<div fxLayout="row wrap" fxLayoutGap="5px" *ngIf="applicationIsReady">

    <div fxFlex="90" *ngIf="application.isPartiallyImported">
        <h3 class="sw-upper sw-bold">Partially imported patch</h3>
        <p>{{mspText}}</p>
        <br>
    </div>  

    <div fxFlex="90" *ngIf="application.isMsp && application.isPartiallyImported">
        <h3>This is a patch and patches the applications below:</h3>
    </div>

    <div fxFlex="90" *ngIf="application.isMsp && !application.hasBeenAssessed ">
        <table class="full-width-bs sw-big-vert-pad-top"> 
            <thead>
                <th>Product Code</th>
                <th>Application</th>
                <th></th>
            </thead>        
            <tr *ngFor="let pi of application.patchParts">
                <td style="width:50%;min-width:380px">{{pi.productCode}}</td>
                <td class="sw-pad">
                    <mat-select *ngIf="pi.candidateApplications.length>0" ngDefaultControl (selectionChange)="patchSelectedApplicationChanged(pi)" [(ngModel)]="pi.applicationId" placeholder="Select Application" style="margin:0px">
                        <mat-option *ngFor='let item of pi.candidateApplications' [value]='item.name'>{{item.value}}</mat-option>
                    </mat-select>
                </td>
                <td style="width:50px" class="sw-pad">
                    <mat-icon *ngIf="pi.applicationId && pi.status == 1">hourglass_empty</mat-icon>
                    <mat-spinner *ngIf="pi.applicationId && pi.status == 0" [diameter]="20"></mat-spinner> 
                </td>   
            </tr>
        </table>
    </div>

    <div fxFlex="90" *ngIf="application.patchPartsWithoutApplication?.length > 0">
        <br>
        <p>NB: There are {{application.patchPartsWithoutApplication.length}} other product codes for which no matching MSI has been identied.</p>
    </div>

    <div fxFlex="90" *ngIf="application.isMsp && !application.hasBeenAssessed">
        <br>
        <p *ngIf="application.patchApplicationConfirmed">The application to patch has been confirmed.</p>
        <button mat-raised-button color="primary" *ngIf="!application.patchApplicationConfirmed" [disabled]="!application.patchCanConfirm" (click)="confirmPatchApplication()">Confirm</button>
    </div>

    <div fxFlex="62" *ngIf="!application.isPartiallyImported">
        <mat-form-field appearance="outline" class="full-width-bs">
            <mat-label>Workflow Stage</mat-label>
            <mat-select [(ngModel)]="application.workflowStage" [disabled]="!currentApplication.canEditWorkflowDetails()" (selectionChange)="appDetailsChanged('WF')">
                <mat-optgroup label="Stage">
                    <mat-option [value]="i" *ngFor="let wfs of getWorkflowStages();let i = index">{{wfs}}</mat-option> 
                </mat-optgroup>
            </mat-select>
        </mat-form-field> 
    </div>

    <div fxFlex="36" *ngIf="!application.isPartiallyImported">
        <mat-form-field appearance="outline" class="full-width-bs">
            <mat-label>Workflow Stage Status</mat-label>
            <mat-select [(ngModel)]="application.workflowStageState" [disabled]="!currentApplication.canEditWorkflowDetails()" (selectionChange)="appDetailsChanged('WFS')">
                <mat-optgroup label="Stage Status">
                    <mat-option [value]="i" *ngFor="let wfs of getWorkflowStatuses();let i = index">{{wfs}}</mat-option> 
                </mat-optgroup>
            </mat-select>
        </mat-form-field> 
    </div>

    <div fxFlex="62" *ngIf="!application.isPartiallyImported">
        <mat-form-field appearance="outline" class="full-width-bs">
            <mat-label>Assigned To</mat-label>
            <mat-select [(ngModel)]="application.assignee" [disabled]="!currentApplication.canEditWorkflowDetails()" (selectionChange)="appDetailsChanged('AS')">
                <mat-optgroup label="Assignee">
                    <mat-option [value]="wfs.userId" *ngFor="let wfs of assignees">{{wfs.name}}</mat-option> 
                </mat-optgroup>
            </mat-select>
        </mat-form-field> 
    </div>

    <div fxFlex="36" *ngIf="!application.isPartiallyImported">
        <mat-form-field appearance="outline" class="full-width-bs">
            <mat-label>Priority</mat-label>
            <mat-select [(ngModel)]="application.priority" [disabled]="!currentApplication.canEditWorkflowDetails()" (selectionChange)="appDetailsChanged('PR')">
                <mat-optgroup label="Priority">
                    <mat-option [value]="i" *ngFor="let wfs of getPriorities();let i = index">{{wfs}}</mat-option> 
                </mat-optgroup>
            </mat-select>
        </mat-form-field> 
    </div>

    <div fxFlex="49" *ngIf="!applicationIsPending && !application.isPartiallyImported">
        <input-edit [context]="applicationId" label="Arguments (Non-Silent)" [disable]="!currentApplication.canEditCoreData()" datatype="ANYSTRING" [focus]="false" [value]="application.arguments" (on-change)="argumentsChanged($event)" class="full-width-bs"></input-edit>
    </div>

    <div fxFlex="49" *ngIf="!applicationIsPending && !application.isPartiallyImported">
        <input-edit [context]="applicationId" label="Arguments (Silent)" [disable]="!currentApplication.canEditCoreData()" datatype="ANYSTRING" [focus]="false" [value]="application.argumentsSilent" (on-change)="argumentsSilentChanged($event)" class="full-width-bs"></input-edit>
    </div>

    <div fxFlex="98" *ngIf="!application.isPartiallyImported">
        <input-edit [context]="applicationId" label="Friendly Name" [disable]="!currentApplication.canEditCoreData()" datatype="ANYSTRING" [focus]="false" [value]="application.friendlyName" (on-change)="friendlyNameChanged($event)" class="full-width-bs"></input-edit>
    </div>

    <div fxFlex="70" *ngIf="!application.isPartiallyImported">
        <input-edit [context]="applicationId" label="Product Name" datatype="ANYSTRING" [focus]="false" [value]="application.productName"(on-change)="productNameChanged($event)" [disable]="!application.noAssess || !currentApplication.canEditCoreData()" class="full-width-bs"></input-edit>
    </div>

    <div fxFlex="28" *ngIf="!application.isPartiallyImported">
        <input-edit [context]="applicationId" label="Product Version" datatype="VERSION" [focus]="false" [value]="application.compoundVersion" (on-change)="compoundVersionChanged($event)" [disable]="!application.noAssess || !currentApplication.canEditCoreData()" class="full-width-bs"></input-edit>
    </div>

    <div fxFlex="98" *ngIf="!application.isPartiallyImported">
        <input-edit [context]="applicationId" label="Manfacturer" datatype="ANYSTRING" [focus]="false" [value]="application.manufacturer" (on-change)="manufacturerChanged($event)" [disable]="!application.noAssess || !currentApplication.canEditCoreData()" class="full-width-bs"></input-edit>
    </div>

    <div fxFlex="98" fxLayout="row" *ngIf="!application.isPartiallyImported && application.isMsi">
        <fieldset class="full-width-bs">
            <legend>Transforms</legend>
            <div fxLayout="row" fxLayoutGap="5">
                <div fxFlex="none" *ngFor="let t of application.importedTransformList">
                    <span>{{t}}</span>
                </div>
            </div>
        </fieldset>
    </div>

    <fieldset fxFlex *ngIf="!utilService.isEmptyGuid(application.extractedFromApplicationId)">
        <legend>Extracted From</legend>
        <div>
            <a href="" [routerLink]="getAppLink(application.extractedFromApplicationId)">{{application.extractedFromApplicationName}}</a>
        </div>
    </fieldset>

    <div fxFlex="95" fxLayout="row" *ngIf="!application.isPartiallyImported">
        <fieldset fxFlex *ngIf="application.noAssess && !applicationIsPending">
            <legend>Detected Installer Type</legend>
            <div fxLayout="row">
                <div fxFlex>
                <span>{{application.exeFlavourDescription}}</span>
                </div>
                <div fxFlex="none" *ngIf="applicationExeFlavourDescription">
                    <button mat-button title="Click here, for more information about silent installation of {{applicationExeFlavourDescription}} installers" (click)="openInstallerInfo()" *ngIf="applicationExeFlavourHasUrl">
                        <mat-icon>help</mat-icon>
                    </button>
                </div>
            </div>
        </fieldset>
    </div>

    <fieldset fxFlex class="sw-app-detail" *ngIf="!applicationIsPending && !application.isPartiallyImported">
        <legend>Middleware</legend>
        <mat-checkbox [disabled]="applicationIsUpdating" [(ngModel)]="application.isMiddleware" [disabled]="!currentApplication.canEditCoreData()" (change)="appDetailsChanged('MID')" matTooltip="Set during import if no shortcuts or file extensions have been detected"></mat-checkbox>
    </fieldset>

    <fieldset fxFlex class="sw-app-detail" *ngIf="application.isMsi && !application.isPartiallyImported">
        <legend>Vendor App.</legend>
        <mat-checkbox [disabled]="applicationIsUpdating" [(ngModel)]="application.isVendor" [disabled]="!currentApplication.canEditCoreData()" (change)="appDetailsChanged('VS')" aria-label="Is Vendor Updating"></mat-checkbox>    
    </fieldset>

    <fieldset fxFlex *ngIf="!application.noAssess && !applicationIsPending && !application.isPartiallyImported" class="sw-app-detail">
        <legend>Complexity</legend>
        <div>
            <complexity-column [value]='application.complexity'></complexity-column>
        </div>
    </fieldset>

    <fieldset fxFlex class="sw-app-detail" *ngIf="!applicationIsPending && !application.isPartiallyImported">
        <legend>Architecture</legend>
        <span *ngIf="application.isMsi">{{application.architectureName}}</span>
        <mat-select *ngIf="!application.isMsi" [(ngModel)]="application.architectureName" (selectionChange)="appDetailsChanged('ARCH')">
            <mat-option value="x86">x86</mat-option>
            <mat-option value="x64">x64</mat-option>
            <mat-option value="{{null}}">unknown</mat-option>
        </mat-select>
    </fieldset>

    <fieldset fxFlex class="sw-app-detail">
        <legend>Date Imported</legend>
        <span>{{application.importDate | date:'dd-MMM-yyyy'}}</span>
    </fieldset>  


</div>

