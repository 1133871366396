import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppCommand } from '../../model/AppCommand';
import { Constants } from '../../api/Constants';
import { UtilService } from '../../svc/utilService';
import { CurrentApplicationService } from 'src/app/svc/currentApplicationService';
import { StateHelperService } from 'src/app/svc/stateHelperService';
import { NavigationService } from 'src/app/svc/navigationService';
import { CommandCollection } from 'src/app/commands/command-collection';
import { PackageFindCommand } from 'src/app/commands/package-find';

import * as CRS from '../../api/CRS';

@Component({
    selector: Constants.ROUTE_PROJECTAPPLICATIONBROWSE,
    templateUrl: './project-application-browse.html',
    styleUrls: [ './project-application-browse.scss']
})
export class ProjectApplicationBrowse implements OnInit, OnDestroy {

  constructor(
    private navigation: NavigationService,
    private currentApplication: CurrentApplicationService,
    private utilService: UtilService,
    private stateHelperService: StateHelperService,
  ) { }

    public packageIssues: CRS.RSMsiGetPackageIssueData=null;

    ngOnInit():void {
        
        this.currentApplication.browser.touch();

        var tbl = this.utilService.isEmptyAny(this.currentApplication.browser.currentTable) ? "File" : this.currentApplication.browser.currentTable;
        if (this.stateHelperService.details.isStatePathCurrent( Constants.ROUTE_PROJECTAPPLICATIONBROWSE)) {
            this.navigation.goApplicationBrowseTableFULL(this.currentApplication.dataItem.projectId, this.currentApplication.dataContext.id, tbl)
        }

        localStorage.setItem(Constants.LOCALSET_LAST_ACTION, Constants.ACTION_BROWSE);
    }

    ngOnDestroy():void {

    }

    public isReady(): boolean {
        return this.currentApplication.dataContext.isReady && this.currentApplication.browser.isReady;
    }

    public hasFindResults(operator:string, value:number): boolean {
        if (this.utilService.isEmptyAny(this.currentApplication.browser.findResults))
            return false; 
            
       switch(operator)
        {
            case '>':
                return this.currentApplication.browser.findResults.length > value;
            case '=':
                return this.currentApplication.browser.findResults.length == value;
        }
    }

    public findResultState(): string {
        return this.currentApplication.browser.findResultState;
    }

    public get packageDetails() : CRS.RSMsiGetPackageDetails {
        return this.currentApplication.browser.packageDetails;
    }

    public get viewEmptyTables(): boolean 
    {
        return this.utilService.showEmptyTablesInAppBrowse;
    } 

//    private viewInternalTables: boolean = this.$localStorage["viewinternaltables"];

    public get toggleViewEmptyTablesCommand(): AppCommand {
        return CommandCollection.ToggleEmptyTables.cmd;
    }

    public get toggleViewInternalTablesCommand(): AppCommand {
        return CommandCollection.ToggleViewInternalTables.cmd;
    }

    public get undoPackageModificationCommand(): AppCommand {
        return CommandCollection.UndoPackageModification.cmd;
    }

    public openTable(item: CRS.RSMsiTable) {
        localStorage.setItem(Constants.LOCALSET_LAST_BROWSE_TABLE, item.name);
        this.navigation.goApplicationBrowseTableFULL(this.currentApplication.dataItem.projectId, this.currentApplication.dataContext.id, item.name);
    }

    private openTableById(id:string) {
        var tbl = this.currentApplication.browser.packageDetails.tables.filter(x=>x.name == id)[0];
        this.openTable(tbl);
        localStorage.setItem(Constants.LOCALSET_LAST_BROWSE_TABLE, id);
    }

    public close() {
        this.currentApplication.browser.clearFind();
        this.navigation.goHome().then(()=>{
            this.navigation.goApplicationJobFULL(this.currentApplication.dataItem.projectId,this.currentApplication.dataItem.id,`O:${this.currentApplication.dataContext.id}`);
        });
        localStorage.removeItem(Constants.LOCALSET_LAST_ACTION);
        localStorage.removeItem(Constants.LOCALSET_LAST_BROWSE_TABLE);
    }

    public get packageFindCommand(): PackageFindCommand {
        return CommandCollection.PackageFind;
    }

    public findTextChanged() {
        CommandCollection.PackageFind.cmd.refresh();
    }
    
    public get savePackageCommand(): AppCommand {
        return CommandCollection.SavePackage.cmd;
    }

    public get viewPackageSummaryInformationCommand(): AppCommand {
        return CommandCollection.ViewPackageSummaryInformation.cmd;
    }

    public get findNextCommand(): AppCommand {
        return CommandCollection.PackageFindNext.cmd;
    }

    public get findPrevCommand(): AppCommand {
        return CommandCollection.PackageFindPrev.cmd;
    }

}


