import { HttpClient, HttpErrorResponse, } from '@angular/common/http';
import { UtilService } from 'src/app/svc/utilService';
import { AuthService } from '../svc/authService';
import { Observable, Subject } from 'rxjs';
import { first } from 'rxjs/operators';

import * as CRS from './CRS';
import * as IRS from './IRS';

export class SvcHttpMethods {

	constructor(
		private http: HttpClient,
		private authService: AuthService,
		private utilService: UtilService)
	{ }

	//401 (unauthorised) error occurs when auth token expires and a guarded api call is made. This traps
	//that error and attempts to get an updated token then re-run the request.

	public get<T>(request:any, url:string, factory: ()=>T) :Promise<CRS.ResponseWrapper<T>> {
		return this.__get(request, url, factory, true);
	}

	public __get<T>(request : any, url:string, factory: ()=>T, rerun401: boolean) :Promise<CRS.ResponseWrapper<T>> {

		return new Promise<CRS.ResponseWrapper<T>>((resolve, reject) => {

			this.http.get<CRS.ResponseWrapper<T>> (this.utilService.getAPIUrl(url),
				{ params:request, headers: this.utilService.getHttpHeaders()})
			.toPromise()
			.then((response) => {
				let s = CRS.RSStatus.deserialize(response.status);
				var u : unknown = factory();
				var dt = <IRS.IDeserializer>u;
				if (!dt?.deserialize)
					throw `No deserialize method found for response to url ${url}}`;
				let d = dt.deserialize(response.data);
				resolve(new CRS.ResponseWrapper(d,s));
			},(err : HttpErrorResponse)=> {
				if (!rerun401) {
				    reject(err);
				}
				else {
					var obs = this.handle401(err);
					if (!obs) {
						reject(err);
					}
					else {
						obs.pipe(first()).subscribe((r)=>{
							this.__get(request, url, factory, false).then((r)=>{
								resolve(r);
							});
						});
					}
				}
			});

		});

	}

	public post(request: any, url: string) :Promise<boolean> {
		return this.__post(request, url, true);
	}

	public __post(request: any, url: string, rerun401: boolean) :Promise<boolean> {

		return new Promise<boolean>((resolve, reject) => {

			this.http.post<boolean> (this.utilService.getAPIUrl(url),
				request,
				{ headers: this.utilService.getHttpHeaders()})
			.toPromise()
			.then((response) => {
				resolve(response);
			},(err : HttpErrorResponse)=> {
				if (!rerun401) {
				    reject(err);
				}
				else {
					var obs = this.handle401(err);
					if (!obs) {
						reject(err);
					}
					else {
						obs.pipe(first()).subscribe((r)=>{
							this.__post(request, url, false).then((r)=>{
								resolve(r);
							});
						});
					}
				}
			});
		});
	}

	public postTyped<T>(request: any, url: string, factory: ()=>T) : Promise<CRS.ResponseWrapper<T>> {
		return this.__postTyped(request, url, factory, true);
	}

	public __postTyped<T>(request: any, url: string, factory: ()=>T, rerun401: boolean) : Promise<CRS.ResponseWrapper<T>> {

		return new Promise<CRS.ResponseWrapper<T>>((resolve, reject) => {

			this.http.post<CRS.ResponseWrapper<T>> (this.utilService.getAPIUrl(url),
				request,
				{ headers: this.utilService.getHttpHeaders()})
			.toPromise()
			.then((response) => {
				let s = CRS.RSStatus.deserialize(response.status);
				var u : unknown = factory();
				var dt = <IRS.IDeserializer>u;
				let d = dt.deserialize(response.data);
				resolve(new CRS.ResponseWrapper(d,s));
			},(err : HttpErrorResponse)=> {
				if (!rerun401) {
				    reject(err);
				}
				else {
					var obs = this.handle401(err);
					if (!obs) {
						reject(err);
					}
					else {
						obs.pipe(first()).subscribe((r)=>{
							this.__postTyped(request, url, factory, false).then((r)=>{
								resolve(r);
							});
						});
					}
				}
			});

		});
	}

	public delete(request: any, url: string) :Promise<boolean> {

		return new Promise<boolean>((resolve, reject) => {

			this.http.delete<boolean> (this.utilService.getAPIUrl(url),
				{ params:request, headers: this.utilService.getHttpHeaders()})
			.toPromise()
			.then((response) => {
				resolve(response);
			},(reason)=> {
				reject(reason);
			});

		});
	}

	public handle401(err: HttpErrorResponse) : Observable<boolean> {
		if (err.status == 401){
			var atr = this.authService.authTokenRefresh();
			if (!atr) {
				return null;
			}
			else {
				var sub = new Subject<boolean>();
				atr.pipe(first()).subscribe((r)=>{
					sub.next(true);
				});
				return sub;
			}
		}
		else {
			return null;
		}
	}

}
