<div fxLayout="column" fxLayoutGap="10px" *ngIf="isDataReady()" class="full-width-bs full-height=bs">

    <div fxFlex="none" *ngIf="task.launchDetails && task.launchDetails.length>0" class="sw-pad-top">
        <div class="sw-bold sw-upper">Launch Details</div>
    </div>
    <div fxFlex="none" fxLayout="row" *ngFor="let ld of task.launchDetails" fxLayoutAlign="start center">
        <div fxFlex="{{(ld.label2 || (ld.installerInfo && ld.installerInfo.length>0)) ?'25':''}}" class="sw-pad-small">
            <bullet text="{{ld.label1}}"></bullet>  
        </div>
        <div fxFlex *ngIf="ld.label2">
            {{ld.label2}}
        </div>
        <div fxFlex fxLayout="column" *ngIf="ld.installerInfo && ld.installerInfo.length>0">
            <div fxFlex fxLayout="row" *ngFor="let psi of ld.installerInfo">
                <div fxFlex fxLayout="column">
                    <div fxFlex>{{psi.filename}}</div>
                    <div fxFlex class="sw-sub-element">{{psi.arguments}}</div>
                </div>
                <div fxFlex="none" fxLayout="row" fxLayoutGap="5px">
                    <div fxFlex *ngIf="psi.useRepack" class="conversion-tag">Repack</div>
                    <div fxFlex *ngIf="psi.useFixTransform" class="conversion-tag">Fixed</div>
                </div>
            </div>
        </div>
    </div>

    <div fxFlex="100">
        <div class="sw-pad"></div>
    </div>

    <div fxFlex="none">
        <div class="sw-bold sw-upper">Completion Details</div>
    </div>

    <div fxFlex="none" *ngIf="task.msiExtractOnly">
        <p>The installation has now finished being processed. MSIs that were detected as having been installed during capture-time have been selected and those that were copied but not installed have not been selected.</p>
        <p>To import the selected packages into Application Readiness use the menu above, and choose "Import MSI Extracts"</p>
    </div>

    <div fxFlex="none" fxLayout="row wrap" *ngIf="getStatsByCategory('CI')">
        <div fxFlex="45" class="sw-pad-small">
            <bullet text="Task Created on {{taskCreated}}"></bullet>
        </div>
        <div *ngFor="let cs of getStatsByCategory('CI')" fxFlex="45" class="sw-pad-small">
            <bullet text="{{cs.message}}"></bullet>
        </div>
    </div>  

    <div fxFlex="none" fxLayout="row wrap" >
        <div fxFlex="100" *ngIf="!isApplicationBasedRoute()" class="sw-pad-small">
            <div fxLayout="row">
                <div fxFlex="none" class="sw-bullet"></div> 
                <div fxFlex><a href="" (click)="goToApp($event)">View Application</a></div>
            </div>
        </div>
    </div>

    <div fxFlex="none" *ngIf="getStatsByCategory('TS').length > 0" class="sw-big-pad-top">
        <div class="sw-bold sw-upper">Test Outcome</div>
    </div>
    <div fxFlex="auto" fxLayout="column" fxLayoutGap="15px" >
        <div *ngFor="let cs of getStatsByCategory('TS')" fxFlex="45" class="sw-pad-small">
            <bullet *ngIf="cs.asNumber == 1" text="{{cs.message}}"></bullet>
            <bullet *ngIf="cs.asNumber != 1" text="{{cs.message}}" warn="true" severe="true"></bullet>
        </div>
    </div>  

    <div flex="none" *ngIf="task.jobType == 20 && task.additionalInfo && task.additionalInfo.length > 0">
        <div class="sw-bold sw-upper sw-vert-pad-bottom">MSIX SERVICE ERRORS</div>
        <div fxLayout="column">
            <div fxFlex="none" *ngFor="let ai of task.additionalInfo">
                <div class="sw-pad">
                    <bullet text="{{ai.key}}" warn="true"></bullet> 
                    <ul>
                        <li *ngFor="let ais of ai.subValues">{{ais}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div fxFlex="none" *ngIf="task.shortcutRunTestResults.length>0">
        <div class="sw-bold sw-upper">Shortcut Screenshots</div>
    </div>
    <div fxFlex="auto" fxLayoutGap="5px" fxLayout="row wrap">
        <div fxFlex="50" *ngFor="let srtr of task.shortcutRunTestResults">
            <div fxLayout="column" class="sw-task-shtcut-image">
                <div fxFlex layout="column" layout-align="center center"> 
                    <div *ngIf="!srtr.screenshot">NO IMAGE</div>
                    <img *ngIf="srtr.screenshot" [src]="getXCheckScreenshotImage(srtr)" style="max-width:300px">
                </div>
                <div fxFlex="20" class="sw-task-shtcut-image-foot" fxLayout="column" fxLayoutAlign="center center">
                    <div fxFlex><h3>{{srtr.shortcutFilename}}</h3></div>
                </div>
            </div>
        </div>
    </div>

    <div fxFlex="none" *ngIf="task.warnings && task.warnings.length > 0">
        <div class="sw-bold sw-upper">Warnings</div>
    </div>
    <div fxFlex="none" fxLayout="row wrap" fxLayoutGap="5px">
        <div [fxFlex]="task.warnings.length > 2 ? 50 : 100" *ngFor="let w of task.warnings">
            <bullet text="{{w.value}}" warn="true" severe="{{w.furtherInfo == '!'}}"></bullet>  
        </div>
    </div>
    <div fxFlex="none" *ngIf="task.completionStatContainsObservations">
        <button mat-raised-button color="primary" (click)="viewCompletionObservations($event)">More Information</button>
    </div>
    
    <div fxFlex="none">
        <div class="sw-pad-small"></div>
    </div>

    <div fxFlex="none" fxLayout="column" *ngIf="task.preInstalledDependencies">
        <div fxflex *ngFor="let pid of task.preInstalledDependencies" fxLayout="column">
            <div fxFlex="none" class="sw-bold sw-upper">{{pid.value}}</div>
            <div fxFlex="none"><div class="sw-pad-small"></div></div>
            <div fxFlex="none" fxLayout="column" fxLayoutGap="5px">
                <div fxFlex="none" *ngFor="let sub of pid.subValues">
                    <bullet text="{{sub}}"></bullet>
                </div>
            </div>
        </div>
    </div>

    <div fxFlex="none">
        <div class="sw-pad-small"></div>
    </div>

    <div fxFlex="none" *ngIf="task.msiInstallersExtracted">
        <div class="sw-bold" *ngIf="task.msiInstallersExtracted.length>0">{{task.msiInstallersExtracted.length}} MSI installer{{task.msiInstallersExtracted.length == 1? "" : "s"}} extracted</div>
        <div *ngIf="task.msiInstallersExtracted.length==0 && task.msiExtractOnly" fxLayout="row"
                    fxLayoutAlign="start center" fxLayoutGap="5px">
            <div fxFlex="none">
                <img src="/img/misc/warning.png" width="48px" height="48px">
            </div>
            <div fxFlex>
                <h3>WARNING</h3>
                <div>NO MSI INSTALLERS WERE EXTRACTED DURING THIS CAPTURE</div>
            </div>  
        </div>
    </div>

    <div fxFlex="none" fxLayout="column" fxLayoutGap="5px" *ngIf="task.msiInstallersExtracted.length>0">
        <div flex="none" class="sw-vert-pad-bottom" *ngIf="!task.msiExtractOnly">
            MSIs that were detected as having been installed during capture-time have been selected and those that were copied but not installed have not been selected.
        </div>
        <div fxFlex fxLayout="row wrap" >
            <div fxFlex="50" *ngFor="let msi of task.msiInstallersExtracted">
                <div fxLayout="row" fxLayoutGap="10px">
                    <div fxFlex="none">
                        <mat-checkbox [(ngModel)]="msi.isChecked" (change)="msiExtractCheckChanged()"></mat-checkbox>
                    </div>
                    <div [ngClass]="{'sw-bold':msi.alreadyImported}">
                        <div fxLayout="row">
                            <div fxFlex="none">{{msi.filename}}&nbsp;&nbsp;</div>
                            <div fxFlex *ngIf="msi.containsInfo">
                                <a title="Show extracted information for {{msi.filename}}" href="" (click)="showMsiInstallerInfo($event, msi)">
                                    <mat-icon class="sw-light-effect md-24">info</mat-icon>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>  
            </div>
        </div>

    </div>

</div>
