import { Component,OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UtilService } from '../../svc/utilService';
import { MenuService } from '../../svc/menuService';
import { NavigationService } from 'src/app/svc/navigationService';
import { CurrentConversionJobService } from '../../svc/currentConversionJobService';
import { CurrentApplicationService } from '../../svc/currentApplicationService';
import { MsiInstallerInfoDialog } from '../../dialogs/msi-installer-info/msi-installer-info-dialog';
import { RepackageObservationsDialog } from 'src/app/dialogs/repackage-observations/repackage-observations-dialog';
import { StateHelperService } from '../../svc/stateHelperService';
import { Constants } from '../../api/Constants';

import * as CRS from '../../api/CRS';

@Component({
  selector: 'conversion-task-completion-info',
  templateUrl: './conversion-task-completion-info.html',
  styleUrls:['./conversion-task-completion-info.scss', '../bullet/bullet.scss']
})
export class ConversionTaskCompletionInfo implements OnInit {

    constructor(
        public currentConversionJob: CurrentConversionJobService,
        public currentApplication: CurrentApplicationService,
        private navigation: NavigationService,
        private dialog: MatDialog, 
        private stateHelperService: StateHelperService,
        private utilService: UtilService,
        private menuService: MenuService
    ) { }

    ngOnInit(): void {
    }

    public isDataReady()  : boolean {
        return !this.utilService.isEmptyAny(this.task);
    }

    public get task() : CRS.RSGetConversionTask {
        if (!this.currentConversionJob.dataContext.isReady)
            return null;
        if (!this.currentConversionJob.hasTask())
            return null;
        return this.currentConversionJob.dataItem.task;
    }

    public getStatsByCategory(category): Array<CRS.RSCompletionStatistic> {
        return this.task.completionStatistics.filter(x=>x.id.startsWith(category));
    }

    public get taskCreated() : string {
        if (!this.currentConversionJob.hasTask())
            return "";
        var dateLocal = new Date(this.currentConversionJob.dataItem.task.dateTaskCreated);
        var dt = new Date(dateLocal.getTime() - dateLocal.getTimezoneOffset()*60*1000);
       return this.utilService.getDateAsStringWithTime(dt);
    }

    public isApplicationBasedRoute() {
        return !this.utilService.isEmpty(this.stateHelperService.details.applicationId);
    }

    public isApplicationReady() {
        return this.currentApplication.dataContext.isReady;
    }

    public goToApp($event) : boolean {
        this.navigation.goApplicationJobFULL(
            this.stateHelperService.details.projectId,
            this.task.applicationId,
            this.task.id
        );
        return false;   
    }

    public msiExtractCheckChanged(): void {
        this.menuService.refreshAll();
    }

    public getXCheckScreenshotImage(srtr: CRS.RSXCheckShortcutResult) : string {
        return `data:image/jpeg;base64,${srtr.screenshot}`;
    }

    public showMsiInstallerInfo($event: Event, item: CRS.RSExtractedMsi) {
        $event.preventDefault();
        $event.stopPropagation();
        const dialogRef = this.dialog.open(MsiInstallerInfoDialog, {
            data: item
          });
          dialogRef.afterClosed().subscribe( prj => {
            if (prj) {
                this.navigation.goProjectDetailsFULL(prj.Id);
            }
          });
    }

    public viewCompletionObservations($event) {
        $event.preventDefault();
        $event.stopPropagation();
        const dialogRef = this.dialog.open(RepackageObservationsDialog);
          dialogRef.afterClosed().subscribe( prj => {
            if (prj) {
                this.navigation.goProjectDetailsFULL(prj.Id);
            }
          });

    }
}
