import { Injector } from '@angular/core';
import { CurrentApplicationService } from "../svc/currentApplicationService";
import { UtilService } from '../svc/utilService';
import { AppState } from "./AppState";
import { MsiTableExt } from "./MSITABLEEXT";
import { Subject } from 'rxjs';
import { Constants } from '../api/Constants';

import * as Enums from '../api/Enum';
import * as CRS from '../api/CRS';
import * as SVC from '../api/SVC';
import { CurrentProjectService } from '../svc/currentProjectService';

export class ApplicationBrowser {
 
    constructor(currentApplication: CurrentApplicationService, injector: Injector) {
        this.currentApplication = currentApplication;
        this.currentProject = injector.get(CurrentProjectService);
        this.utilService = injector.get(UtilService);
        this.msiService = injector.get(SVC.MsiService);
        currentApplication.dataContext.onStateChanged().subscribe((state)=> {
            this.handleApplicationStateChange(state);
        });
        this._onTableDataReady = new Subject();
        this._onFindItemChangedWithinTable =new Subject();
    }

    private _onTableDataReady : Subject<void>;
    public onTableDataReady() {
        return this._onTableDataReady.asObservable();
    }
    private _onFindItemChangedWithinTable :Subject<void>;
    public onFindItemChangedWithinTable() {
        return this._onFindItemChangedWithinTable.asObservable();
    }

    private msiService : SVC.MsiService;
    private currentApplication : CurrentApplicationService;
    private currentProject: CurrentProjectService;
    private utilService: UtilService;

    public packageDetails: CRS.RSMsiGetPackageDetails = null;
    public updates: Array<CRS.RSMsiRowUpdateCollection> = null;
    public packageTable: CRS.RSMsiGetPackageTableData=null;
    public packageIssues: CRS.RSMsiGetPackageIssueData=null;
    public issuesByTable: {[id:string]: Array<CRS.RSMsiIssue>}=null;
    public tableExt: MsiTableExt=null;
    public currentTable:string=null;
    public platformNo:number = 0; // Not wired up yet

    public findResults: Array<CRS.RSMsiFindResult>=null;
    public findItem: CRS.RSMsiFindResult=null;
    public findResultState: string =null;

    public canBrowsePackage: boolean=false;

    public get isReady() : boolean {
        return !this.utilService.isEmptyAny( this.packageDetails);
    }

    public touch() : void {
        this.update();
    }

    public touchTable(name:string) : void {
        this.currentTable = name;
        this.updateTable(name);
    }

    public clearFind() {
        this.currentTable=null;
        this.findItem=null;
        this.findResultState=null;
        this.findResults=null;
    }

    public find(searchText: string) : Promise<number> {
        return new Promise<number>((resolve,reject)=> {
            this.msiService.msiFind(
                this.currentApplication.dataContext.id,
                this.platformNo,
                searchText).then((response)=> {
                    this.findResults = response.data.results;
                    resolve(this.findResults.length);
                },
            (err)=> {
                reject(err);
            });
        });
    }

    public goToFindItem(item: CRS.RSMsiFindResult)  : void {

        this.findItem = item;
        this.findResultState = (item) ?  (this.currentApplication.browser.findResults.indexOf(item) + 1) + " of " + this.currentApplication.browser.findResults.length : null;

        if (!this.findItem || (this.findItem && item.table != this.currentTable)) {
            this.currentTable = item.table;
            this.touchTable(this.currentTable);
        }
        else {
            this._onFindItemChangedWithinTable.next();
        }
    }

    public tableHasIssues(name:string) : boolean {
        return this.issuesByTable && this.issuesByTable[name] && this.issuesByTable[name].length>0;
    }

    public getRowDependencies(tableName: string, row: CRS.RSMsiRow) : Promise<CRS.ResponseWrapper<CRS.RSMsiGetPackageRowDependencies>> {
        var pk = this.currentApplication.browser.tableExt.getPrimaryKey(row).split('|');
        return this.msiService.msiGetPackageRowDependencies(
            this.currentApplication.dataContext.id,
            this.platformNo,
            tableName, 
            pk);
    }

    private handleApplicationStateChange(state: AppState) {
        switch(state.loadState)
        {
            case Enums.LoadState.Ready:
                this.canViewPackageRefresh();
                break;
            default:
                this.packageDetails=null;
                this.packageTable=null;
                this.packageIssues=null;
                this.issuesByTable=null;
                this.tableExt=null;
                this.findResults=null;
                this.findItem=null;
                this.findResultState=null;
                this.canBrowsePackage=false;
                break;
            }
    }

    private update(): void {
        this.msiService.msiGetPackageDetails(this.currentApplication.dataContext.id, this.platformNo).then((response) => {
             this.packageDetails = response.data;
             this.updates = response.data.updates;
        }, () => {
           console.error('Application couldn\'t be loaded');
        });

        this.msiService.msiGetPackageIssueData(this.currentApplication.dataContext.id, this.platformNo).then((response)=> {
            this.packageIssues=response.data;
            this.issuesByTable ={};
            response.data.issues.forEach(x => {
                var ti = this.issuesByTable[x.tableName];
                if (!ti) {
                    ti = [];
                    this.issuesByTable[x.tableName] = ti;
                }
                ti.push(x);
            });
        }, ()=> {
            console.error('Application issues couldn\'t be loaded');
        });
    }

    private updateTable(tableName:string): void {
        this.msiService.msiGetPackageTableData(this.currentApplication.dataContext.id, this.platformNo, tableName).then((response) => {
            this.packageTable = response.data;
            this.updates = response.data.updates;
            var tbls = response.data.tables.filter(t=>t.name == tableName);
            if (tbls.length==0) // Table doesn't exist for this pkg
                tbls = response.data.tables;
            this.tableExt = (tbls.length==0) ? null : MsiTableExt.Create(tbls[0]);;
            this._onTableDataReady.next();
        });
    }

    public canViewPackageRefresh() {
        this.canBrowsePackage = false;
        if (this.currentApplication.dataContext.isReady) {
            if (this.currentApplication.dataItem.isMsi) 
                this.canBrowsePackage=true;
            else if (this.currentApplication.dataItem.repackageStatus == Constants.STATUS_SUCCESS)
                this.canBrowsePackage = true;
        } 
    }

}