import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilService } from 'src/app/svc/utilService';
import { AuthService } from '../svc/authService';
import { SvcHttpMethods } from './SvcHttpMethods';
import { NavigationService } from '../svc/navigationService';

import * as CRS from './CRS';
import * as IRQ from './IRQ';
import { RSAKeyPairKeyObjectOptions } from 'crypto';

@Injectable({
	providedIn: 'root',
})
export class ApplicationService {

	constructor(
		private http: HttpClient,
		private authService: AuthService,
		private navigation: NavigationService,
		private utilService: UtilService)
	{ 		
		this.methods = new SvcHttpMethods(http, authService, utilService);
	}

	private methods: SvcHttpMethods;

	public clearCache() : void {
		this.__lastUpdateArgsGetApplication="";
		this.__lastUpdateArgsGetApplicationIssues=null;
	}

	private __lastUpdateGetApplication: string=this.utilService.getEmptyGuid();
	private __lastUpdateArgsGetApplication: any=null;

	public getApplication(applicationId: string, isRefreshCheck: boolean) {

		let args = [this.utilService.stateChangeCounter, this.navigation.url, applicationId]
		let irc = isRefreshCheck && this.utilService.areEqual(args, this.__lastUpdateArgsGetApplication);
		this.__lastUpdateArgsGetApplication = [this.utilService.stateChangeCounter, this.navigation.url, applicationId]

		return new Promise<CRS.ResponseWrapper<CRS.RSGetApplication>>((resolve, reject) => {

			let request = {
					"applicationId":applicationId,
					"isRefreshCheck": irc.toString(),
					"lastUpdateId": this.__lastUpdateGetApplication,
					"serverId":this.utilService.serverId
			};
		
			this.http.get<CRS.ResponseWrapper<CRS.RSGetApplication>>(this.utilService.getAPIUrl( 'Application/getApplication'),
				{ params:request, headers: this.utilService.getHttpHeaders() , observe: 'response'})
			.toPromise()
			.then((response) => {
				let s = CRS.RSStatus.deserialize(response.body.status);
				let d = (new CRS.RSGetApplication()).deserialize(response.body.data);
				this.__lastUpdateGetApplication = (d)  ? d.updatedActivity : this.utilService.getEmptyGuid();
				resolve(new CRS.ResponseWrapper(d,s));
			},(reason)=> {
				reject(reason);
			});
		});
	}

	private __lastUpdateGetApplicationIssues: string=this.utilService.getEmptyGuid();
	private __lastUpdateArgsGetApplicationIssues: any=null;

	public getApplicationIssues(applicationId: string, isRefreshCheck: boolean) : Promise<CRS.ResponseWrapper<CRS.RSGetApplicationIssues>> {
		let args = [this.utilService.stateChangeCounter, this.navigation.url, applicationId]
		let irc = isRefreshCheck && this.utilService.areEqual(args, this.__lastUpdateArgsGetApplicationIssues);
		this.__lastUpdateArgsGetApplicationIssues = [this.utilService.stateChangeCounter, this.navigation.url, applicationId]

		return new Promise<CRS.ResponseWrapper<CRS.RSGetApplicationIssues>>((resolve, reject) => {

			let request = {
				"applicationId": applicationId,
				"isRefreshCheck": String(irc),
				"lastUpdateId": this.__lastUpdateGetApplicationIssues.toString(),
				"serverId": this.utilService.serverId
			};
		
			this.http.get<CRS.ResponseWrapper<CRS.RSGetApplicationIssues>> (this.utilService.getAPIUrl( 'Application/GetApplicationIssues'),
					{params:request, headers: this.utilService.getHttpHeaders(), observe: 'response'})
			.toPromise()
			.then((response) => {
				let s = CRS.RSStatus.deserialize(response.body.status);
				let d = (new CRS.RSGetApplicationIssues()).deserialize(response.body.data);
				resolve(new CRS.ResponseWrapper(d,s));
			},(reason)=> {
				reject(reason);
			});

		});
	}

	public getApplicationSiblingInstallers(applicationId: string) : Promise<CRS.ResponseWrapper<CRS.RSGetApplicationSiblingInstallers>> {
		let request = {
			"applicationId": applicationId,
			"serverId": this.utilService.serverId
		};
		return this.methods.get<CRS.RSGetApplicationSiblingInstallers>(request, "Application/GetApplicationSiblingInstallers", ()=>CRS.RSClsFactory.factory(CRS.RSGetApplicationSiblingInstallers));
	}

	public getApplicationDocumentationFiles(applicationId: string) : Promise<CRS.ResponseWrapper<CRS.RSGetApplicationDocumentationFiles>> {
		let request = {
			"applicationId": applicationId,
			"serverId": this.utilService.serverId
		};
		return this.methods.get<CRS.RSGetApplicationDocumentationFiles>(request, "Application/GetApplicationDocumentationFiles", ()=>CRS.RSClsFactory.factory(CRS.RSGetApplicationDocumentationFiles));
	}

	public downloadApplicationDocumentation(applicationId: string, id: number) :Promise<CRS.ResponseWrapper<CRS.RSDownloadApplicationDocumentation>>{
		let request = {
			"applicationId": applicationId,
			"id": id.toString(),
			"serverId": this.utilService.serverId
		};
		return this.methods.get<CRS.RSDownloadApplicationDocumentation>(request, "Application/DownloadApplicationDocumentation", ()=>CRS.RSClsFactory.factory(CRS.RSDownloadApplicationDocumentation));
	}

	public getApplicationsAtPath(projectId:string, path:string) : Promise<CRS.ResponseWrapper<CRS.RSGetApplicationsAtPath>>{
		let request = {
			"projectId"	:projectId,
			"path": path,
			"serverId": this.utilService.serverId
		};
		return this.methods.get<CRS.RSGetApplicationsAtPath>(request, "Application/GetApplicationsAtPath", ()=>CRS.RSClsFactory.factory(CRS.RSGetApplicationsAtPath));
	}

	public getApplicationActivity(applicationId: string) : Promise<CRS.ResponseWrapper<CRS.RSGetApplicationActivity>>{
		let request= {
			"applicationId": applicationId,
			"serverId": this.utilService.serverId
		};
		return this.methods.get<CRS.RSGetApplicationActivity>(request, "Application/GetApplicationActivity", ()=>CRS.RSClsFactory.factory(CRS.RSGetApplicationActivity));
	}

	public getApplicationQaChecksheet(applicationId: string) : Promise<CRS.ResponseWrapper<CRS.RSGetApplicationQaChecksheet>>{
		let request= {
			"applicationId": applicationId,
			"serverId": this.utilService.serverId
		};
		return this.methods.get<CRS.RSGetApplicationQaChecksheet>(request, "Application/GetApplicationQAChecksheet", ()=>CRS.RSClsFactory.factory(CRS.RSGetApplicationQaChecksheet));
	}

	public addApplication(projectId:string, productName: string, productVersion: string, priority: number, sourceMediaPath:string) : Promise<CRS.ResponseWrapper<CRS.RSAddApplication>> {
		let request: IRQ.IRQAddApplication = {
			projectId: projectId,
			productName: productName,
			productVersion: productVersion,
			priority: priority,
			sourceMediaPath: sourceMediaPath,
			serverId: this.utilService.serverId
		};
		return this.methods.postTyped(request, "Application/AddApplication", ()=>CRS.RSClsFactory.factory(CRS.RSAddApplication));
	}

	public setApplicationSourcePath(applicationId:string, sourceMediaPath:string) : Promise<CRS.ResponseWrapper<any>> {
		let request: IRQ.IRQSetApplicationSourcePath = {
			applicationId: applicationId,
			sourceMediaPath: sourceMediaPath,
			serverId: this.utilService.serverId
		};
		
		var p = <unknown>this.methods.post(request, "Application/SetApplicationSourcePath");
		return <Promise<CRS.ResponseWrapper<any>>>p;
	}

	
	public updatePatchProductApplication(mspApplicationId:string, productCode:string, msiApplicationId:string) : Promise<boolean> {
		let request: IRQ.IRQUpdatePatchProductApplication = {
			mspApplicationId: mspApplicationId,
			productCode: productCode,
			msiApplicationId: msiApplicationId,
			serverId: this.utilService.serverId
		};
		
		return this.methods.post(request, "Application/UpdatePatchProductApplication");
	}

	public confirmPatchApplication(mspApplicationId:string) : Promise<boolean> {
		let request: IRQ.IRQConfirmPatchApplication = {
			mspApplicationId:mspApplicationId,
			serverId: this.utilService.serverId
		}
		return this.methods.post(request, "Application/ConfirmPatchApplication");
	}

	public deleteApplication(projectId: string, applicationId: string) : Promise<boolean> {
		let request = {
			"projectId": projectId,
			"applicationId": applicationId,
			"serverId": this.utilService.serverId
		};
		return this.methods.delete(request, "Application/Delete")
	}

	public applyFixes(projectId: string, applicationId: string, platformNumber: number, issues: Array<string>) : Promise<boolean> {
		let request: IRQ.IRQApplyFixes = {
			projectId: projectId,
			applicationId: applicationId,
			platformNumber: platformNumber,
			issues: issues,
			serverId: this.utilService.serverId
		};
		return this.methods.post(request, "Application/ApplyFixes");
	}

	public ignoreIssues(applicationId: string, issues: Array<string>) : Promise<boolean> {
		let request: IRQ.IRQIgnoreIssues = {
			applicationId: applicationId,
			issues: issues,
			serverId: this.utilService.serverId
		};
		return this.methods.post(request, "Application/IgnoreIssues");
	}

	public saveClickerScript(applicationId:string, script: Array<string>) : Promise<boolean> {
		let request: IRQ.IRQSaveClickerScript = {
			applicationId: applicationId,
			script: script,
			serverId: this.utilService.serverId
		};
		return this.methods.post(request, "Application/SaveClickerScript");
	}

	public updateQaValue(applicationId: string, sectionId:string, rowIndex:number, colIndex:number, value:string) : Promise<boolean> {
		let request: IRQ.IRQUpdateQaValue = {
			applicationId: applicationId,
			sectionId:sectionId,
			rowIndex: rowIndex,
			columnIndex: colIndex,
			value:value,
			serverId: this.utilService.serverId
		};
		return this.methods.post(request, "Application/UpdateQaValue");
	}

	public applyCustomRuleFixes(projectId: string, applicationId: string, ruleSetId: string, issues: Array<string>)  : Promise<boolean>{	
		let request: IRQ.IRQApplyCustomRuleFixes = {
			projectId: projectId,
			applicationId: applicationId,
			ruleSetId: ruleSetId,
			issues: issues,
			serverId: this.utilService.serverId
		};
		return this.methods.post(request, "Application/ApplyCustomRuleFixes");
	}

	public runReport(projectId: string, applicationId: string, reportId: string, platformNumber: number, assessmentGroupId: string) : Promise<boolean> {
		let request: IRQ.IRQRunReport = {
			"projectId": projectId,
			"applicationId": applicationId,
			"reportId": reportId,
			"platformNumber": platformNumber,
			"assessmentGroupId": assessmentGroupId,
			"serverId": this.utilService.serverId
		};
		return this.methods.post(request, "Application/RunReport");
	}

	public updateAppId(applicationId: string) : Promise<boolean> {
		let request: IRQ.IRQUpdateAppId = {
			"applicationId": applicationId,
			"serverId": this.utilService.serverId
		};
		return this.methods.post(request, "Application/UpdateAppId");
	}

	public registerApplicationInstallDependency(applicationId: string, relationshipType: number, relationshipSubType: number, value: string) : Promise<CRS.ResponseWrapper<CRS.RSRegisterApplicationInstallDependency>> {
		let request: IRQ.IRQRegisterApplicationInstallDependency = {
			applicationId: applicationId,
			relationshipType: relationshipType,
			relationshipSubType: relationshipSubType,
			value: value,
			serverId: this.utilService.serverId
		};
		return this.methods.postTyped<CRS.RSRegisterApplicationInstallDependency>(request, "Application/RegisterApplicationInstallDependency", ()=>CRS.RSClsFactory.factory(CRS.RSRegisterApplicationInstallDependency));
	}

	public moveApplicationInstallDependencyOrder(applicationId: string, index: number, direction: number) : Promise<CRS.ResponseWrapper<CRS.RSMoveApplicationInstallDependencyOrder>> {
		let request: IRQ.IRQMoveApplicationInstallDependencyOrder = {
			applicationId: applicationId,
			index: index,
			direction: direction,
			serverId: this.utilService.serverId
		};
		return this.methods.postTyped<CRS.RSMoveApplicationInstallDependencyOrder>(request, "Application/MoveApplicationInstallDependencyOrder", ()=>CRS.RSClsFactory.factory(CRS.RSMoveApplicationInstallDependencyOrder));
	}

	public setArguments(applicationId: string, argumentType:number, value: string) : Promise<boolean> {
		let request: IRQ.IRQSetArguments = {
			applicationId: applicationId,
			argumentType: argumentType,
			value: value,
			serverId: this.utilService.serverId
		};
		return this.methods.post(request, "Application/SetArguments");
	}

	public setWorkflowStage(applicationId: string, value: number) : Promise<boolean> {
		let request: IRQ.IRQSetWorkflowStage = {
			applicationId: applicationId,
			value: value,
			serverId: this.utilService.serverId
		};
		return this.methods.post(request, "Application/SetWorkflowStage");
	}

	public setWorkflowStageState(applicationId: string, value: number) : Promise<boolean> {
		let request: IRQ.IRQSetWorkflowStageState = {
			applicationId: applicationId,
			value: value,
			serverId: this.utilService.serverId
		};
		return this.methods.post(request, "Application/SetWorkflowStageState");
	}

	public setWorkflowStageAndState(applicationId: string, stage: number, state: number, assignee:string) : Promise<boolean> {
		let request: IRQ.IRQSetWorkflowStageAndState = {
			applicationId: applicationId,
			stage: stage,
			state: state,
			assignee: assignee,
			serverId: this.utilService.serverId
		};
		return this.methods.post(request, "Application/SetWorkflowStageAndState");
	}

	public setPriority(applicationId: string, value: number) : Promise<boolean> {
		let request: IRQ.IRQSetPriority = {
			applicationId: applicationId,
			value: value,
			serverId: this.utilService.serverId
		};
		return this.methods.post(request, "Application/SetPriority");
	}

	public setAssignee(applicationId: string, assignee: string) : Promise<boolean> {
		let request: IRQ.IRQSetAssignee = {
			applicationId: applicationId,
			assignee: assignee,
			serverId: this.utilService.serverId
		};
		return this.methods.post(request, "Application/SetAssignee");
	}

	public deleteApplicationInstallDependency(applicationId: string, index: number) : Promise<boolean> {
		let request= {
			"applicationId": applicationId,
			"index": index.toString(),
			"serverId": this.utilService.serverId
		};
		return this.methods.delete(request, "Application/DeleteApplicationInstallDependency");
	}

	public deleteCompanionFile(applicationId: string, companionFileId: string): Promise<boolean> {
		let request: IRQ.IRQDeleteCompanionFile = {
			applicationId: applicationId,
			companionFileId: companionFileId,
			serverId: this.utilService.serverId
		};
		return this.methods.post(request, "Application/DeleteCompanionFile");
	}

	public deletePrerequisiteFile(prerequisiteFileId: string):Promise<boolean> {
		let request = {
			"prerequisiteFileId": prerequisiteFileId,
			"serverId": this.utilService.serverId
		}
		return this.methods.post(request, "Application/DeletePrerequisiteFile");
	}

	public setPrerequisiteFileArguments(prerequisiteFileId: string, args: string):Promise<boolean>{
		let request: IRQ.IRQSetPrerequisiteFileArguments = {
			prerequisiteFileId: prerequisiteFileId,
			args: args,
			serverId: this.utilService.serverId
		};
		return this.methods.post(request, "Application/SetPrerequisiteFileArguments");
	}

	public importRepackConversion(projectId: string, applicationId: string) :Promise<boolean>{
		let request: IRQ.IRQImportRepackConversion = {
			projectId: projectId,
			applicationId: applicationId,
			serverId: this.utilService.serverId
		};
		return this.methods.post(request, "Application/ImportRepackConversion");
	}

	public setApplicationNote(applicationId: string, noteId: string, value: string) :Promise<boolean> {
		let request: IRQ.IRQSetApplicationNote = {
			applicationId: applicationId,
			noteId: noteId,
			value: value,
			serverId: this.utilService.serverId
		};
		return this.methods.post(request, "Application/SetApplicationNote");
	}

	public setApplicationFriendlyName(applicationId: string, value: string) :Promise<boolean> {
		let request: IRQ.IRQSetApplicationFriendlyName = {
			applicationId: applicationId,
			value: value,
			serverId: this.utilService.serverId
		};
		return this.methods.post(request, "Application/SetApplicationFriendlyName");
	}

	public setApplicationProductName(applicationId: string, value: string)  :Promise<boolean> {
		let request: IRQ.IRQSetApplicationProductName = {
			applicationId: applicationId,
			value: value,
			serverId: this.utilService.serverId
		};
		return this.methods.post(request, "Application/SetApplicationProductName");
	}

	public setApplicationManufacturer(applicationId: string, value: string): Promise<boolean> {
		let request: IRQ.IRQSetApplicationManufacturer = {
			applicationId: applicationId,
			value: value,
			serverId: this.utilService.serverId
		};
		return this.methods.post(request, "Application/SetApplicationManufacturer");
	}

	public setApplicationCompoundVersion(applicationId: string, value: string) : Promise<boolean> {
		let request: IRQ.IRQSetApplicationCompoundVersion = {
			applicationId: applicationId,
			value: value,
			serverId: this.utilService.serverId
		};
		return this.methods.post(request, "Application/SetApplicationCompoundVersion");
	}

	public setApplicationArchitecture(applicationId: string, value:string) : Promise<boolean> {
		let request: IRQ.IRQSetApplicationArchitecture = {
			applicationId: applicationId,
			value: value,
			serverId: this.utilService.serverId
		};
		return this.methods.post(request, "Application/SetApplicationArchitecture");
	}

	public setApplicationMiddlewareState(applicationId: string, state: boolean) :Promise<Boolean> {
		let request: IRQ.IRQSetApplicationMiddlewareState = {
			applicationId: applicationId,
			state: state,
			serverId: this.utilService.serverId
		};
		return this.methods.post(request, "Application/SetApplicationMiddlewareState");
	}

	public setApplicationVendorState(applicationId: string, state: boolean) :Promise<boolean>{
		let request: IRQ.IRQSetApplicationVendorState = {
			applicationId: applicationId,
			state: state,
			serverId: this.utilService.serverId
		};
		return this.methods.post(request, "Application/SetApplicationVendorState");
	}

	public importExtractedApplication(jobId: string, applicationId: string, msiToExtract: String[]) :Promise<boolean>{
		let request: IRQ.IRQImportExtractedApplication = {
			jobId: jobId,
			applicationId: applicationId,
			msiToExtract: msiToExtract,
			serverId: this.utilService.serverId
		};
		return this.methods.post(request, "Application/ImportExtractedApplication");
	}

}

@Injectable({
	providedIn: 'root',
})
export class AssessmentGroupService {

	constructor(
		private http: HttpClient,
		private authService: AuthService,
		private navigation: NavigationService,
		private utilService: UtilService)
	{ 		
		this.methods = new SvcHttpMethods(http, authService, utilService);
	}

	private methods: SvcHttpMethods;

	public clearCache() : void {
		this.__lastUpdateArgsGetAssessmentGroup=this.utilService.getEmptyGuid();
		this.__lastUpdateArgsGetAssessmentGroup=null;
	}

	private __lastUpdateGetAssessmentGroup: string =this.utilService.getEmptyGuid();
	private __lastUpdateArgsGetAssessmentGroup: any=null;

	public getAssessmentGroup(projectId: string, assessmentGroupId: string, isRefreshCheck: boolean) :Promise<CRS.ResponseWrapper<CRS.RSGetAssessmentGroup>>{

		let args = [this.utilService.stateChangeCounter, this.navigation.url, projectId, assessmentGroupId]
		let irc = isRefreshCheck && this.utilService.areEqual(args, this.__lastUpdateArgsGetAssessmentGroup);
		this.__lastUpdateArgsGetAssessmentGroup = [this.utilService.stateChangeCounter, this.navigation.url, projectId, assessmentGroupId]

		return new Promise<CRS.ResponseWrapper<CRS.RSGetAssessmentGroup>>((resolve, reject) => {

			let request = {
				projectId: projectId,
				assessmentGroupId: assessmentGroupId,
				isRefreshCheck: String(irc),
				lastUpdateId: this.__lastUpdateGetAssessmentGroup.toString(),
				serverId: this.utilService.serverId
			};

			this.http.get<CRS.ResponseWrapper<CRS.RSGetAssessmentGroup>> (this.utilService.getAPIUrl( 'AssessmentGroup/GetAssessmentGroup'),
					{ params:request, headers: this.utilService.getHttpHeaders(), observe: 'response'})
			.toPromise()
			.then((response) => {
				let s = CRS.RSStatus.deserialize(response.body.status);
				let d = (new CRS.RSGetAssessmentGroup()).deserialize(response.body.data);
				this.__lastUpdateGetAssessmentGroup = (d) ? d.updatedActivity : this.utilService.getEmptyGuid();
				resolve(new CRS.ResponseWrapper(d,s));
			},(reason)=> {
				reject(reason);
			});

		});
	}

	private __lastUpdateGetIssuesForCheck: string=this.utilService.getEmptyGuid();
	private __lastUpdateArgsGetIssuesForCheck: any=null;

	public getIssuesForCheck(projectId: string, checkId: string, isRefreshCheck: boolean) {

		let args = [this.utilService.stateChangeCounter, this.navigation.url, projectId, checkId]
		let irc = isRefreshCheck && this.utilService.areEqual(args, this.__lastUpdateArgsGetIssuesForCheck);
		this.__lastUpdateArgsGetIssuesForCheck = [this.utilService.stateChangeCounter, this.navigation.url, projectId, checkId]

		return new Promise<CRS.ResponseWrapper<CRS.RSGetIssuesForCheck>>((resolve, reject) => {

			let request = {
				projectId: projectId,
				checkId: checkId,
				isRefreshCheck: String(irc),
				lastUpdateId: this.__lastUpdateGetIssuesForCheck.toString(),
				serverId: this.utilService.serverId
			};

			this.http.get<CRS.ResponseWrapper<CRS.RSGetIssuesForCheck>> (this.utilService.getAPIUrl( 'AssessmentGroup/GetIssuesForCheck'),
					{ params:request, headers: this.utilService.getHttpHeaders(), observe: 'response'})
			.toPromise()
			.then((response) => {
				let s = CRS.RSStatus.deserialize(response.body.status);
				let d = (new CRS.RSGetIssuesForCheck()).deserialize(response.body.data);
				this.__lastUpdateGetIssuesForCheck = (d) ? d.updatedActivity : this.utilService.getEmptyGuid();
				resolve(new CRS.ResponseWrapper(d,s));
			},(reason)=> {
				reject(reason);
			});
		});
	}

	public getCheckInfo(checkId: string, ruleId: string) : Promise<CRS.ResponseWrapper<CRS.RSGetCheckInfo>> {
		let request = {
			"checkId": checkId,
			"ruleId": ruleId,
			"serverId": this.utilService.serverId
		};
		return this.methods.get<CRS.RSGetCheckInfo>(request, "AssessmentGroup/GetCheckInfo", ()=>CRS.RSClsFactory.factory(CRS.RSGetCheckInfo));
	}
}

@Injectable({
	providedIn: 'root',
})
export class ConversionService {

	constructor(
		private http: HttpClient,
		private authService: AuthService,
		private navigation: NavigationService,
		private utilService: UtilService)
	{ 		
		this.methods = new SvcHttpMethods(http, authService, utilService);
	}

	private methods: SvcHttpMethods;

	public clearCache() : void {
		this.__lastUpdateArgsGetCurrentConversionTasks=null;
		this.__lastUpdateArgsGetCurrentConversionTasksForApplication=null;
		this.__lastUpdateArgsGetConversionTask=null;
	}

	public convertApplication(projectId: string, applicationId: string, conversionTechnology: string, args: string, preRequisites: Array<string>, siblingInstallers: Array<string>, siblingInstallerArguments: Array<string>, companionFiles: Array<string>, virtualiseFromRepack: boolean, fixTransformPlatform: number, virtualiseFromResponseTransform: boolean, useExtractTransform: boolean, msiExtractOnly: boolean, useArpForProductProperties: boolean, usePvad: boolean, pvadDirectory: string, manualModificationRequired: boolean, useExistingSnapshot: boolean) : Promise<CRS.ResponseWrapper<CRS.RSConvertApplication>> {
		let request: IRQ.IRQConvertApplication = {
			projectId: projectId,
			applicationId: applicationId,
			conversionTechnology: conversionTechnology,
			args: args,
			preRequisites: preRequisites,
			siblingInstallers: siblingInstallers,
			siblingInstallerArguments: siblingInstallerArguments,
			companionFiles: companionFiles,
			virtualiseFromRepack: virtualiseFromRepack,
			fixTransformPlatform: fixTransformPlatform,
			virtualiseFromResponseTransform: virtualiseFromResponseTransform,
			useExtractTransform: useExtractTransform,
			msiExtractOnly: msiExtractOnly,
			useArpForProductProperties: useArpForProductProperties,
			usePvad: usePvad,
			pvadDirectory: pvadDirectory,
			manualModificationRequired: manualModificationRequired,
			useExistingSnapshot: useExistingSnapshot,
			serverId: this.utilService.serverId
		};
		return this.methods.postTyped<CRS.RSConvertApplication>(request, "Conversion/ConvertApplication", ()=>CRS.RSClsFactory.factory(CRS.RSConvertApplication));
	}

	public getConversionTaskLaunchInfo(applicationId: string, jobType: number) {
		let request: IRQ.IRQGetConversionTaskLaunchInfo = {
			applicationId: applicationId,
			jobType: jobType,
			serverId: this.utilService.serverId
		};
		return this.methods.get<CRS.RSGetConversionTaskLaunchInfo>(request, "Conversion/GetConversionTaskLaunchInfo", ()=>CRS.RSClsFactory.factory(CRS.RSGetConversionTaskLaunchInfo));
	}

	public executeClickerScript(jobId: string, script:string, hashCode:number) : Promise<CRS.ResponseWrapper<CRS.RSExecuteClickerScript>> {
		let request: IRQ.IRQExecuteClickerScript = {
			jobId: jobId,
			script: script,
			hashCode: hashCode,
			serverId: this.utilService.serverId
		};
		return this.methods.postTyped<CRS.RSExecuteClickerScript>(request, "Conversion/ExecuteClickerScript", ()=>CRS.RSClsFactory.factory(CRS.RSExecuteClickerScript));
	}

	public refreshClickerExecutionStatus(jobId: string, exchangeId:string) : Promise<CRS.ResponseWrapper<CRS.RSClickerExecution>> {
		let request: IRQ.IRQRefreshClickerExecutionStatus = {
			jobId: jobId,
			exchangeId: exchangeId,
			serverId: this.utilService.serverId
		};
		return this.methods.get<CRS.RSClickerExecution>(request, "Conversion/RefreshClickerExecutionStatus", ()=>CRS.RSClsFactory.factory(CRS.RSClickerExecution));
	}

	public publishApplication(jobId: string, showEvalResultsInReadme: boolean, mergeTransformsDuringPublish: boolean) : Promise<CRS.ResponseWrapper<CRS.RSPublishApplication>> {
		let request: IRQ.IRQPublishApplication = {
			jobId: jobId,
			showEvalResultsInReadme: showEvalResultsInReadme,
			mergeTransformsDuringPublish: mergeTransformsDuringPublish,
			serverId: this.utilService.serverId
		};
		return this.methods.postTyped<CRS.RSPublishApplication>(request, "Conversion/PublishApplication", ()=>CRS.RSClsFactory.factory(CRS.RSPublishApplication));
	}

	public publishedTest(jobId: string) : Promise<CRS.ResponseWrapper<CRS.RSPublishedTest>> {
		let request: IRQ.IRQPublishedTest = {
			jobId: jobId,
			serverId: this.utilService.serverId
		};
		return this.methods.postTyped<CRS.RSPublishedTest>(request, "Conversion/PublishedTest", ()=>CRS.RSClsFactory.factory(CRS.RSPublishedTest));
	}

	public releaseTestMachine(jobId: string, testOutcome: string, testText: string) : Promise<boolean> {
		let request: IRQ.IRQReleaseTestMachine = {
			jobId: jobId,
			testOutcome: testOutcome,
			testText: testText,
			serverId: this.utilService.serverId
		};
		return this.methods.post(request, "Conversion/ReleaseTestMachine");
	}

	public getApplicationIdFromJob(jobId: string) : Promise<CRS.ResponseWrapper<CRS.RSGetApplicationIdFromJob>> {
		let request = {
			jobId: jobId,
			serverId: this.utilService.serverId
		}
		return this.methods.get<CRS.RSGetApplicationIdFromJob>(request, "Conversion/GetApplicationIdFromJob", ()=>CRS.RSClsFactory.factory(CRS.RSGetApplicationIdFromJob));
	}

	public setManualModificationStageState(jobId: string, value:number) : Promise<boolean> {
		let request: IRQ.IRQSetManualModificationStageState = {
			jobId: jobId,
			value:value,
			serverId: this.utilService.serverId
		};
		return this.methods.post(request, "Conversion/SetManualModificationStageState");
	}

	public clearEndedConversionTasks(projectId: string) : Promise<boolean> {
		let request: IRQ.IRQClearEndedConversionTasks = {
			projectId: projectId,
			serverId: this.utilService.serverId
		};	
		return this.methods.post(request, "Conversion/ClearEndedConversionTasks");
	}

	public killConversionTaskProcess(jobId: string, processId: number, processCommandLine: string) : Promise<boolean> {
		let request: IRQ.IRQKillConversionTaskProcess = {
			jobId: jobId,
			processId: processId,
			processCommandLine: processCommandLine,
			serverId: this.utilService.serverId
		};	
		return this.methods.post(request, "Conversion/KillConversionTaskProcess");
	}
	
	private __lastUpdateGetCurrentConversionTasks: string="";
	private __lastUpdateArgsGetCurrentConversionTasks: any=null;

	public getCurrentConversionTasks(projectId: string, isRefreshCheck: boolean) :Promise<CRS.ResponseWrapper<CRS.RSGetCurrentConversionTasks>> {

		let args = [this.utilService.stateChangeCounter, this.navigation.url, projectId]
		let irc = isRefreshCheck && this.utilService.areEqual(args, this.__lastUpdateArgsGetCurrentConversionTasks);
		this.__lastUpdateArgsGetCurrentConversionTasks = [this.utilService.stateChangeCounter, this.navigation.url, projectId]

		return new Promise<CRS.ResponseWrapper<CRS.RSGetCurrentConversionTasks>>((resolve, reject) => {

			let request = {
				"projectId": projectId,
				"isRefreshCheck": String(irc),
				"lastUpdateId": this.__lastUpdateGetCurrentConversionTasks.toString(),
				"serverId": this.utilService.serverId
			};
			
			this.http.get<CRS.ResponseWrapper<CRS.RSGetCurrentConversionTasks>> ( this.utilService.getAPIUrl('Conversion/GetCurrentConversionTasks'),
				{ params:request, headers: this.utilService.getHttpHeaders(), observe: 'response'})
			.toPromise()
			.then((response) => {
				let s = CRS.RSStatus.deserialize(response.body.status);
				let d = (new CRS.RSGetCurrentConversionTasks()).deserialize(response.body.data);
				this.__lastUpdateGetCurrentConversionTasks = (d) ? d.updatedActivity : this.utilService.getEmptyGuid();
				resolve(new CRS.ResponseWrapper(d,s));
			},(reason)=> {
				reject(reason);
			});

		});

	}

	private __lastUpdateGetCurrentConversionTasksForApplication: string="";
	private __lastUpdateArgsGetCurrentConversionTasksForApplication: any=null;

	public getCurrentConversionTasksForApplication(applicationId: string, isRefreshCheck: boolean) :Promise<CRS.ResponseWrapper<CRS.RSGetCurrentConversionTasksForApplication>> {

		let args = [this.utilService.stateChangeCounter, this.navigation.url, applicationId]
		let irc = isRefreshCheck && this.utilService.areEqual(args, this.__lastUpdateArgsGetCurrentConversionTasksForApplication);
		this.__lastUpdateArgsGetCurrentConversionTasksForApplication = [this.utilService.stateChangeCounter, this.navigation.url, applicationId]

		return new Promise<CRS.ResponseWrapper<CRS.RSGetCurrentConversionTasksForApplication>>((resolve, reject) => {

			let request = {
				"applicationId": applicationId,
				"isRefreshCheck": String(irc),
				"lastUpdateId": this.__lastUpdateGetCurrentConversionTasksForApplication.toString(),
				"serverId": this.utilService.serverId
			};

			this.http.get<CRS.ResponseWrapper<CRS.RSGetCurrentConversionTasksForApplication>> (this.utilService.getAPIUrl('Conversion/GetCurrentConversionTasksForApplication'),
				{ params:request, headers: this.utilService.getHttpHeaders(), observe: 'response'})
			.toPromise()
			.then((response) => {
				let s = CRS.RSStatus.deserialize(response.body.status);
				let d = (new CRS.RSGetCurrentConversionTasks()).deserialize(response.body.data);
				this.__lastUpdateGetCurrentConversionTasksForApplication = (d) ? d.updatedActivity: this.utilService.getEmptyGuid();
				resolve(new CRS.ResponseWrapper(d,s));
			},(reason)=> {
				reject(reason);
			});

		});

	}

	public getActionsForApplication(applicationId: string, includeUnstarted: boolean, flatten: boolean, platformNumber:number) :Promise<CRS.ResponseWrapper<CRS.RSGetActionsForApplication>> {
		let request = {
			"applicationId": applicationId,
			"includeUnstarted": String(includeUnstarted),
			"flatten": String(flatten),
			"platformNumber": String(platformNumber),
			"serverId": this.utilService.serverId
		};
		return this.methods.get(request, "Conversion/GetActionsForApplication", ()=>CRS.RSClsFactory.factory(CRS.RSGetActionsForApplication));
	}

	public getActionsForProject(projectId: string, includeUnstarted: boolean, flatten: boolean) :Promise<CRS.ResponseWrapper<CRS.RSGetActionsForProject>> {
		let request = {
			"projectId": projectId,
			"includeUnstarted": String(includeUnstarted),
			"flatten": String(flatten),
			"serverId": this.utilService.serverId
		};
		return this.methods.get(request, "Conversion/GetActionsForProject", ()=>CRS.RSClsFactory.factory(CRS.RSGetActionsForProject));
	}

	public getActionImages(jobId: string) :Promise<CRS.ResponseWrapper<CRS.RSGetActionImages>> {
		let request = {
			"jobId": jobId,
			"serverId": this.utilService.serverId
		};
		return this.methods.get(request, "Conversion/GetActionImages", ()=>CRS.RSClsFactory.factory(CRS.RSGetActionImages));
	}

	public getActionDetails(actionType: string, applicationId: string, actionId: string) :Promise<CRS.ResponseWrapper<CRS.RSGetActionDetails>> {
		let request={
			"actionType": actionType,
			"applicationId": applicationId,
			"actionId": actionId,
			"serverId": this.utilService.serverId
		};
		return this.methods.get<CRS.RSGetActionDetails>(request, "Conversion/GetActionDetails", ()=>CRS.RSClsFactory.factory(CRS.RSGetActionDetails));
	}

	public resetApplicationAction(jobId: string) :Promise<boolean> {
		let request: IRQ.IRQResetApplicationAction = {
			jobId: jobId,
			serverId: this.utilService.serverId
		};
		return this.methods.post(request, "Conversion/ResetApplicationAction");
	}
	
	private __lastUpdateGetConversionTask: string="";
	private __lastUpdateArgsGetConversionTask: any=null;

	public getConversionTask(projectId: string, taskId: string, isRefreshCheck: boolean) :Promise<CRS.ResponseWrapper<CRS.RSGetConversionTask>> {

		let args = [this.utilService.stateChangeCounter, this.navigation.url, projectId, taskId]
		let irc = isRefreshCheck && this.utilService.areEqual(args, this.__lastUpdateArgsGetConversionTask);
		this.__lastUpdateArgsGetConversionTask = [this.utilService.stateChangeCounter, this.navigation.url, projectId, taskId]

		return new Promise<CRS.ResponseWrapper<CRS.RSGetConversionTask>>((resolve, reject) => {

			let request = {
				"projectId": projectId,
				"taskId": taskId,
				"isRefreshCheck": String(irc),
				"lastUpdateId": this.__lastUpdateGetConversionTask.toString(),
				"serverId": this.utilService.serverId
			};

			this.http.get<CRS.ResponseWrapper<CRS.RSGetConversionTask>> (this.utilService.getAPIUrl( 'Conversion/GetConversionTask'),
				{ params:request, headers: this.utilService.getHttpHeaders(), observe: 'response'})
			.toPromise()
			.then((response) => {
				let s = CRS.RSStatus.deserialize(response.body.status);
				let d = (new CRS.RSGetConversionTask()).deserialize(response.body.data);
				this.__lastUpdateGetConversionTask = (d) ? d.updatedActivity: this.utilService.getEmptyGuid(); 
				resolve(new CRS.ResponseWrapper(d,s));
			},(reason)=> {
				reject(reason);
			});

		});

	}

	public getBaseImages() : Promise<CRS.ResponseWrapper<CRS.RSGetBaseImages>> {
		var request = {	"serverId": this.utilService.serverId };
		return this.methods.get<CRS.RSGetBaseImages>(request, "Conversion/GetBaseImages", ()=>CRS.RSClsFactory.factory(CRS.RSGetBaseImages));
	}

	public getPatchUpdates() : Promise<CRS.ResponseWrapper<CRS.RSGetPatchUpdates>> {
		var request = { "serverId": this.utilService.serverId };
		return this.methods.get<CRS.RSGetPatchUpdates>(request, "Conversion/GetPatchUpdates", ()=>CRS.RSClsFactory.factory(CRS.RSGetPatchUpdates));
	}

	public getConversionTaskLog(jobId: string) :Promise<CRS.ResponseWrapper<CRS.RSGetConversionTaskLog>> {
		let request= {
			"jobId": jobId,
			"serverId": this.utilService.serverId
		};
		return this.methods.get<CRS.RSGetConversionTaskLog>(request, "Conversion/GetConversionTaskLog", ()=>CRS.RSClsFactory.factory(CRS.RSGetConversionTaskLog));
	}

	public getRepackageObservations(jobId:string): Promise<CRS.ResponseWrapper<CRS.RSGetRepackageObservations>> {
		let request = {
			"jobId":jobId,
			"serverId": this.utilService.serverId
		}
		return this.methods.get<CRS.RSGetRepackageObservations>(request, "Conversion/GetRepackageObservations", ()=>CRS.RSClsFactory.factory(CRS.RSGetRepackageObservations));
	}

	public sendConversionTaskInteraction(projectId: string, processId: number, jobId: string, interactionType: string, interactionTargetId: string, interactionValue: string) :Promise<boolean> {
		let request: IRQ.IRQSendConversionTaskInteraction = {
			projectId: projectId,
			processId: processId,
			jobId: jobId,
			interactionType: interactionType,
			interactionTargetId: interactionTargetId,
			interactionValue: interactionValue,
			serverId: this.utilService.serverId
		};
		return this.methods.post(request, "Conversion/SendConversionTaskInteraction");
	}

	public updateAppId(applicationId: string) :Promise<boolean> {

		let request: IRQ.IRQUpdateAppId = {
			applicationId: applicationId,
			serverId: this.utilService.serverId
		};
		return this.methods.post(request, "Application/UpdateAppId");
	}

	public cancelConversionTask(projectId: string, taskId: string) :Promise<boolean> {
		let request: IRQ.IRQCancelConversionTask = {
			projectId: projectId,
			taskId: taskId,
			serverId: this.utilService.serverId
		};
		return this.methods.post(request, "Conversion/CancelConversionTask");
	}

	public retireWindowsUpdate(id: string) : Promise<boolean> {
		let request: IRQ.IRQRetireWindowsUpdate = {
			id: id,
			serverId: this.utilService.serverId
		};
		return this.methods.post(request, "Conversion/RetireWindowsUpdate");
	}

	public createVirtualMachineFromBaseImage(projectId: string, imageId: string) : Promise<boolean> {
		let request = {
			projectId: projectId,
			imageId: imageId,
			serverId: this.utilService.serverId
		};
		return this.methods.post(request, "Conversion/CreateVirtualMachineFromBaseImage");
	}

	public importXCheckImage(imageId: string, name: string, year: number, month: number) : Promise<boolean> {
		let request: IRQ.IRQImportXCheckImage = {
			imageId: imageId,
			name: name,
			year: year,
			month: month,
			serverId: this.utilService.serverId
		};
		return this.methods.post(request, "Conversion/ImportXCheckImage");
	}

	public getDependencyList() :Promise<CRS.ResponseWrapper<CRS.RSGetDependencyList>> {
		var request = { "serverId" : this.utilService.serverId }
		return this.methods.get<CRS.RSGetDependencyList>(request, "Conversion/GetDependencyList", ()=>CRS.RSClsFactory.factory(CRS.RSGetDependencyList));
	}

	public getWindowsUpdates(projectId: string) : Promise<CRS.ResponseWrapper<CRS.RSGetWindowsUpdates>> {
		let request = {
			projectId: projectId,
			serverId: this.utilService.serverId
		};
		return this.methods.get(request, "Conversion/GetWindowsUpdates", ()=>CRS.RSClsFactory.factory(CRS.RSGetWindowsUpdates));
	}

	public getXCheckVirtualMachines(projectId: string) : Promise<CRS.ResponseWrapper<CRS.RSGetXCheckVirtualMachines>> {
		let request = {
			projectId: projectId,
			serverId: this.utilService.serverId
		};
		return this.methods.get<CRS.RSGetXCheckVirtualMachines>(request, "Conversion/GetXCheckVirtualMachines", ()=>CRS.RSClsFactory.factory(CRS.RSGetXCheckVirtualMachines));
	}

	public deleteVirtualMachineImage(imageId: string) : Promise<boolean> {
		let request = {
			imageId: imageId,
			serverId: this.utilService.serverId
		};
		return this.methods.delete(request, "Conversion/DeleteVirtualMachineImage");
	};

	public getXcheckAvailableVms() :Promise<CRS.ResponseWrapper<CRS.RSGetXcheckAvailableVms>> {
		var request = {	"serverId": this.utilService.serverId }
		return this.methods.get<CRS.RSGetXcheckAvailableVms>(request, "Conversion/GetXCheckAvailableVms", ()=>CRS.RSClsFactory.factory(CRS.RSGetXcheckAvailableVms));
	}

}

@Injectable({
	providedIn: 'root',
})
export class ErrorService {

	constructor(
		private http: HttpClient,
		private authService: AuthService,
		private utilService: UtilService)
	{ 		
		this.methods = new SvcHttpMethods(http, authService, utilService);
	}

	private methods: SvcHttpMethods;


	public clearCache() : void {
	}

	public errorReport(code: string, title: string, text: string, description: string) :Promise<boolean> {
		let request: IRQ.IRQErrorReport = {
			code: code,
			title: title,
			text: text,
			description: description,
			serverId: this.utilService.serverId
		};
		return this.methods.post(request, "Error/Report");
	}

}


@Injectable({
	providedIn: 'root',
})
export class ProjectService {

	constructor(
		private http:HttpClient,
		private authService: AuthService,
		private navigation: NavigationService,
		private utilService: UtilService)
	{ 
		this.methods = new SvcHttpMethods(http, authService, utilService);
	}

	private methods: SvcHttpMethods;

	public clearCache() : void {
		this.__lastUpdateGetProjects=this.utilService.getEmptyGuid();
		this.__lastUpdateArgsGetProject=null;
	}

	private __lastUpdateGetProjects: string= this.utilService.getEmptyGuid();
	private __lastUpdateArgsGetProjects: any=null;

	public getProjects() :Promise<CRS.ResponseWrapper<CRS.RSGetProjects>> {

		return new Promise<CRS.ResponseWrapper<CRS.RSGetProjects>>((resolve, reject) => {

			let request= {
				serverId:this.utilService.serverId
			};

			this.http.get<CRS.ResponseWrapper<CRS.RSGetProjects>> (this.utilService.getAPIUrl(  'Project/GetProjects'),
				{ params:request, headers: this.utilService.getHttpHeaders(), observe: 'response'})
			.toPromise()
			.then((response) => {
				let s = CRS.RSStatus.deserialize(response.body.status);
				let d = (new CRS.RSGetProjects()).deserialize(response.body.data);
				this.__lastUpdateGetProjects = (d) ? d.updatedActivity : this.utilService.getEmptyGuid();
				resolve(new CRS.ResponseWrapper(d,s));
			},(reason)=> {
				reject(reason);
			});

		});

	}

	private __lastUpdateGetProject: string=this.utilService.getEmptyGuid();
	private __lastUpdateArgsGetProject: any=null;

	public getProject(projectId: string, isRefreshCheck: boolean) :Promise<CRS.ResponseWrapper<CRS.RSGetProject>> {

		let args = [this.utilService.stateChangeCounter, this.navigation.url, projectId]
		let irc = isRefreshCheck && this.utilService.areEqual(args, this.__lastUpdateArgsGetProject);
		this.__lastUpdateArgsGetProject = [this.utilService.stateChangeCounter, this.navigation.url, projectId]

		return new Promise<CRS.ResponseWrapper<CRS.RSGetProject>>((resolve, reject) => {

			let request= {
				projectId: projectId,
				isRefreshCheck: String(irc),
				lastUpdateId: this.__lastUpdateGetProject.toString(),
				serverId:this.utilService.serverId
			};

			this.http.get<CRS.ResponseWrapper<CRS.RSGetProject>> (this.utilService.getAPIUrl(  'Project/GetProject'),
				{ params:request, headers: this.utilService.getHttpHeaders(), observe: 'response'})
			.toPromise()
			.then((response) => {
				let s = CRS.RSStatus.deserialize(response.body.status);
				let d = (new CRS.RSGetProject()).deserialize(response.body.data);
				this.__lastUpdateGetProject =  (d) ? d.updatedActivity: this.utilService.getEmptyGuid();
				resolve(new CRS.ResponseWrapper(d,s));
			},(reason)=> {
				reject(reason);
			});

		});

	}

	public searchProjects(serverId:string, searchText:string) :Promise<CRS.ResponseWrapper<CRS.RSSearchProjects>> {
		let request= {
			searchText: searchText,
			serverId: serverId
		};
		return this.methods.get<CRS.RSSearchProjects>(request, "Project/SearchProjects", ()=>CRS.RSClsFactory.factory(CRS.RSSearchProjects));
	}

	public searchProjecPackageSourceFolders(serverId:string, projectId: string, searchText:string) :Promise<CRS.ResponseWrapper<CRS.RSSearchProjectPackageSourceFolders>> {
		let request= {
			projectId: projectId,
			searchText: searchText,
			serverId: serverId
		};
		return this.methods.get<CRS.RSSearchProjectPackageSourceFolders>(request, "Project/searchProjectPackageSourceFolders", ()=>CRS.RSClsFactory.factory(CRS.RSSearchProjectPackageSourceFolders));
	}

	public createProject(name: string, description: string, storageAccessKey: string, image: string, platformRequirements: string, referenceProjectId: string) :Promise<CRS.ResponseWrapper<CRS.RSCreateProject>> {
		let request: IRQ.IRQCreateProject = {
			name: name,
			description: description,
			storageAccessKey: storageAccessKey,
			image: image,
			platformRequirements: platformRequirements,
			referenceProjectId: referenceProjectId,
			serverId: this.utilService.serverId
		};
		return this.methods.postTyped(request, "Project/Create", ()=>CRS.RSClsFactory.factory(CRS.RSCreateProject));
	}

	public hasProject() :Promise<CRS.ResponseWrapper<CRS.RSHasProject>> {
		var request = { "serverId": this.utilService.serverId }
		return this.methods.get<CRS.RSHasProject>(request, "Project/HasProject", ()=>CRS.RSClsFactory.factory(CRS.RSHasProject));
	}

	public updateProject(projectId: string, name: string, description: string, image: string) :Promise<boolean> {
		let request: IRQ.IRQUpdateProject = {
			projectId: projectId,
			name: name,
			description: description,
			image: image,
			serverId: this.utilService.serverId
		};
		return this.methods.post(request, "Project/Update");
	}

	public updateProjectName(projectId: string, name: string) :Promise<boolean> {
		let request: IRQ.IRQUpdateProjectName = {
			"projectId": projectId,
			"name": name,
			"serverId": this.utilService.serverId
		};
		return this.methods.post(request, "Project/UpdateProjectName");
	}

	public updateProjectStorageAccessKey(projectId: string, storageAccessKey: string) :Promise<boolean> {
		let request: IRQ.IRQUpdateProjectStorageAccessKey = {
			projectId: projectId,
			storageAccessKey: storageAccessKey,
			serverId: this.utilService.serverId
		};
		return this.methods.post(request, "Project/UpdateProjectStorageAccessKey");
	}

	public updateProjectDescription(projectId: string, description: string) :Promise<boolean> {
		let request: IRQ.IRQUpdateProjectDescription = {
			projectId: projectId,
			description: description,
			serverId: this.utilService.serverId
		};
		return this.methods.post(request, "Project/UpdateProjectDescription");
	}

	public updateProjectImage(projectId: string, imageType: number, image: string) :Promise<CRS.ResponseWrapper<CRS.RSUpdateProjectImage>> {
		let request: IRQ.IRQUpdateProjectImage = {
			projectId: projectId,
			imageType: imageType,
			image: image,
			serverId: this.utilService.serverId
		};
		return this.methods.postTyped<CRS.RSUpdateProjectImage>(request, "Project/UpdateProjectImage", ()=>CRS.RSClsFactory.factory(CRS.RSUpdateProjectImage));
	}

	public updateDriveLinkSecondaries(projectId: string, applicationId: string, secondaries: string, selectedSecondaries: string) : Promise<boolean> {
		let request: IRQ.IRQUpdateDriveLinkSecondaries = {
			projectId: projectId,
			applicationId: applicationId,
			secondaries: secondaries,
			selectedSecondaries: selectedSecondaries,
			serverId: this.utilService.serverId
		};
		return this.methods.post(request, "Project/UpdateDriveLinkSecondaries");
	}

	public updateCertificatePassword(projectId: string, value: string) : Promise<boolean> {

		return new Promise<boolean>((resolve, reject) => {

			let request: IRQ.IRQUpdateCertificatePassword = {
				projectId: projectId,
				value: value,
				serverId: this.utilService.serverId
			};
			this.http.post<boolean> (this.utilService.getAPIUrl( 'Project/updateCertificatePassword'), 
				{ headers:  this.utilService.getHttpHeaders() })
			.toPromise()
			.then((response)=>{
				let r = true;
				resolve(r);
			}, (reason)=>{
				reject(reason);
			});

		});

	}

	public updatePlatformDefinition(projectPlatformId: string, osIdFrom: string, osIdTo: string, virtualisationTechnology: string, officeSuite: string, dotNet: string, java: string, browser: string, updateDate: string) :Promise<boolean> {
		let request: IRQ.IRQUpdatePlatformDefinition = {
			projectPlatformId: projectPlatformId,
			osIdFrom: osIdFrom,
			osIdTo: osIdTo,
			virtualisationTechnology: virtualisationTechnology,
			officeSuite: officeSuite,
			dotNet: dotNet,
			java: java,
			browser: browser,
			updateDate: updateDate,
			serverId: this.utilService.serverId
		};
		return this.methods.post(request, "Project/UpdatePlatformDefinition");
	}

	public updateProjectPlatformBaseImage(projectPlatformId: string, imageId: string) :Promise<boolean> {
		let request: IRQ.IRQUpdateProjectPlatformBaseImage = {
			projectPlatformId: projectPlatformId,
			imageId: imageId,
			serverId: this.utilService.serverId
		};
		return this.methods.post(request, "Project/UpdateProjectPlatformBaseImage");
	}

	public updateProjectPlatformName(projectPlatformId: string, name: string) :Promise<boolean> {
		let request: IRQ.IRQUpdateProjectPlatformName = {
			projectPlatformId: projectPlatformId,
			name: name,
			serverId: this.utilService.serverId
		};
		return this.methods.post(request, "Project/UpdateProjectPlatformName");
	}

	public setProjectTrackedOSList(projectId: string, operatingSystemConfigurations: Array<string>) :Promise<boolean> {
		let request: IRQ.IRQSetProjectTrackedOSList = {
			projectId: projectId,
			operatingSystemConfigurations: operatingSystemConfigurations,
			serverId: this.utilService.serverId
		};
		return this.methods.post(request, "Project/SetProjectTrackedOSList");
	}

	public deleteProject(projectId: string) :Promise<boolean> {
		let request = {
			"projectId": projectId,
			"serverId": this.utilService.serverId
		};
		return this.methods.delete(request, "Project/Delete");
	}

	public clearImportFailures(projectId: string) :Promise<boolean> {
		let request: IRQ.IRQClearImportFailures = {
			projectId: projectId,
			serverId: this.utilService.serverId
		};
		return this.methods.post(request, "Project/ClearImportFailures");
	}

	public getProjectImage(imageId: string) :Promise<CRS.ResponseWrapper<CRS.RSGetProjectImage>> {
		let request = {
			"imageId": imageId,
			"serverId": this.utilService.serverId
		};
		return this.methods.get<CRS.RSGetProjectImage>(request, "Project/GetImage", ()=>CRS.RSClsFactory.factory(CRS.RSGetProjectImage));
	}

	public setProjectImage(projectId: string, imageData: string) :Promise<boolean> {
		let request: IRQ.IRQSetProjectImage = {
			projectId: projectId,
			imageData: imageData,
			serverId: this.utilService.serverId
		};
		return this.methods.post(request, "Project/SetImage");
	}

	public getDriveLinks(projectId: string) :Promise<CRS.ResponseWrapper<CRS.RSGetDriveLinks>> {
		let request = {
			"projectId": projectId,
			"serverId": this.utilService.serverId
		};
		return this.methods.get<CRS.RSGetDriveLinks>(request, "Project/GetDriveLinks", ()=>CRS.RSClsFactory.factory(CRS.RSGetDriveLinks));
	}

	public getDriveLink(driveLinkId: string) :Promise<CRS.ResponseWrapper<CRS.RSGetDriveLink>> {
		let request = {
			"driveLinkId": driveLinkId,
			"serverId": this.utilService.serverId
		};
		return this.methods.get<CRS.RSGetDriveLink>(request, "Project/GetDriveLink", ()=>CRS.RSClsFactory.factory(CRS.RSGetDriveLink));
	}

	public createDriveLink(projectId: string, driveType: number, definition: string) :Promise<boolean> {
		let request: IRQ.IRQCreateDriveLink = {
			projectId: projectId,
			driveType: driveType,
			definition: definition,
			serverId: this.utilService.serverId
		};
		return this.methods.post(request, "Project/CreateDriveLink");
	}

	public disableDriveLink(projectId: string, definition: string) :Promise<boolean> {
		let request: IRQ.IRQDisableDriveLink = {
			projectId: projectId,
			definition: definition,
			serverId: this.utilService.serverId
		};
		return this.methods.post(request, "Project/DisableDriveLink");
	}

	public initiateDriveScan(projectId: string, quickScan:boolean) :Promise<boolean> {
		let request: IRQ.IRQInitiateDriveScan = {
			projectId: projectId,
			quickScan:quickScan,
			serverId: this.utilService.serverId
		};
		return this.methods.post(request, "Project/InitiateDriveScan");
	}

	public queueDriveLinkImport(driveLinkId: string, applications: Array<string>) :Promise<boolean> {
		let request: IRQ.IRQQueueDriveLinkImport = {
			driveLinkId: driveLinkId,
			applications: applications,
			serverId: this.utilService.serverId
		};
		return this.methods.post(request, "Project/QueueDriveLinkImport");
	}

	public createProjectRule(projectId: string, ruleType: number, targetId: string) :Promise<CRS.ResponseWrapper<CRS.RSCreateProjectRule>> {
		let request: IRQ.IRQCreateProjectRule = {
			projectId: projectId,
			ruleType: ruleType,
			targetId: targetId,
			serverId: this.utilService.serverId
		};
		return this.methods.postTyped<CRS.RSCreateProjectRule>(request, "Project/CreateProjectRule", ()=>CRS.RSClsFactory.factory(CRS.RSCreateProjectRule));
	}

	public updateProjectRule(projectRuleId: string, targetId: string) :Promise<boolean> {
		let request: IRQ.IRQUpdateProjectRule = {
			projectRuleId: projectRuleId,
			targetId: targetId,
			serverId: this.utilService.serverId
		};
		return this.methods.post(request, "Project/UpdateProjectRule");
	}

	public removeProjectRule(projectRuleId: string) :Promise<boolean> {
		let request: IRQ.IRQRemoveProjectRule = {
			projectRuleId: projectRuleId,
			serverId: this.utilService.serverId
		};
		return this.methods.post(request, "Project/RemoveProjectRule");
	}

	public runReport(projectId: string, applicationId: string, reportId: string, platformNumber: number, assessmentGroupId: string) :Promise<boolean> {
		let request: IRQ.IRQRunReport = {
			projectId: projectId,
			applicationId: applicationId,
			reportId: reportId,
			platformNumber: platformNumber,
			assessmentGroupId: assessmentGroupId,
			serverId: this.utilService.serverId
		};
		return this.methods.post(request, "Project/RunReport");
	}

	public getJobStatus(jobID: string) :Promise<CRS.ResponseWrapper<CRS.RSGetJobStatus>> {
		let request = {
			"jobID": jobID,
			"serverId": this.utilService.serverId
		};
		return this.methods.get<CRS.RSGetJobStatus>(request, "Project/GetJobStatus", ()=>CRS.RSClsFactory.factory(CRS.RSGetJobStatus));
	}

	public addProjectShare(projectId: string, userEmail: string) :Promise<boolean> {
		let request: IRQ.IRQAddProjectShare = {
			projectId: projectId,
			userEmail: userEmail,
			serverId: this.utilService.serverId
		};
		return this.methods.post(request, "Project/AddProjectShare");
	}

	public deleteProjectShare(projectId: string, userId: string) :Promise<boolean> {
		let request: IRQ.IRQDeleteProjectShare = {
			projectId: projectId,
			userId: userId,
			serverId: this.utilService.serverId
		};
		return this.methods.post(request, "Project/DeleteProjectShare");
	}

	public getVhds() :Promise<CRS.ResponseWrapper<CRS.RSGetVhds>> {
		let request = {	"serverId": this.utilService.serverId };
		return this.methods.get<CRS.RSGetVhds>(request, "Project/GetVhds", ()=>CRS.RSClsFactory.factory(CRS.RSGetVhds));
	}

	public getMsixBundles() : Promise<CRS.ResponseWrapper<CRS.RSGetMsixBundles>> {
		let request = {	"serverId": this.utilService.serverId };
		return this.methods.get<CRS.RSGetMsixBundles>(request, "Project/GetMsixBundles", ()=>CRS.RSClsFactory.factory(CRS.RSGetMsixBundles));
	}

	public updateConversionTypeVhd(projectId: string, jobType: number, subType: number, vhd: string) :Promise<boolean> {
		let request: IRQ.IRQUpdateConversionTypeVhd = {
			projectId: projectId,
			jobType: jobType,
			subType: subType,
			vhd: vhd,
			serverId: this.utilService.serverId
		};
		return this.methods.post(request, "Project/UpdateConversionTypeVhd");
	}

	public getReportingConfigurations() :Promise<CRS.ResponseWrapper<CRS.RSGetReportingConfigurations>> {
		var request = {	"serverId": this.utilService.serverId };
		return this.methods.get<CRS.RSGetReportingConfigurations>(request, "Project/GetReportingConfigurations", ()=>CRS.RSClsFactory.factory(CRS.RSGetReportingConfigurations));
	}

	public getProjectStandardsDifferences(mainProjectId: string, otherProjectId: string) :Promise<CRS.ResponseWrapper<CRS.RSGetProjectStandardsDifferences>> {
		let request = {
			"mainProjectId": mainProjectId,
			"otherProjectId": otherProjectId,
			"serverId": this.utilService.serverId
		};
		return this.methods.get<CRS.RSGetProjectStandardsDifferences>(request, "Project/GetProjectStandardsDifferences", ()=>CRS.RSClsFactory.factory(CRS.RSGetProjectStandardsDifferences));
	}

	public importAdditionalStandardsChecks(referenceProjectId: string, targetProjectId: string) :Promise<boolean> {
		let request: IRQ.IRQImportAdditionalStandardsChecks = {
			referenceProjectId: referenceProjectId,
			targetProjectId: targetProjectId,
			serverId: this.utilService.serverId
		};
		return this.methods.post(request, "Project/ImportAdditionalStandardsChecks");
	}

	public updateXCheckTrackedOperatingSystems(projectId: string, operatingSystemId: string, checkpoint: string, enabled: boolean, originalTestEnabled: boolean, msixTestEnabled: boolean) :Promise<boolean> {
		let request: IRQ.IRQUpdateXCheckTrackedOperatingSystems = {
			projectId: projectId,
			operatingSystemId: operatingSystemId,
			checkpoint: checkpoint,
			enabled: enabled,
			originalTestEnabled: originalTestEnabled,
			msixTestEnabled: msixTestEnabled,
			serverId: this.utilService.serverId
		};
		return this.methods.post(request, "Project/UpdateXCheckTrackedOperatingSystems");
	}

	public getProjectMetrics(projectId: string, timeoneOffset: number) :Promise<CRS.ResponseWrapper<CRS.RSGetProjectMetrics>> {
		let request = {
			"projectId": projectId,
			"timeoneOffset": String(timeoneOffset),
			"serverId": this.utilService.serverId
		};
		return this.methods.get<CRS.RSGetProjectMetrics>(request, "Project/GetProjectMetrics", ()=>CRS.RSClsFactory.factory(CRS.RSGetProjectMetrics));
	}

	
	public getReferenceData() : Promise<CRS.ResponseWrapper<CRS.RSGetReferenceData>> {
		var request = {
			"serverId":this.utilService.serverId
		}
		return this.methods.get<CRS.RSGetReferenceData>(request, "Project/GetReferenceData", ()=>CRS.RSClsFactory.factory(CRS.RSGetReferenceData));
	}

	public getXCheckMetrics(projectId: string) :Promise<CRS.ResponseWrapper<CRS.RSGetXCheckMetrics>> {
		let request ={
			"projectId": projectId,
			"serverId": this.utilService.serverId
		};
		return this.methods.get<CRS.RSGetXCheckMetrics>(request, "Project/GetXCheckMetrics", ()=>CRS.RSClsFactory.factory(CRS.RSGetXCheckMetrics));
	}
}

@Injectable({
	providedIn: 'root',
})
export class MetaService {

	constructor(
		private http: HttpClient,
		private authService: AuthService,
		private utilService: UtilService)
	{ 		
		this.methods = new SvcHttpMethods(http, authService, utilService);
	}

	private methods: SvcHttpMethods;

	public clearCache() : void {
	}

	public getMetaData(projectId: string) :Promise<CRS.ResponseWrapper<CRS.RSGetMetaData>> {
		let request = {
			"projectId": projectId,
			"serverId": this.utilService.serverId
		};
		return this.methods.get<CRS.RSGetMetaData>(request, "Meta/GetMetaData", ()=>CRS.RSClsFactory.factory(CRS.RSGetMetaData));
	}
}

@Injectable({
	providedIn: 'root',
})
export class MsiService {

	constructor(
		private http: HttpClient,
		private authService: AuthService,
		private utilService: UtilService)
	{ 		
		this.methods = new SvcHttpMethods(http, authService, utilService);
	}

	private methods: SvcHttpMethods;

	public clearCache() : void {
	}

	public msiGetPackageDetails(applicationId: string, platformNumbr: number) :Promise<CRS.ResponseWrapper<CRS.RSMsiGetPackageDetails>> {
		let request = {
			"applicationId": applicationId,
			"platformNumbr": String(platformNumbr),
			"serverId": this.utilService.serverId
		};
		return this.methods.get<CRS.RSMsiGetPackageDetails>(request, "Msi/MsiGetPackageDetails", ()=>CRS.RSClsFactory.factory(CRS.RSMsiGetPackageDetails));
	}

	public msiGetPackageIssueData(applicationId: string, platformNumber: number) :Promise<CRS.ResponseWrapper<CRS.RSMsiGetPackageIssueData>> {
		let request = {
			"applicationId": applicationId,
			"platformNumber": String(platformNumber),
			"serverId": this.utilService.serverId
		};
		return this.methods.get<CRS.RSMsiGetPackageIssueData>(request, "Msi/MsiGetPackageIssueData", ()=>CRS.RSClsFactory.factory(CRS.RSMsiGetPackageIssueData));
	}

	public msiGetPackageTableData(applicationId: string, platformNumber: number, tableExpression: string) :Promise<CRS.ResponseWrapper<CRS.RSMsiGetPackageTableData>> {
		let request = {
			"applicationId": applicationId,
			"platformNumber": String(platformNumber),
			"tableExpression": tableExpression,
			"serverId": this.utilService.serverId
		};
		return this.methods.get<CRS.RSMsiGetPackageTableData>(request, "Msi/MsiGetPackageTableData", ()=>CRS.RSClsFactory.factory(CRS.RSMsiGetPackageTableData));
	}

	public msiFind(applicationId: string, platformNumber: number, findExpression: string) :Promise<CRS.ResponseWrapper<CRS.RSMsiFind>> {
		let request = {
			"applicationId": applicationId,
			"platformNumber": String(platformNumber),
			"findExpression": findExpression,
			"serverId": this.utilService.serverId
		};
		return this.methods.get<CRS.RSMsiFind>(request, "Msi/MsiFind", ()=>CRS.RSClsFactory.factory(CRS.RSMsiFind));
	}

	public msiDeleteKeySet(applicationId: string, platformNumber: number, keys: Array<string>) : Promise<boolean> {
		let request: IRQ.IRQMsiDeleteKeySet = {
			applicationId: applicationId,
			platformNumber: String(platformNumber),
			keys: JSON.stringify(keys),
			serverId: this.utilService.serverId
		};
		return this.methods.post(request, "Msi/MsiDeleteKeySet");
	}

	public msiUndoPackageUpdates(applicationId: string, platformNumber: number, stepCount:number) : Promise<boolean> {
		let request: IRQ.IRQMsiUndoUpdates = {
			applicationId: applicationId,
			platformNumber: String(platformNumber),
			stepCount:String(stepCount),
			serverId: this.utilService.serverId
		};
		return this.methods.post(request, "Msi/MsiUndoUpdates");
	}

	public msiGetPackageRowDependencies(applicationId: string, platformNumber: number, tableName:string, primaryKeys:Array<string>) : Promise<CRS.ResponseWrapper<CRS.RSMsiGetPackageRowDependencies>> {
		let request = {
			"applicationId": applicationId,
			"platformNumber": String(platformNumber),
			"tableName": tableName,
			"primaryKeys": primaryKeys,
			"serverId": this.utilService.serverId
		};
		return this.methods.get<CRS.RSMsiGetPackageRowDependencies>(request, "Msi/MsiGetPackageRowDependencies", ()=>CRS.RSClsFactory.factory(CRS.RSMsiGetPackageRowDependencies));
	}

}

@Injectable({
	providedIn: 'root',
})
export class NotificationService {

	constructor(
		private http: HttpClient,
		private authService: AuthService,
		private utilService: UtilService)
	{ 		
		this.methods = new SvcHttpMethods(http, authService, utilService);
	}

	private methods: SvcHttpMethods;

	public clearCache() : void {
	}

	public getNotifications(projectId: string, fromDate: string) :Promise<CRS.ResponseWrapper<CRS.RSGetNotifications>> {
		let request=  {
			"projectId": projectId,
			"fromDate": fromDate,
			"serverId": this.utilService.serverId
		};
		return this.methods.get<CRS.RSGetNotifications>(request, "Notification/GetNotifications", ()=>CRS.RSClsFactory.factory(CRS.RSGetNotifications));
	}

	public getNotificationsForApplication(projectId: string, applicationId: string, fromDate: string) :Promise<CRS.ResponseWrapper<CRS.RSGetNotificationsForApplication>> {
		let request= {
			"projectId": projectId,
			"applicationId": applicationId,
			"fromDate": fromDate,
			"serverId": this.utilService.serverId
		};
		return this.methods.get<CRS.RSGetNotificationsForApplication>(request, "Notification/GetNotificationsForApplication", ()=>CRS.RSClsFactory.factory(CRS.RSGetNotificationsForApplication));
	}
}

@Injectable({
	providedIn: 'root',
})
export class PatchService {

	constructor(
		private http: HttpClient,
		private authService: AuthService,
		private utilService: UtilService)
	{ 		
		this.methods = new SvcHttpMethods(http, authService, utilService);
	}

	private methods: SvcHttpMethods;

	public clearCache() : void {
	}

	public getPatchGroups() :Promise<CRS.ResponseWrapper<CRS.RSGetPatchGroups>> {
		var request = {
			"serverId": this.utilService.serverId
		};
		return this.methods.get<CRS.RSGetPatchGroups>(request, "Patch/GetPatchGroups", ()=>CRS.RSClsFactory.factory(CRS.RSGetPatchGroups));
	}

	public getPatchGroup(projectId: string, patchId: string, year: number, month: number) :Promise<CRS.ResponseWrapper<CRS.RSGetPatchGroup>> {
		let request= {
			"projectId": projectId,
			"patchId": patchId,
			"year": String(year),
			"month": String(month),
			"serverId": this.utilService.serverId
		};
		return this.methods.get<CRS.RSGetPatchGroup>(request, "Patch/GetPatchGroup", ()=>CRS.RSClsFactory.factory(CRS.RSGetPatchGroup));
	}

	public getPatchExport(year: number, month: number) :Promise<CRS.ResponseWrapper<CRS.RSGetPatchExport>> {
		let request= {
			"year": String(year),
			"month": String(month),
			"serverId": this.utilService.serverId
		};
		return this.methods.get<CRS.RSGetPatchExport>(request, "Patch/GetPatchExport", ()=>CRS.RSClsFactory.factory(CRS.RSGetPatchExport));
	}

	public getPatchConflictSummary(projectId: string, patchId: string) :Promise<CRS.ResponseWrapper<CRS.RSGetPatchConflictSummary>> {
		let request = {
			"projectId": projectId,
			"patchId": patchId,
			"serverId": this.utilService.serverId
		};
		return this.methods.get<CRS.RSGetPatchConflictSummary>(request, "Patch/GetPatchConflictSummary", ()=>CRS.RSClsFactory.factory(CRS.RSGetPatchConflictSummary));
	}

	public getPatchConflictDetails(projectId: string, checkId: string) :Promise<CRS.ResponseWrapper<CRS.RSGetPatchConflictDetails>> {
		let request ={
			"projectId": projectId,
			"checkId": checkId,
			"serverId": this.utilService.serverId
		};
		return this.methods.get<CRS.RSGetPatchConflictDetails>(request, "Patch/GetPatchConflictDetails", ()=>CRS.RSClsFactory.factory(CRS.RSGetPatchConflictDetails));
	}

}

@Injectable({
	providedIn: 'root',
})
export class QueryService {

	constructor(
		private http: HttpClient,
		private authService: AuthService,
		private utilService: UtilService)
	{ 
		this.methods = new SvcHttpMethods(http, authService, utilService);
	}

	private methods: SvcHttpMethods;

	public clearCache() : void {
	}

	public executeQuery(projectId: string, eid: number, typeId: number, outputAttributes: Array<number>, values: Array<string>) :Promise<CRS.ResponseWrapper<CRS.RSExecuteQuery>> {
		let request: IRQ.IRQExecuteQuery = {
			projectId: projectId,
			eid: eid,
			typeId: typeId,
			outputAttributes: outputAttributes,
			values: values,
			serverId: this.utilService.serverId
		};
		return this.methods.postTyped<CRS.RSExecuteQuery>(request, "Query/ExecuteQuery", ()=>CRS.RSClsFactory.factory(CRS.RSExecuteQuery));
	}

	public executeType2Query(projectId: string, ruleJson: string, ruleType: string) :Promise<CRS.ResponseWrapper<CRS.RSExecuteType2Query>> {
		let request: IRQ.IRQExecuteType2Query = {
			projectId: projectId,
			ruleJson: ruleJson,
			ruleType: ruleType,
			serverId: this.utilService.serverId
		};
		return this.methods.postTyped<CRS.RSExecuteType2Query>(request, "Query/ExecuteType2Query", ()=>CRS.RSClsFactory.factory(CRS.RSExecuteType2Query));
	}

	public getCustomQueryResults(jobId: string) :Promise<CRS.ResponseWrapper<CRS.RSGetCustomQueryResults>> {
		let request = {
			"jobId": jobId,
			"serverId": this.utilService.serverId
		};
		return this.methods.get<CRS.RSGetCustomQueryResults>(request, "Query/GetQueryResults", ()=>CRS.RSClsFactory.factory(CRS.RSGetCustomQueryResults));
	}

	public saveCustomQuery(projectId: string, queryName: string, eid: number, typeId: number, outputAttributes: Array<number>, values: Array<string>) :Promise<CRS.ResponseWrapper<CRS.RSSaveCustomQuery>> {
		let request: IRQ.IRQSaveCustomQuery = {
			projectId: projectId,
			queryName: queryName,
			eid: eid,
			typeId: typeId,
			outputAttributes: outputAttributes,
			values: values,
			serverId: this.utilService.serverId
		};
		return this.methods.postTyped<CRS.RSSaveCustomQuery>(request, "Query/SaveCustomQuery", ()=>CRS.RSClsFactory.factory(CRS.RSSaveCustomQuery));
	}

	public getCustomQueries() :Promise<CRS.ResponseWrapper<CRS.RSGetCustomQueries>> {
		var request = {
			"serverId": this.utilService.serverId
		}
		return this.methods.get<CRS.RSGetCustomQueries>(request, "Query/GetCustomQueries", ()=>CRS.RSClsFactory.factory(CRS.RSGetCustomQueries));
	}

}

@Injectable({
	providedIn: 'root',
})
export class StandardsService {

	constructor(
		private http: HttpClient,
		private authService: AuthService,
		private utilService: UtilService)
	{ 
		this.methods = new SvcHttpMethods(http, authService, utilService);
	}

	private methods: SvcHttpMethods;

	public clearCache() : void {
	}

	public getCustomRules(projectId: string) :Promise<CRS.ResponseWrapper<CRS.RSGetCustomRules>> {
		let request = { 
			"projectId": projectId ,
			"serverId": this.utilService.serverId
		};
		return this.methods.get<CRS.RSGetCustomRules>(request, "Standards/GetCustomRules", ()=>CRS.RSClsFactory.factory(CRS.RSGetCustomRules));
	}

}

@Injectable({
	providedIn: 'root',
})
export class UserService {

	constructor(
		private http: HttpClient,
		private authService: AuthService,
		private utilService: UtilService)
	{ 
		this.methods = new SvcHttpMethods(http, authService, utilService);
	}

	private methods: SvcHttpMethods;

	public clearCache() : void {
	}

	public getUserGroup(pattern: string) : Promise<CRS.ResponseWrapper<CRS.RSGetUserGroup>> {
		let request= { pattern: pattern };
		return this.methods.get<CRS.RSGetUserGroup>(request, "User/GetUserGroup", ()=>CRS.RSClsFactory.factory(CRS.RSGetUserGroup));
	}

	public getUserInformation() :Promise<CRS.ResponseWrapper<CRS.RSGetUserInformation>> {
		return this.methods.get<CRS.RSGetUserInformation>({}, "User/GetUserInformation", ()=>CRS.RSClsFactory.factory(CRS.RSGetUserInformation));
	}

	public getUsers() :Promise<CRS.ResponseWrapper<CRS.RSGetUsers>> {
		return this.methods.get<CRS.RSGetUsers>({}, "Users/GetUsers", ()=>CRS.RSClsFactory.factory(CRS.RSGetUsers));
	}

	public getStyling(hostName:string) :Promise<CRS.ResponseWrapper<CRS.RSGetStyling>> {

		let request = {
			"hostName": hostName
		};
		return this.methods.get<CRS.RSGetStyling>(request,"User/GetStyling", ()=> CRS.RSClsFactory.factory(CRS.RSGetStyling));
	}

	public getUserActivity(dateExpr: string) : Promise<CRS.ResponseWrapper<CRS.RSGetUserActivity>> {

		let request = {
			"dateExpr": dateExpr,
			"serverId": this.utilService.serverId
		};
		return this.methods.get<CRS.RSGetUserActivity>(request, "User/GetUserActivity", ()=>CRS.RSClsFactory.factory(CRS.RSGetUserActivity));
	}

	public getHelpInfo() :Promise<CRS.ResponseWrapper<CRS.RSGetHelpInfo>> {
		return this.methods.get<CRS.RSGetHelpInfo>({}, "User/GetHelpInfo", ()=>CRS.RSClsFactory.factory(CRS.RSGetHelpInfo));
	}

	public setCurrentServer(serverId: string) :Promise<boolean> {

		let request: IRQ.IRQSetCurrentServer = {
			serverId: serverId
		};
		return this.methods.post(request, "User/SetCurrentServer");
	}

	public createUserProfileColumnSet(name: string, columns: Array<string>) :Promise<CRS.ResponseWrapper<CRS.RSCreateUserProfileColumnSet>> {
		let request: IRQ.IRQCreateUserProfileColumnSet = {
			name: name,
			columns: columns,
		};
		return this.methods.postTyped(request, "User/CreateUserProfileColumnSet", ()=>CRS.RSClsFactory.factory(CRS.RSCreateUserProfileColumnSet));
	}

	public setPreferredProfileColumnSet(profileId: string) :Promise<boolean> {
		let request: IRQ.IRQSetPreferredProfileColumnSet = {
			profileId: profileId
		};
		return this.methods.post(request, "User/SetPreferredProfileColumnSet");
	}

	public setProfileColumnSetName(profileId: string, name: string) :Promise<boolean> {
		let request: IRQ.IRQSetProfileColumnSetName = {
			profileId: profileId,
			name: name,
		};
		return this.methods.post(request, "User/SetProfileColumnSetName");
	}

	public setProfileColumnSetColumns(profileId: string, columns: Array<string>) :Promise<boolean> {
		let request: IRQ.IRQSetProfileColumnSetColumns = {
			profileId: profileId,
			columns: columns,
		};
		return this.methods.post(request, "User/SetProfileColumnSetColumn");
	}

	public deleteProfileColumnSet(profileId: string) :Promise<boolean> {
		let request: IRQ.IRQDeleteProfileColumnSet = {
			profileId: profileId
		};
		return this.methods.post(request, "User/DeleteProfileColumnSet");
	}

}

@Injectable({
	providedIn: 'root',
})
export class AdminService {

	constructor(
		private http: HttpClient,
		private authService: AuthService,
		private utilService: UtilService)
	{ 		
		this.methods = new SvcHttpMethods(http, authService, utilService);
	}

	private methods: SvcHttpMethods;

	public clearCache() : void {
	}	

	public adminGetAssessmentGroups() : Promise<CRS.ResponseWrapper<CRS.RSAdminGetAssessmentGroups>> {
		return this.methods.get<CRS.RSAdminGetAssessmentGroups>({}, "Admin/AdminGetAssessmentGroups", ()=>CRS.RSClsFactory.factory(CRS.RSAdminGetAssessmentGroups));
	}

	public adminGetAssessmentGroup(assessmentGroupId: string) : Promise<CRS.ResponseWrapper<CRS.RSAdminGetAssessmentGroup>> {
		let request = { "assessmentGroupId": assessmentGroupId };
		return this.methods.get<CRS.RSAdminGetAssessmentGroup>(request, "Admin/AdminGetAssessmentGroup", ()=>CRS.RSClsFactory.factory(CRS.RSAdminGetAssessmentGroup));
	}

	public adminGetActivity(days: number) :Promise<CRS.ResponseWrapper<CRS.RSAdminGetActivity>> {
		let request = {	"days": days.toString()	};
		return this.methods.get<CRS.RSAdminGetActivity>(request, "Admin/AdminGetActivity", ()=>CRS.RSClsFactory.factory(CRS.RSAdminGetActivity));
	}

	public adminGetChecks() : Promise<CRS.ResponseWrapper<CRS.RSAdminGetChecks>> {
		return this.methods.get<CRS.RSAdminGetChecks>({}, "Admin/AdminGetChecks", ()=>CRS.RSClsFactory.factory(CRS.RSAdminGetChecks));
	}

	public adminGetTaskMgr() : Promise<CRS.ResponseWrapper<CRS.RSAdminGetTaskMgr>> {
		return this.methods.get<CRS.RSAdminGetTaskMgr>({}, "Admin/AdminGetTaskMgr", ()=>CRS.RSClsFactory.factory(CRS.RSAdminGetTaskMgr));
	}

	public adminGetUsers() : Promise<CRS.ResponseWrapper<CRS.RSAdminGetUsers>> {
		return this.methods.get<CRS.RSAdminGetUsers>({}, "Admin/AdminGetUsers", ()=>CRS.RSClsFactory.factory(CRS.RSAdminGetUsers));
	}

	public adminGetUser(userId:string) : Promise<CRS.ResponseWrapper<CRS.RSAdminGetUser>> {
		let request = {	"userId": userId };
		return this.methods.get<CRS.RSAdminGetUser>(request, "Admin/AdminGetUser", ()=>CRS.RSClsFactory.factory(CRS.RSAdminGetUser));
	}

	public adminGetCheck(checkId: string) : Promise<CRS.ResponseWrapper<CRS.RSAdminGetCheck>>{
		let request = {	"checkId": checkId };
		return this.methods.get<CRS.RSAdminGetCheck>(request, "Admin/AdminGetCheck", ()=>CRS.RSClsFactory.factory(CRS.RSAdminGetCheck));
	}

	public adminGetCheckRule(checkRuleId: string) : Promise<CRS.ResponseWrapper<CRS.RSAdminGetCheckRule>> {
		let request = { "checkRuleId": checkRuleId };
		return this.methods.get<CRS.RSAdminGetCheckRule>(request, "Admin/AdminGetCheckRule", ()=>CRS.RSClsFactory.factory(CRS.RSAdminGetCheckRule));
	}

	public adminAssGrpGetFilePayload(checkId: string) : Promise<CRS.ResponseWrapper<CRS.RSAdminAssGrpGetFilePayload>> {
		let request = { "checkId": checkId };
		return this.methods.get<CRS.RSAdminAssGrpGetFilePayload>(request, "Admin/AdminAssGrpGetFilePayload", ()=>CRS.RSClsFactory.factory(CRS.RSAdminAssGrpGetFilePayload));
	}

	public adminGetPatchGroups() : Promise<CRS.ResponseWrapper<CRS.RSAdminGetPatchGroups>> {
		return this.methods.get<CRS.RSAdminGetPatchGroups>({}, "Admin/AdminGetPatchGroups", ()=>CRS.RSClsFactory.factory(CRS.RSAdminGetPatchGroups));
	}

	public adminGetVirtualMachines() : Promise<CRS.ResponseWrapper<CRS.RSAdminGetVirtualMachines>> {
		return this.methods.get<CRS.RSAdminGetVirtualMachines>({}, "Admin/AdminGetVirtualMachines", ()=>CRS.RSClsFactory.factory(CRS.RSAdminGetVirtualMachines) );
	}

	public adminGetOperatingSystems() : Promise<CRS.ResponseWrapper<CRS.RSAdminGetOperatingSystems>> {
		return this.methods.get<CRS.RSAdminGetOperatingSystems>({}, "Admin/AdminGetOperatingSystems", ()=>CRS.RSClsFactory.factory(CRS.RSAdminGetOperatingSystems));
	}

	public adminGetWindowsUpdates() : Promise<CRS.ResponseWrapper<CRS.RSAdminGetWindowsUpdates>>{
		return this.methods.get<CRS.RSAdminGetWindowsUpdates>({}, "Admin/AdminGetWindowsUpdates", ()=>CRS.RSClsFactory.factory(CRS.RSAdminGetWindowsUpdates));
	}

	public adminGetPatchGroupItem(patchGroupId: string) : Promise<CRS.ResponseWrapper<CRS.RSAdminGetPatchGroupItem>>{
		let request = {	"patchGroupId": patchGroupId };
		return this.methods.get<CRS.RSAdminGetPatchGroupItem>(request, "Admin/AdminGetPatchGroupItem", ()=>CRS.RSClsFactory.factory(CRS.RSAdminGetPatchGroupItem));
	}

	public adminGetPatchGroupItemSummarised(patchGroupId: string)  {
		let request = {	"patchGroupId": patchGroupId };
		return this.methods.get<CRS.RSAdminGetPatchGroupItemSummarised>(request, "Admin/AdminGetPatchGroupItemSummarised", ()=>CRS.RSClsFactory.factory(CRS.RSAdminGetPatchGroupItemSummarised));
	}

	public adminGetKB(patchGroupId: string, remediationId: string) : Promise<CRS.ResponseWrapper<CRS.RSAdminGetKB>> {
		let request = {
			"patchGroupId": patchGroupId,
			"remediationId": remediationId
		};
		return this.methods.get<CRS.RSAdminGetKB>(request, "Admin/AdminGetKB", ()=>CRS.RSClsFactory.factory(CRS.RSAdminGetKB));
	}

	public adminGetProjects() : Promise<CRS.ResponseWrapper<CRS.RSAdminGetProjects>> {
		return this.methods.get<CRS.RSAdminGetProjects>({}, "Admin/AdminGetProjects", ()=>CRS.RSClsFactory.factory(CRS.RSAdminGetProjects));
	}

	public adminGetJobLogs(serverId: string)  : Promise<CRS.ResponseWrapper<CRS.RSAdminGetJobLogs>> {
		let request = {	"targetServerId": serverId };
		return this.methods.get<CRS.RSAdminGetJobLogs>(request, "Admin/AdminGetJobLogs", ()=>CRS.RSClsFactory.factory(CRS.RSAdminGetJobLogs));
	}

	public adminGetJobLogItem(jobId: string) : Promise<CRS.ResponseWrapper<CRS.RSAdminGetJobLogItem>> {
		let request = {	"jobId": jobId };
		return this.methods.get<CRS.RSAdminGetJobLogItem>(request, "Admin/AdminGetJobLogItem", ()=>CRS.RSClsFactory.factory(CRS.RSAdminGetJobLogItem));
	}

	public adminGetSoftwareVersions() : Promise<CRS.ResponseWrapper<CRS.RSAdminGetSoftwareVersions>> {
		return this.methods.get<CRS.RSAdminGetSoftwareVersions>({}, "Admin/AdminGetSoftwareVersions", ()=>CRS.RSClsFactory.factory(CRS.RSAdminGetSoftwareVersions));
	}

	public adminGetJobStatistics(serverId: string, noDays: number)  : Promise<CRS.ResponseWrapper<CRS.RSAdminGetJobStatistics>> {
		let request = {
			"targetServerId": serverId,
			"noDays": noDays.toString()
		};
		return this.methods.get<CRS.RSAdminGetJobStatistics>(request, "Admin/AdminGetJobStatistics", ()=>CRS.RSClsFactory.factory(CRS.RSAdminGetJobStatistics));
	}

	public adminGetAllApplications() : Promise<CRS.ResponseWrapper<CRS.RSAdminGetAllApplications>> {
		return this.methods.get<CRS.RSAdminGetAllApplications>({}, "Admin/AdminGetAllApplications", ()=>CRS.RSClsFactory.factory(CRS.RSAdminGetAllApplications));
	}

	public adminGetPatchCategories() : Promise<CRS.ResponseWrapper<CRS.RSAdminGetPatchCategories>> {
		return this.methods.get<CRS.RSAdminGetPatchCategories>({}, "Admin/AdminGetPatchCategories", ()=>CRS.RSClsFactory.factory(CRS.RSAdminGetPatchCategories));
	}

	public adminGetPatchCategory(category: string) : Promise<CRS.ResponseWrapper<CRS.RSAdminGetPatchCategory>> {
		let request = {	"category":category	};
		return this.methods.get<CRS.RSAdminGetPatchCategory>(request, "Admin/AdminGetPatchCategory", ()=>CRS.RSClsFactory.factory(CRS.RSAdminGetPatchCategory));
	}

	public adminGetMessageRoutes() : Promise<CRS.ResponseWrapper<CRS.RSAdminGetMessageRoutes>> {
		let request = {	};
		return this.methods.get<CRS.RSAdminGetMessageRoutes>(request, "Admin/AdminGetMessageRoutes", ()=>CRS.RSClsFactory.factory(CRS.RSAdminGetMessageRoutes));
	}

	public adminRequestPatchUpdateCheck() : Promise<boolean> {
		return this.methods.post({}, "Admin/AdminRequestPatchUpdateCheck");
	}

	public adminSetCveCategory(category: string, cve: string) : Promise<boolean> {
		let request:IRQ.IRQAdminSetCveCategory = {
			"category": category,
			"cve": cve
		};
		return this.methods.post(request, "Admin/AdminSetCveCategory");
	}

	public adminUpdateUserFeature(userId: string, feature: string, state:boolean) : Promise<boolean> {
		let request:IRQ.IRQAdminUpdateUserFeature = {
			"userId": userId,
			"feature": feature,
			"state":state
		};
		return this.methods.post(request, "Admin/AdminUpdateUserFeature");
	}
	
	public adminAddPatchCategory(name: string) : Promise<boolean> {
		let request: IRQ.IRQAdminAddPatchCategory = {
			name: name
		};
		return this.methods.post(request, "Admin/AdminAddPatchCategory");
	}

	public adminAddFilenameToPatchCategory(category: string, filename: string) : Promise<boolean> {
		let request: IRQ.IRQAdminAddFilenameToPatchCategory = {
			category: category,
			filename: filename,
		};
		return this.methods.post(request, "Admin/AdminAddFilenameToPatchCategory");
	}

	public adminDeleteFilenameFromPatchCategory(category: string, filename: string) : Promise<boolean> {
		let request: IRQ.IRQAdminDeleteFilenameFromPatchCategory = {
			category: category,
			filename: filename,
		};
		return this.methods.post(request, "Admin/AdminDeleteFilenameFromPatchCategory");
	}

	public adminRetireWindowsUpdate(id: string) : Promise<boolean> {
		let request: IRQ.IRQAdminRetireWindowsUpdate = {
			id: id
		};
		return this.methods.post(request, "Admin/AdminRetireWindowsUpdate");
	}

	public adminDeployWebJobBuild(versionNo: string, serverId: string) : Promise<boolean> {
		let request: IRQ.IRQAdminDeployWebJobBuild = {
			versionNo: versionNo,
			targetServerId: serverId,
		};
		return this.methods.post(request, "Admin/AdminDeployWebJobBuild");
	}

	public adminDeployWebsite(versionNo: string, serverId: string) : Promise<boolean> {
		let request: IRQ.IRQAdminDeployWebsite = {
			versionNo: versionNo,
			targetServerId: serverId,
		};
		return this.methods.post(request, "Admin/AdminDeployWebsite");
	}

	public adminRemotingReset() : Promise<boolean> {
		return this.methods.post({}, "Admin/AdminRemotingReset");
	}

	public refreshMetrics() : Promise<boolean> {
		return this.methods.post({}, "Admin/AdminRefreshMetrics");
	}

	public adminPackageFixingRecorder(projectId: string, version: string) :Promise<boolean> {
		let request: IRQ.IRQAdminPackageFixingRecorder = {
			projectId: projectId,
			version: version,
		};
		return this.methods.post(request, "Admin/AdminPackageFixingRecorder");
	}

	public adminRegisterTestEvent(serverId: string, projectId: string, description: string) : Promise<CRS.ResponseWrapper<CRS.RSAdminRegisterTestEvent>> {
		let request: IRQ.IRQAdminRegisterTestEvent = {
			serverId: serverId,
			projectId: projectId,
			description: description,
		};
		return this.methods.postTyped<CRS.RSAdminRegisterTestEvent>(request, "Admin/AdminRegisterTestEvent", ()=>CRS.RSClsFactory.factory(CRS.RSAdminRegisterTestEvent));
	}

	public adminRegisterTestEventMetric(eventId: string, metricType: string, value: string) : Promise<boolean> {
		let request: IRQ.IRQAdminRegisterTestEventMetric = {
			eventId: eventId,
			metricType: metricType,
			value: value,
		};
		return this.methods.post(request, "Admin/AdminRegisterTestEventMetric");
	}

	public adminUpdateTestEventMetric(eventId: string, metricType: string, value: string) : Promise<boolean> {
		let request: IRQ.IRQAdminUpdateTestEventMetric = {
			eventId: eventId,
			metricType: metricType,
			value: value,
		};
		return this.methods.post(request, "Admin/AdminUpdateTestEventMetric");
	}

	public adminSetPatchGroupComplete(patchGroupId: string) : Promise<boolean> {
		let request: IRQ.IRQAdminSetPatchGroupComplete = {
			patchGroupId: patchGroupId
		};
		return this.methods.post(request, "Admin/AdminSetPatchGroupComplete");
	}

	public adminGeneratePatchBulletin(year: number, month: number) : Promise<boolean> {
		let request: IRQ.IRQAdminGeneratePatchBulletin = {
			year: year,
			month: month
		};
		return this.methods.post(request, "Admin/GeneratePatchBulletin");
	}

	public adminClearCache(type: string) : Promise<boolean> {
		let request: IRQ.IRQAdminClearCache = {
			serverId: this.utilService.serverId,
			type: type
		};
		return this.methods.post(request, "Admin/AdminClearCache");
	}

	public adminUpdateWindowsServiceInfo(id: string, description: string, operatingSystemId: string, enabled: boolean) : Promise<boolean> {
		let request: IRQ.IRQAdminUpdateWindowsServiceInfo = {
			id: id,
			description: description,
			operatingSystemId: operatingSystemId,
			enabled: enabled,
		};
		return this.methods.post(request, "Admin/AdminUpdateWindowsServiceInfo");
	}
}

