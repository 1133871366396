import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CurrentApplicationService } from 'src/app/svc/currentApplicationService';
import { UtilService } from 'src/app/svc/utilService';

import * as CRS from '../../api/CRS';

@Component({
    selector: 'application-browse-undo-dialog',
    templateUrl: './applicationBrowseUndo.html',
    styleUrls: [ ]
})
export class ApplicationBrowseUndoDialog implements OnInit {

    constructor(
        private currentApplication:CurrentApplicationService,
        private utilService: UtilService,
        private dialogRef: MatDialogRef<boolean>,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

    // WK=Working
    private state: string = "RDY";

    ngOnInit() {
    }

    isDataReady() : boolean {
        return !this.utilService.isEmptyAny( this.currentApplication.browser.updates) && this.state == "RDY";
    }

    isValid(): boolean {
        return true;
    }

    isWorking() : boolean {
        return this.state == "WK";
    }

    public apply() : void {
        var noSteps = this.updateList.length;
        this.state = "WK";
        this.currentApplication.undoPackageUpdates(noSteps).then((r)=>{
            this.dialogRef.close(true);
        });
    }

    public get updateList() {
        return this.currentApplication.browser?.updates;
    }

    public get subHeading() {
        var pd= this.currentApplication.browser?.updates;
        if (pd) {
            var pl = (pd.length==1)?"":"s";
            return `${pd.length} update${pl} applied`;
        }
        return null;
    }

    cancelDialog() {
        this.dialogRef.close(null);
    }
}

