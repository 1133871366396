import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CurrentApplicationService } from 'src/app/svc/currentApplicationService';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { UtilService } from 'src/app/svc/utilService';

import * as CRS from '../../api/CRS';

@Component({
    selector: 'application-browse-row-confirmation-dialog',
    templateUrl: './applicationBrowseRowConfirmation.html',
    styleUrls: [ './applicationBrowseRowConfirmation.scss']
})
export class ApplicationBrowseRowConfirmationDialog implements OnInit {

    constructor(
        private currentApplication:CurrentApplicationService,
        private utilService: UtilService,
        private dialogRef: MatDialogRef<boolean>,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

    public tableName:string;
    public row: CRS.RSMsiRow;
    public treeControl :NestedTreeControl<CRS.RSMsiRowDependency>;
    public dataSource: MatTreeNestedDataSource<CRS.RSMsiRowDependency>;

    // WFD=Waiting for dependencies
    // DDR=Data dependencies ready
    // DEL=Deleting
    private state: string = "WFD";
 
    ngOnInit() {
        this.treeControl = new NestedTreeControl<CRS.RSMsiRowDependency>(node => node.children);
        this.dataSource = new MatTreeNestedDataSource<CRS.RSMsiRowDependency>();
        this.tableName = this.data.tableName;
        this.row = this.data.row;
        this.currentApplication.browser.getRowDependencies(this.tableName,this.row).then((r)=>{
            this.dataSource.data= [ r.data.item]
            this.treeControl.expand(r.data.item);
            this.state = "DDR";
        });
    }

    isDataReady() : boolean {
        return true;
    }

    isValid(): boolean {
        return this.isDependencyDataReady();
    }

    public childrenAccessor = (node: CRS.RSMsiRowDependency) => node.children ?? [];

    public hasChild = (_: number, node: CRS.RSMsiRowDependency) =>{
        return !!node.children && node.children.length > 0;
    }

    public isPk(node: CRS.RSMsiRowDependency, col: CRS.RSMsiColumnValue): boolean {
        return node.primaryKeyCols.indexOf(col.columnId) != -1;
    }

    public fkParentFilter(items: Array<CRS.RSMsiColumnValue>): Array<CRS.RSMsiColumnValue> {
        return items.filter(x=>!x.isFkToParent);
    }

    public isWaitingForDependencies() : boolean {
        return this.state == "WFD";
    }
    public isDependencyDataReady(): boolean {
        return this.state == "DDR";
    }
    public isDeleting(): boolean {
        return this.state == "DEL";
    }

    public apply() : void {
        this.state = "DEL";
        var data = <Array<CRS.RSMsiRowDependency>>this.dataSource.data;
        var keys= this.extractKeys(data);
        this.currentApplication.deletePackageKeySet(keys).then((r)=>{
            this.dialogRef.close(true);
        });
    }

    private extractKeys(depRoots: Array<CRS.RSMsiRowDependency>) : Array<string> {
        var ret = [];
        depRoots.forEach(d=> {
            var k = this.utilService.aggregate( d.primaryKeyCols.map(x=>d.row.columns[x].value), '|');
            ret.push(`${d.tableName}@${k}`);
            this.extractKeys(d.children).forEach(x=>ret.push(x));
        });
        return ret;
    }

    cancelDialog() {
        this.dialogRef.close(null);
    }
}

