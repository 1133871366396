import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Constants } from '../api/Constants';
import { UtilService } from './utilService';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {

    constructor(
        private router : Router,
        private utilService: UtilService,
        private route: ActivatedRoute) { 
    }

    public get url() : string {
        return this.router.url;
    }

    public get urlParts() : Array<string> {
        return this.router.url.split("/");
    }

    public getCurrentRouteParameter(name:string): string {
        return this.route.params[name];
    }

    public goAdmin() : Promise<boolean> {
        return this.router.navigate([ 
            Constants.ROUTE_ADMINDASHBOARD 
        ]);
    }
    
    public goHome() : Promise<boolean> {
        return this.router.navigateByUrl("/");
    }
    
    public goError() {
        this.router.navigateByUrl("xxx");
    } 
    
    public goErrorPage() {
        this.router.navigate([Constants.ROUTE_ERROR]);
    }
    
    public goServerLostFULL() {
        this.router.navigate([Constants.ROUTE_ERROR_SERVER_LOST]);
    }

    public goAdminAssessmentGroupsFULL()  : Promise<Boolean> {
        return this.router.navigate([ 
            Constants.ROUTE_ADMINDASHBOARD, 
            Constants.ROUTE_ADMINASSESSMENTGROUPS
        ]);
    }
    
    public goAdminAssessmentGroupFULL(groupId:string): Promise<Boolean>  {
        return this.router.navigate([
            Constants.ROUTE_ADMINDASHBOARD, 
            Constants.ROUTE_ADMINASSESSMENTGROUPS,
            Constants.ROUTE_ADMIN_ASSESSMENT_GROUPS_DETAIL_SLUG,
            groupId
        ]);   
    }

    public goAdminAssessmentGroupCheckFULL(groupId:string, checkId: string) : Promise<Boolean> {
        return this.router.navigate([
            Constants.ROUTE_ADMINDASHBOARD, 
            Constants.ROUTE_ADMINASSESSMENTGROUPS,
            Constants.ROUTE_ADMIN_ASSESSMENT_GROUPS_DETAIL_SLUG,
            groupId,
            Constants.ROUTE_ADMIN_ASSESSMENT_GROUPS_CHECK_SLUG,
            checkId
        ]);
    }

    public goAdminCheckLibrary(): Promise<Boolean>  {
        return this.router.navigate([
             Constants.ROUTE_ADMINDASHBOARD,
             Constants.ROUTE_ADMINCHECKLIBRARY
        ]);
    }
    
    public goAdminCheckLibraryCheckFULL(checkId: string): Promise<Boolean>  {
        return this.router.navigate([
            Constants.ROUTE_ADMINDASHBOARD, 
            Constants.ROUTE_ADMINCHECKLIBRARY,
            Constants.ROUTE_ADMINCHECKDETAIL_MAIN, 
            checkId
        ]);
    }

    public goAdminPatchGroupsFULL(): Promise<Boolean>  {
        return this.router.navigate([
            Constants.ROUTE_ADMINDASHBOARD,
            Constants.ROUTE_ADMINPATCHGROUPS
        ]);
    }

    public goAdminPatchGroupFULL(patchGroupId: string): Promise<Boolean>  {
        return this.router.navigate([
            Constants.ROUTE_ADMINDASHBOARD,
            Constants.ROUTE_ADMINPATCHGROUPS,
            Constants.ROUTE_PATCHGROUP_MAIN,
            patchGroupId]);
    }

    public goAdminPatchGroupItemDetailFULL(remediationId: string): Promise<Boolean>  {
        return this.router.navigate([
            Constants.ROUTE_ADMINDASHBOARD,
            Constants.ROUTE_PATCHGROUPITEMDETAIL_MAIN, 
            remediationId
        ]);
    }

    public goAdminPatchCategoryFiles(pc:string): Promise<Boolean>  {
        return this.router.navigate([Constants.ROUTE_PATCHCATEGORYFILES_MAIN, pc], {relativeTo: this.route})
    }

    public goAdminUser(userId: string): Promise<Boolean>  {
        return this.router.navigate([
            Constants.ROUTE_ADMINDASHBOARD,
            Constants.ROUTE_ADMINUSERS,
            Constants.ROUTE_ADMINUSERDETAIL_MAIN, 
            userId]);
    }

    public navigateToRouteFULL(items: Array<string>): Promise<Boolean>  {
        return this.router.navigate(items);        
    }

    public goProjectApplicationsFULL(projectId: string): Promise<Boolean>  {
        return this.router.navigate([
            Constants.ROUTE_PROJECTDETAILS_MAIN, 
            this.compressGuidWithSvr(projectId),
            Constants.ROUTE_PROJECTAPPLICATIONS ]); 
    }

    public goProjectApplicationDetailsFULL(projectId: string, applicationId: string) : Promise<boolean> {
        return this.router.navigate([
            Constants.ROUTE_PROJECTDETAILS_MAIN,
            this.compressGuidWithSvr(projectId),
            Constants.ROUTE_PROJECTAPPLICATIONS,
            Constants.ROUTE_PROJECTAPPLICATIONLANDING_MAIN,
            this.compressGuid(applicationId)
        ]);   
    }

    public goProjectSubFULL(projectId: string, category:string) : Promise<boolean> {
        return this.router.navigate([
            Constants.ROUTE_PROJECTDETAILS_MAIN,
            this.compressGuidWithSvr(projectId),
            category
        ]);
    }

    public goProjectSettingsFULL(projectId: string, category: string) : Promise<boolean> {
        return this.router.navigate([
            Constants.ROUTE_PROJECTDETAILS_MAIN,
            this.compressGuidWithSvr(projectId),
            Constants.ROUTE_PROJECTSETTINGS,
            category
        ]);
    }

    public goProjectReportAssessmentGroupFULL(projectId: string, agId: string) : Promise<boolean> {
        return this.router.navigate([
            Constants.ROUTE_PROJECTDETAILS_MAIN, 
            this.compressGuidWithSvr(projectId), 
            Constants.ROUTE_PROJECTREPORTS,
            Constants.ROUTE_PROJECTREPORTAG_MAIN, 
            agId
        ]);      
    }

    public goProjectReportsCheckDetailFULL(projectId: string, agId:string, checkId:string) : Promise<boolean> {
        return this.router.navigate([
            Constants.ROUTE_PROJECTDETAILS_MAIN, 
            this.compressGuidWithSvr(projectId), 
            Constants.ROUTE_PROJECTREPORTS,
            Constants.ROUTE_PROJECTREPORTAG_MAIN, 
            agId,
            Constants.ROUTE_PROJECTREPORTCHECKDETAIL_MAIN, 
            checkId
        ]);
    }

    public goProjectActionJobFULL(projectId:string, jobId:string) {
        this.router.navigate([
            Constants.ROUTE_PROJECTDETAILS_MAIN, 
            this.compressGuidWithSvr(projectId), 
            Constants.ROUTE_PROJECTACTIONS,
            Constants.ROUTE_PROJECTACTIONDETAILS_MAIN, 
            this.compressGuid(jobId)
        ]);        
    }

    public goProjectDetailsFULL(projectId: string): Promise<Boolean>  {
        return this.router.navigate([
            Constants.ROUTE_PROJECTDETAILS_MAIN,
            this.compressGuidWithSvr(projectId) 
          ]);
    }

    public goProjectQueriesFULL(projectId:string, category?: string) {
        var qryRoute = category??Constants.ROUTE_PROJECTQUERIESSTANDARDS;
        this.router.navigate([
            Constants.ROUTE_PROJECTDETAILS_MAIN, 
            this.compressGuidWithSvr(projectId), 
            Constants.ROUTE_PROJECTQUERIES,
            qryRoute
        ]);
    }

    public goProjectCreate(serverId?:string): Promise<Boolean>  {
        var svrArg = (serverId)?"?svr="+serverId: "";
        return this.router.navigateByUrl("/" + Constants.ROUTE_PROJECTCREATE + svrArg);
    }

    public goApplicationJobFULL(projectId:string, applicationId:string, jobId: string) : Promise<boolean> {

        var taskStr = (this.utilService.isGuid(jobId))
                ? this.compressGuid(jobId)
                : jobId;

        return this.router.navigate([
            Constants.ROUTE_PROJECTDETAILS_MAIN,
            this.compressGuidWithSvr(projectId),
            Constants.ROUTE_PROJECTAPPLICATIONS,
            Constants.ROUTE_PROJECTAPPLICATIONLANDING_MAIN, 
            this.compressGuid(applicationId),
            Constants.ROUTE_PROJECTAPPLICATIONACTIONSDETAIL_MAIN,
            taskStr]);   
    }

    public goApplicationBrowseTableFULL(projectId: string, applicationId: string, tableName:string): Promise<boolean> {
        return this.router.navigate( [
            Constants.ROUTE_PROJECTDETAILS_MAIN, 
            this.compressGuid(projectId),
            Constants.ROUTE_PROJECTAPPLICATIONS,
            Constants.ROUTE_PROJECTAPPLICATIONLANDING_MAIN, 
            this.compressGuid(applicationId),
            Constants.ROUTE_PROJECTAPPLICATIONBROWSE,
            Constants.ROUTE_PROJECTAPPLICATIONBROWSETABLE_MAIN,
            tableName
          ]);
    }

    public goApplicationBrowseFULL(projectId: string, applicationId: string): Promise<boolean> {
        return this.router.navigate( [
            Constants.ROUTE_PROJECTDETAILS_MAIN, 
            this.compressGuid(projectId),
            Constants.ROUTE_PROJECTAPPLICATIONS,
            Constants.ROUTE_PROJECTAPPLICATIONLANDING_MAIN, 
            this.compressGuid(applicationId),
            Constants.ROUTE_PROJECTAPPLICATIONBROWSE
          ]);
    }

    private compressGuid(g:string): string {
        if(this.utilService.isEmpty(g))
            return g;
        let re = /-/gi;
        return g.replace(re, "");
    }

    private compressGuidWithSvr(g:string): string {
        if(this.utilService.isEmpty(g))
            return g;
        let re = /-/gi;
        return g.replace(re, "") + `@${this.utilService.serverId}`;
    }

    public static expandGuid(id:string) :string {
        if (id && NavigationService.isGuid(id)) {
            return id;
        }
        else if (id) {
            var reg = new RegExp(/^([a-f0-9]{8})([a-f0-9]{4})([a-f0-9]{4})([a-f0-9]{4})([a-f0-9]{12})$/);
            var m = id.match(reg);
            if (m)
                return `${m[1]}-${m[2]}-${m[3]}-${m[4]}-${m[5]}`;
        }
        return id;
    }

    private static isGuid(g:string) :boolean {
        var isEmpty = g === undefined || g == null || g.trim() == ''
        if (isEmpty)
            return false;
        return g.toLocaleLowerCase().match("^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$")!=null;
    }

}
