<div #msiCellTooltip class="msi-issue-tooltip" style="display:none">
    <div fxLayout="column">
        <table width="100%">
            <tr *ngFor="let cri of currentRowIssues">
                <td class="msi-bold">
                    <div class="sw-pad-right">{{cri.checkName}}</div>
                </td>
                <td>{{cri.message}}</td>
            </tr>
        </table>
    </div>
</div>

<div class="full-width-bs full-height-bs sw-segment">

    <div>
        
        <angular-slickgrid gridId="grid1" gridHeight="99%" gridWidth="100%"
            [columnDefinitions]="gridColumnDefs" 
            [gridOptions]="gridOptionsFull"
            [dataset]="tableData"
            (onAngularGridCreated)="appAngularGridReady($event)">
        </angular-slickgrid>  

    </div>

</div>
 


