import { SlickGrid, ColumnSort } from "angular-slickgrid";

export class SortCol {

    constructor(colId:string|number, asc:boolean) {
        this.columnId = colId;
        this.sortAsc = asc;
    }

    public columnId:string|number;
    public sortAsc:boolean;

    public ToColumnSort(grid: SlickGrid) : ColumnSort {
        var c =grid.getColumns().filter(x=>x.id == this.columnId)[0];
        return {grid: grid, columnId: this.columnId, sortAsc: this.sortAsc, sortCol: c};
    }

    public static FromColumnSort(column: ColumnSort) : SortCol {
        return new SortCol(column.columnId, column.sortAsc);
    }
}