<div fxLayout='column' fxFlexFill>  <!--[(ngClass)]='{"sw-viewcontainer-collapsed": vm.$state.current === "editPackage"}'-->

    <div fxFlex fxLayout="column" fxFlexFill fxLayoutAlign="center center" *ngIf="!isReady()">
        <p>PREPARING PACKAGE. PLEASE WAIT A FEW MOMENTS..</p>
        <mat-spinner [diameter]="60"></mat-spinner>
    </div>

    <div fxFlex="none" class='sw-toolbar' *ngIf="isReady()" md-colors="::{background: 'default-primary-500'}">
        <div fxLayout="row" class="toolbar-table" fxLayoutGap="10px">
            <div fxFlex="none">
                <button mat-button (click)='viewPackageSummaryInformationCommand.execute()'
                        [disabled]='!viewPackageSummaryInformationCommand.can' class="button-link-narrow">
                    <mat-icon>event_note</mat-icon>
                    Summary
                </button>
            </div>
            <div fxFlex="none">
                <button mat-button (click)='toggleViewEmptyTablesCommand.execute()' 
                    [disabled]='!toggleViewEmptyTablesCommand.can' class="button-link-narrow">
                    <mat-icon *ngIf="viewEmptyTables" [ngClass]="{'button-on':viewEmptyTables}">done</mat-icon>
                    <mat-icon *ngIf="!viewEmptyTables">clear</mat-icon>
                    Empty
                </button>
            </div>
            <div fxFlex="none">
                <button mat-button (click)='undoPackageModificationCommand.execute()'
                        [disabled]="!undoPackageModificationCommand.can" class="button-link-narrow" *ngIf="undoPackageModificationCommand.show">
                        <mat-icon>refresh</mat-icon>
                        Undo
                </button>
            </div>
            <div fxFlex="none">
                <button mat-button (click)='savePackageCommand.execute()' 
                            [disabled]='!savePackageCommand.can' class="button-link-narrow" *ngIf='savePackageCommand.show'>
                    <mat-icon>save</mat-icon>
                    Save
                </button>
            </div>
            <div fxFlex>
                <div fxLayout="row" fxLayoutAlign="start center">
                    <div fxFlex="nogrow">
                         <input type="text" [(ngModel)]="packageFindCommand.findText" (input)="findTextChanged()">
                    </div>
                     <div fxFlex>
                         <button mat-button class="button-link-narrow" (click)='packageFindCommand.cmd.execute()' [disabled]='!packageFindCommand.cmd.can' >
                             <mat-icon>search</mat-icon>
                         </button>
                     </div>
                    <div fxFlex *ngIf="hasFindResults('=', 0)">
                        <span>No results found</span>
                    </div>
                     <div class="sw-pad" *ngIf="hasFindResults('>', 1)">{{findResultState()}}</div>
                     <div fxFlex *ngIf="hasFindResults('>', 1)">
                         <button mat-button class="button-link-narrow" (click)="findPrevCommand.execute()" [disabled]="!findPrevCommand.can">
                             <mat-icon>keyboard_arrow_left</mat-icon>
                         </button>
                     </div>
                     <div fxFlex *ngIf="hasFindResults('>',1)">
                         <button mat-button class="button-link-narrow" (click)="findNextCommand.execute()" [disabled]="!findNextCommand.can">
                             <mat-icon>keyboard_arrow_right</mat-icon>
                         </button>
                     </div>
                </div>
                <div fxFlex></div>
                <div fxFlex="none">
                    <button mat-button (click)="close()">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div fxFlex="none" class="sw-pad"></div>

    <div fxFlex fxLayout="row" *ngIf="isReady()">
        <div fxFlex="none" class="msi-table-list"> 
            <div class="full-height-bs full-width-bs sw-segment">
                <div>
                    <div *ngIf="packageDetails && packageDetails.status == -1" class="sw-error">FAILED TO LOAD</div>
                    <application-browse-table-selector
                        *ngIf="packageDetails && packageDetails.status == 0"
                         [package]="packageDetails" 
                         [show-empty]="viewEmptyTables"
                         (open-table)="openTable($event)"></application-browse-table-selector>
                </div>  
            </div>
        </div>
        <div fxFlex>
            <router-outlet></router-outlet>
        </div> 
    </div>

</div>
