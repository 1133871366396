import { Component, OnInit, OnDestroy } from '@angular/core';
import { Column, GridOption, Formatter, Formatters, ExtensionName, FieldType, Editors, SlickGrid, GridStateChange, AngularGridInstance, CurrentRowSelection } from 'angular-slickgrid';
import { StateHelperService } from '../../svc/stateHelperService';
import { DriveScanService } from '../../svc/driveScanService';
import { Constants } from '../../api/Constants';
import { DLITEM } from '../../model/DLITEM';
import { Grids } from '../../model/Grids';
import { UtilService } from 'src/app/svc/utilService';
import { CustomInputFilter } from '../../shared/custom-input-filter';
import { SecondariesInputEditor } from './secondariesInputEditor';

@Component({
  selector: Constants.ROUTE_PROJECTDRIVES,
  templateUrl: './project-drives.html',
  styleUrls: ['./project-drives.scss']
})
export class ProjectDrives implements OnInit, OnDestroy {

  constructor(
    private stateHelperService:StateHelperService,
    public driveScanService: DriveScanService,
    private utilService: UtilService,
    private grids:Grids
  ) { }

  public items: Array<DLITEM> = null;
  public columnDefinitions: Column[];
  public gridOptions: GridOption;

  private grid: SlickGrid;
  private gridDataView:any;
  private inTransition:boolean=false;

  ngOnInit(): void {
      this.initialiseGrid();
      this.driveScanService.startPolling(this.stateHelperService.details.projectId, (state)=> {
        this.gridRefresh();
      });  
  }

  ngOnDestroy(): void {
      this.driveScanService.stopPolling();
       if (this.grid)
         this.grid.destroy();
  }

  public handleGridReady(angularGrid: AngularGridInstance) {
    this.grid = angularGrid.slickGrid;
    this.gridDataView = angularGrid.dataView;
    const gmi = angularGrid.extensionService.getSlickgridAddonInstance(ExtensionName.gridMenu);
    gmi.onCommand.subscribe((e,a)=>this.executeGridCommand(e,a));

    var gridFeat = this.grids.features(Constants.ROUTE_PROJECTDRIVES)
    gridFeat.setGrid(angularGrid);
    this.gridDataView.setFilter((r,cf)=>gridFeat.Filter.quickFilter(r,cf, (r,c)=>this.getGridRowValue(r,c)));
    this.grid.onCellChange.subscribe((e,a)=>{
      this.driveScanService.updateTransformSelections(this.driveScanService.items[a.row]);
    });
  }

  private getGridRowValue(r, c): any {
    return r.item.path; // this grid only supports filtering on path
  }

  public handleGridStateChanged(e: GridStateChange) {
    this.grids.gridStateChanged(this.grid, e, (rows)=>{
        var selItems = rows.map(x=>x.id);
        this.driveScanService.setSelectedItems(selItems);
    });
  }

  public isScanning(): boolean {
    return this.driveScanService.isScanInProgress();
  }

  private gridRefresh() {
    if(this.inTransition || !this.grid)
      return;
    this.inTransition=true;
    this.grid.invalidateAllRows();
    this.grid.render();
    this.inTransition=false;
  }

  private initialiseGrid()
  {
    this.columnDefinitions = [
      { id: 'path', name: 'Path', field: 'item', sortable: true, width: 400, filterable:true,
          filter: { model: new CustomInputFilter(), enableTrimWhiteSpace: true },
          formatter : function(row, cell, value, columnDef, dataContext){
            return value.path;
          } 
      },
      { id: 'associatedFiles', name: 'Associated Files', field: 'item', sortable:true, width: 300,
          formatter : function(row, cell, value, columnDef, dataContext){
            return dataContext.getSecondaryState();
          } 
      },
      { id:'transforms',name:'Transforms',field:'selectedSecondariesFlat',width:300,
        type:FieldType.string,
        formatter : function(row, cell, value, columnDef, dataContext){
          return dataContext.selectedSecondariesFlat;
        },
        editor: {
          model: SecondariesInputEditor
        }
      },
      { id: 'created', name: 'Created', field: 'item', sortable:true, width:120, 
        sorter: function(a,b,d){ return d*((a.dateCreated < b.dateCreated)?-1: 1); },
        formatter : function(row, cell, value, columnDef, dataContext){
          return dataContext.dateCreatedString;
        },
      },
      { id: 'loaded', name: 'Loaded', field: 'item', sortable:true, width:50, 
        formatter : customLoadedFormatter
      }
    ];

    this.grids.enableEditing(Constants.ROUTE_PROJECTDRIVES);
    this.grids.enableCheckboxSelector(Constants.ROUTE_PROJECTDRIVES);
    this.grids.enableFiltering(Constants.ROUTE_PROJECTDRIVES);
    this.grids.enableMenu(Constants.ROUTE_PROJECTDRIVES);
    this.grids.setHeaderRowVisibility(Constants.ROUTE_PROJECTDRIVES, this.utilService.localStorageHelper.drivesGridMenuHeaderRowVisible);
    this.grids.disableActiveRowSelection(Constants.ROUTE_PROJECTDRIVES);
    this.gridOptions = this.grids.options(Constants.ROUTE_PROJECTDRIVES);
  }

  private executeGridCommand(e:any,a:any) {
    switch(a.item.command) {
      case Constants.APP_GRID_MENU_CMD_TOGGLE_FILTER:
        this.utilService.localStorageHelper.drivesGridMenuHeaderRowVisible = this.grid.getOptions().showHeaderRow;
        break;
    }
  }

}

const customLoadedFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any, grid: SlickGrid) => {
  var html = '';
  if (value.status != 0) {
      html += '<div class="sw-ok md-24">';
      if (value.status == 1)
          html += '<i class="material-icons" title="Loading">hourglass_empty</i>';
      if (value.status == 2)
          html += '<i class="material-icons" title="Loaded">check_circle</i>';
      if (value.status == 3)
          html += '<i class="material-icons" title="Locked">lock</i>';
      html += '</div>';
  }
  return html;
};


