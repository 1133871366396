import { SortCol } from './SortCol';
import { SlickGrid, ColumnSort, Column, AngularGridInstance } from 'angular-slickgrid';
import { UtilService} from '../svc/utilService';

export class GridSorting {

    constructor(utilService: UtilService) {
        this._utilService = utilService;
    }

    private _grid : SlickGrid;
    private _dataview: any;
    private _sortAction : (any)=>any[];
    private _utilService: UtilService;

    public setGrid(grid: SlickGrid, dataview: any) {
        this._grid = grid;
        this._dataview= dataview;
        this.init();
    }

    public setDataSort(sortAction: (any)=>any[]) {
      this._sortAction = sortAction;
      this.init();
    }

    private init() {
        if (!(this._sortAction && this._grid))
          return;
        
        this._grid.setSortColumns(this.recallCols())
        this._grid.onSort.subscribe((e, args) => {
            var data = this._sortAction(args.sortCols);
            this._grid.getData().setItems(data);
            this._grid.invalidateAllRows();
            this._grid.render();
            this.storeCols(args.sortCols);
        });
      };

      public recallColsMinimum() : SortCol[] {
        var v = this._utilService.localStorageHelper.applicationListSort;
        if (v && v!="[null]") 
        {
             return (<SortCol[]>JSON.parse(v)).map(x=>new SortCol(x.columnId, x.sortAsc));
        }
        return [];
      }

      private recallCols() : ColumnSort[] {
        var v = this._utilService.localStorageHelper.applicationListSort;
        if (v && v!="[null]") 
        {
             var lcl =(<any[]>JSON.parse(v)).map(x=>new SortCol(x.columnId, x.sortAsc));
             return lcl.map(x=>x.ToColumnSort(this._grid));
        }
        return [];
      }
    
      private storeCols(cols: ColumnSort[]) {
        var lcl = cols.map(x=>SortCol.FromColumnSort(x));
        this._utilService.localStorageHelper.applicationListSort = cols? JSON.stringify(lcl): null;
      }
}