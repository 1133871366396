export class ListSpec {

    constructor(propertyName:string, keyProperty:string, type: {new(): any;}) {
        this.propertyName = propertyName;
        this.keyProperty = keyProperty;
        this.type = type;
    }
    public propertyName: string;
    public keyProperty:string;
    public type: { new(): any ;}
}