import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';  
import { DatePipe } from '@angular/common';
import { FileUploadModule } from 'ng2-file-upload';
import { ErrorHandler } from '@angular/core';
import { LocationStrategy, HashLocationStrategy} from '@angular/common';

import { XCheckMain } from './xcheck/xcheck-main/xcheck-main';
import { XCheckResultItem } from './xcheck/xcheck-result-item/xcheck-result-item';
import { XCheckSideNav } from './xcheck/xcheck-sidenav/xcheck-sidenav';
import { XCheckOutputCom } from './xcheck/xcheck-output-com/xcheck-output-com';
import { XCheckOutputDCom } from './xcheck/xcheck-output-dcom/xchreck-output-dcom';
import { XCheckOutputFirewall } from './xcheck/xcheck-output-firewall/xcheck-output-firewall';
import { XCheckOutputCompatibility } from './xcheck/xcheck-output-compatibility/xchreck-output-compatibility';
import { XCheckOutputScheduledTasks } from './xcheck/xcheck-output-scheduled-tasks/xcheck-output-scheduled-tasks';
import { XCheckOutputEnv } from './xcheck/xcheck-output-env/xcheck-output-env';
import { XCheckOutputDiskUsage } from './xcheck/xcheck-output-disk-usage/xcheck-output-disk-usage';
import { XCheckOutputEventLog } from './xcheck/xcheck-output-eventlog/xcheck-output-eventlog';
import { XCheckOutputPrinters } from './xcheck/xcheck-output-printers/xcheck-output-printers';
import { XCheckOutputServices } from './xcheck/xcheck-output-services/xcheck-output-services';
import { XCheckOutputShortcuts } from './xcheck/xcheck-output-shortcuts/xcheck-output-shortcuts';
import { XCheckOutputDefenderExclusions } from './xcheck/xcheck-output-defender-exclusions/xcheck-output-defender-exclusions';

import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatExpansionModule} from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatTreeModule } from '@angular/material/tree';
import { MatSlideToggleModule} from '@angular/material/slide-toggle';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon'
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSliderModule } from '@angular/material/slider';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from "@angular/material/dialog";
import { MatTabsModule } from '@angular/material/tabs';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatMenuModule } from '@angular/material/menu';
import { MatChipsModule } from '@angular/material/chips';
import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { OrderModule } from 'ngx-order-pipe';
import { AngularSlickgridModule } from 'angular-slickgrid';

import 'flatpickr/dist/l10n/fr';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { NgxMutationObserverModule } from 'ngx-mutation-observer';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppErrorHandler } from './app-error-handler';
import { Dashboard } from './project/dashboard/dashboard';
import { AppHttpInterceptHandler } from './app-http-intercept-handler';
import { ErrorView } from './miscViews/error-view';
import { ErrorNotFound } from './miscViews/error-not-found';
import { ErrorServerLost } from './miscViews/error-server-lost';
import { ProjectCreate } from './project/project-create/project-create';
import { ProjectListMenuBar } from './project/project-list-menubar/project-list-menubar';
import { ProjectListMenubarDialog } from './project/project-list-menubar-dialog/project-list-menubar-dialog';
import { ProjectListTable } from './project/project-list-table/project-list-table';
import { ProjectDetails } from './project/project-details/project-details';
import { ProjectDetailsSideNav } from './project/project-details-sidenav/project-details-sidenav';
import { ProjectError } from './project/project-details/project-error';
import { ProjectApplications } from './project/project-applications/project-applications';
import { ProjectApplicationsProfileSelector } from './project/project-applications/project-applications-profile-selector';
import { ProjectSelector } from './project/project-selector/project-selector';
import { CreateGridProfileDialog } from './dialogs/create-grid-profile/create-grid-profile-dialog';
import { AppColumnProfileChip } from './project/project-applications/app-column-profile-chip';
import { AppCompanionList } from './project/project-applications/app-companion-list';
import { AppPrerequisiteList } from './project/project-applications/app-prerequisite-list';
import { ProjectActivityDialog } from './project/project-activity/project-activity-dialog';
import { ProjectDrives } from './project/project-drives/project-drives';
import { ProjectReports } from './project/project-reports/project-reports';
import { ProjectNotifications } from './project/project-notifications/project-notifications';
import { ProjectQueries } from './project/project-queries/project-queries';
import { ProjectPatches } from './project/project-patches/project-patches';
import { ProjectQueriesSideNav } from './project/project-queries/project-queries-sidenav';
import { ProjectQueriesStandards } from './project/project-queries/project-queries-standards';
import { ProjectQueriesChecksheet } from './project/project-queries/project-queries-checksheet';
import { ProjectQueryRulesetTable } from './project/project-queries/project-query-ruleset-table';
import { ProjectQueriesCustom } from './project/project-queries/project-queries-custom';
import { SaveCustomQueryDialog } from './project/project-queries/save-custom-query-dialog';
import { ProjectSettings } from './project/project-settings/project-settings';
import { ProjectActions } from './project/project-actions/project-actions';
import { ProjectActionDetails } from './project/project-actions/project-action-details';
import { ProjectReportsChecks } from './project/project-reports-checks/project-reports-checks';
import { ProjectReportsChecksDetail } from './project/project-reports-checks-detail/project-reports-checks-detail';
import { ProjectXCheck } from './project/project-xcheck/project-xcheck';
import { WindowsUpdateList } from './project/project-xcheck/windows-update-list';
import { XCheckVirtualMachineList } from './project/project-xcheck/xcheck-virtual-machine-list';
import { CreateVmDialog } from './project/project-xcheck/create-vm-dialog';
import { CreateXCheckPatchTestDialog } from './project/project-xcheck/create-xcheck-patch-test-dialog';
import { ImportVmDialog } from './project/project-xcheck/import-vm-dialog';
import { ProjectSettingsSideNav } from './project/project-settings/project-settings-sidenav';
import { ProjectSettingsGeneral } from './project/project-settings/project-settings-general';
import { ProjectSettingsWorkflow } from './project/project-settings/project-settings-workflow';
import { ProjectSettingsVHDs } from './project/project-settings/project-settings-vhds';3
import { ProjectSettingsPublishing } from './project/project-settings/project-settings-publishing';
import { ProjectSettingsScripts } from './project/project-settings/project-settings-scripts';
import { ProjectSettingsConvert } from './project/project-settings/project-settings-convert';
import { ProjectSettingsReporting } from './project/project-settings/project-settings-reporting';
import { ProjectSettingsXCheck } from './project/project-settings/project-settings-xcheck';
import { ProjectSettingsShared } from './project/project-settings/project-settings-shared';
import { ProjectApplicationLanding } from './project/project-applications/project-application-landing';
import { ProjectApplicationDetails } from './project/project-applications/project-application-details';
import { ProjectApplicationDetailHeader } from './project/project-applications/project-application-detail-header';
import { ProjectApplicationImporting } from './project/project-applications/project-application-importing';
import { ProjectApplicationIssues } from './project/project-applications/project-application-issues';
import { ProjectApplicationActionsDetail } from './project/project-applications/project-application-actions-detail';
import { ProjectApplicationNotes } from './project/project-applications/project-application-notes';
import { ProjectApplicationCompanions } from './project/project-applications/project-application-companions';
import { ProjectApplicationOutput } from './project/project-applications/project-application-output';
import { ProjectApplicationQa} from './project/project-applications/project-application-qa';
import { ProjectApplicationHistory } from './project/project-applications/project-application-history';
import { ProjectApplicationDocumentation } from './project/project-applications/project-application-documentation';
import { ProjectApplicationRelatedApps } from './project/project-applications/project-application-related-apps';
import { ProjectApplicationPrerequisites } from './project/project-applications/project-application-prerequisites';
import { ProjectApplicationCore } from './project/project-applications/project-application-core';
import { ProjectApplicationBrowse } from './project/project-applications/project-application-browse';
import { ProjectApplicationBrowseTable } from './project/project-applications/project-application-browse-table';
import { ApplicationBrowseSummaryInfoDialog } from './dialogs/application-browse-summary-info/application-browse-summary-info-dialog';
import { ProjectMenu } from './project/project-menu/project-menu';
import { AssessmentGroupSummaryTable } from './project/project-reports/assessment-group-summary-table';
import { AssessmentGroupCharts } from './project/project-reports/assessment-group-charts';
import { NormalChecksTable } from './project/project-reports-checks/normal-checks-table';
import { PatchChecksTable } from './project/project-reports-checks/patch-checks-table';
import { YesNoDialog } from './svc/dialogs/yes-no-dialog';
import { PromptDialog } from './svc/dialogs/prompt-dialog';
import { SimpleDialog } from './svc/dialogs/simple-dialog';
import { InputDialog } from './svc/dialogs/input-dialog';
import { ApplicationBrowseRowConfirmationDialog } from './dialogs/application-browse-row-confirmation/applicationBrowseRowConfirmation';
import { ApplicationBrowseUndoDialog } from './dialogs/application-browse-undo/applicationBrowseUndo';
import { AdminAddPatchCategoryDialog } from './svc/dialogs/admin-add-patch-category-dialog'
import { AddApplicationDialog } from './dialogs/add-application/add-application-dialog';
import { SetApplicationSourcePathDialog } from './dialogs/set-application-source-path/setApplicationSourcePathDialog';
import { ImageViewDialog } from './dialogs/image-view/image-view';
import { AboutDialog }  from './dialogs/about/about-dialog';
import { HelpDialog }  from './dialogs/help/help-dialog';
import { TableUtils } from './shared/table-utils/table-utils';
import { HeadingBar } from './shared/heading-bar/headingBar';
import { FootingBar } from './shared/footing-bar/footingBar';
import { NotesEditor } from './shared/notes-editor/notes-editor';
import { QaWorksheet } from './shared/qa-worksheet/qa-worksheet';
import { WorkflowStatusCommunication } from './shared/workflow-status-communication/workflow-status-communication';
import { TaskDiskSelector } from './shared/task-disk-selector/task-Disk-Selector';
import { ErrorPanel } from './shared/error-panel/error-panel';
import { Spinner } from './shared/spinner/spinner';
import { SwButtonText } from './shared/sw-button-text/sw-button-text';
import { RagColumn } from './shared/rag-column/rag-column';
import { RagDistribution } from './shared/rag-distribution/rag-distribution';
import { BreadcrumbBar } from './shared/breadcrumb-bar/breadcrumb-bar';
import { PlatformBar } from './shared/platform-bar/platform-bar';
import { ContextToggleBar } from './shared/context-toggle-bar/context-toggle-bar';
import { ProgressBlocks } from './shared/progress-blocks/progress-blocks';
import { BackgroundImageDirective } from './shared/background-image/background-image';
import { ComplexityColumn } from './shared/complexity-column/complexity-column';
import { InputEdit } from './shared/input-edit/input-edit';
import { QaValue } from './shared/qa-value/qa-value';
import { ImageUpload } from './shared/image-upload/image-upload';
import { ContextSelector } from './shared/context-selector/context-selector';
import { HashDirective } from './shared/hash-directive/hash-directive';
import { AppIssuesTable } from './project/project-applications/app-issues-table';
import { ProjectOutputsDialog } from './project/project-outputs/project-outputs-dialog';
import { ProjectOutputSelector } from './project/project-outputs/project-outputs-selector';
import { ConversionTaskFailed } from './shared/conversion-task-failed/conversion-task-failed';
import { ConversionTaskTest } from './shared/conversion-task-test/conversion-task-test';
import { ConversionTaskPublish } from './shared/conversion-task-publish/conversion-task-publish';
import { ConversionTaskPublishTest } from './shared/conversion-task-publish-test/conversion-task-publish-test';
import { ConversionTaskEdit } from './shared/conversion-task-edit/conversion-task-edit';
import { ConversionTaskConvert } from './shared/conversion-task-convert/conversion-task-convert';
import { ConversionTaskConverted } from './shared/conversion-task-converted/conversion-task-converted';
import { ConversionTaskInProgress } from './shared/conversion-task-inprogress/conversion-task-inprogress';
import { ConversionClickerPanel } from './shared/conversion-clicker-panel/conversion-clicker-panel';
import { ConversionTaskOutcomeSummary } from './shared/conversion-task-outcome-summary/conversion-task-outcome-summary';
import { ConversionTaskOutput } from './shared/conversion-task-output/conversion-task-output';
import { ConversionTaskRetained } from './shared/conversion-task-retained/conversion-task-retained';
import { RelatedInstallers } from './shared/related-installers/related-installers';
import { ConversionTaskCompletionInfo } from './shared/conversion-task-completion-info/conversion-task-completion-info';
import { ConversionTaskLogView } from './shared/conversion-task-log-view/conversion-task-log-view';
import { ApplicationCompanionSelector } from './shared/application-companion-selector/application-companion-selector';
import { Bullet } from './shared/bullet/bullet';
import { PlatformEditor } from './shared/platform-editor/platform-editor';
import { WorkflowBar } from './shared/workflow-bar/workflow-bar';
import { XcheckStatus } from './shared/xcheck-status/xcheck-status';
import { MatRangeSlider } from './shared/mat-range-slider/mat-range-slider';
import { FileUploadQueue } from './shared/file-upload-queue/file-upload-queue';
import { ApplicationActionTree } from './shared/application-action-tree/application-action-tree';
import { BigContent } from './shared/big-content/big-content';
import { ImageSizeDirective } from './shared/image-size/image-size';
import { ProcessList } from './shared/process-list/process-list';
import { ApplicationBrowseTableSelector } from './shared/application-browse-table-selector/application-browse-table-selector';
import { NoAuth } from './shared/no-auth/no-auth';
import { PromoInfo } from './shared/promo-info/promo-info';
import { SetWorkflowStateDialog } from './dialogs/set-workflow-state/set-workflow-state-dialog';
import { GridAssistPanel } from './shared/grid-assist-panel/grid-assist-panel';

import { MsiInstallerInfoDialog } from './dialogs/msi-installer-info/msi-installer-info-dialog';
import { SelectApplicationDialog } from './dialogs/select-application/select-application-dialog';
import { CreateInstallDependencyDialog } from './dialogs/create-install-dependency/create-install-dependency-dialog';
import { TestCompleteDialog } from './dialogs/test-complete/test-complete-dialog';
import { SaveMsiEditDialog } from './dialogs/save-msi-edit/save-msi-edit-dialog';
import { RepackageObservationsDialog } from './dialogs/repackage-observations/repackage-observations-dialog';

import { CallbackPipe } from './shared-helper/callback-pipe';

import { StylingService } from './svc/stylingService';
import { UtilService } from './svc/utilService';
import { DownloadService } from './svc/downloadService';
import { CurrentProjectService } from './svc/currentProjectService';
import { CurrentApplicationService } from './svc/currentApplicationService';
import { CurrentConversionJobService} from './svc/currentConversionJobService';
import { CurrentAssessmentGroupService } from './svc/currentAssessmentGroupService';
import { NavigationService } from './svc/navigationService';
import { CurrentDataService } from './svc/currentDataService';
import { DialogService } from './svc/dialogService';
import { StateHelperService } from './svc/stateHelperService';
import { RagValueResolverService } from './svc/ragValueResolverService';
import { PlatformService } from './svc/platformService';
import { MenuService } from './svc/menuService';
import { UserCacheService } from './svc/userCacheService';
import { ErrorService } from './svc/errorService';
import { DriveScanService } from './svc/driveScanService';
import { AppUploadService } from './svc/appUploadService';
import { CertUploadService } from './svc/certUploadService';
import { EventService } from './svc/eventService';
import { AuthService, MSALInstanceFactory, MSALInterceptorConfigFactory, MSALGuardConfigFactory } from './svc/authService';
import { AuthTokenInterceptor } from './svc/authTokenInterceptor';
import { RangeVisualiser } from './shared/range-visualiser/range-visualiser';
import { DistributionBlock } from './shared/distribution-block/distribution-block';
import { ChartsModule } from 'ng2-charts';

import { AdminDashboard } from './admin/admin-dashboard/admin-dashboard';
import { AdminSideNav } from './admin/admin-side-nav/admin-side-nav';
import { AdminCheckLibrary } from './admin/admin-check-library/admin-check-library';
import { AdminCheckDetail } from './admin/admin-check-detail/admin-check-detail';
import { AdminActivity  } from './admin/admin-activity/admin-activity';
import { AdminPatchGroups } from './admin/admin-patch-groups/admin-patch-groups';
import { AdminPatchGroupItem } from './admin/admin-patch-group-item/admin-patch-group-item';
import { AdminPatchKB } from './admin/admin-patch-kb/admin-patch-kb';
import { AdminPatchCats } from './admin/admin-patch-cats/admin-patch-cats';
import { AdminPatchCatFiles } from './admin/admin-patch-cat-files/admin-patch-cat-files';
import { AdminPatchCveCats } from './admin/admin-patch-cve-cats/admin-patch-cve-cats';
import { AdminProjects } from './admin/admin-projects/admin-projects';
import { AdminWindowsUpdates } from './admin/admin-windows-updates/admin-windows-updates';
import { AdminAssessmentGroups } from './admin/admin-assessment-groups/admin-assessment-groups';
import { AdminAssessmentGroupsDetail } from './admin/admin-assessment-groups-detail/admin-assessment-groups-detail';
import { AdminAssessmentGroupCheck } from './admin/admin-assessment-group-check/admin-assessment-group-check';
import { AdminTaskMgr } from './admin/admin-task-mgr/admin-task-mgr';
import { AdminUsers } from './admin/admin-users/admin-users';
import { AdminUserDetail } from './admin/admin-users/admin-user-detail';
import { AdminMessageRoutes } from './admin/admin-message-routes/admin-msg-routes';

@NgModule({
  declarations: [
    AppComponent,
    Dashboard,
    ErrorView,
    ErrorNotFound,
    ErrorServerLost,
    AdminActivity,
    AdminCheckLibrary,
    AdminCheckDetail,
    AdminPatchGroups,
    AdminPatchGroupItem,
    AdminPatchKB,
    AdminPatchCats,
    AdminPatchCatFiles,
    AdminPatchCveCats,
    AdminProjects,
    AdminWindowsUpdates,
    AdminAssessmentGroups,
    AdminAssessmentGroupsDetail,
    AdminAssessmentGroupCheck,
    AdminMessageRoutes,
    AdminDashboard,
    AdminTaskMgr,
    AdminUsers,
    AdminUserDetail,
    AdminSideNav,
    ProjectCreate,
    ProjectListMenuBar,
    ProjectListMenubarDialog,
    ProjectListTable,
    ProjectDetails,
    ProjectDetailsSideNav,
    ProjectError,
    ProjectApplications,
    ProjectApplicationsProfileSelector,
    CreateGridProfileDialog,
    AppColumnProfileChip,
    AppCompanionList,
    AppPrerequisiteList,
    ProjectActivityDialog,
    ProjectDrives,
    ProjectReports,
    ProjectPatches,
    ProjectXCheck,
    WindowsUpdateList,
    XCheckVirtualMachineList,
    ImportVmDialog,
    CreateVmDialog,
    ApplicationBrowseRowConfirmationDialog,
    ApplicationBrowseUndoDialog,
    SetWorkflowStateDialog,
    CreateXCheckPatchTestDialog,
    ProjectReportsChecks,
    ProjectReportsChecksDetail,
    ProjectNotifications,
    ProjectQueries,
    ProjectQueriesStandards,
    ProjectQueriesChecksheet,
    ProjectQueryRulesetTable,
    ProjectQueriesCustom,
    ProjectQueriesSideNav,
    SaveCustomQueryDialog,
    ProjectSettings,
    ProjectSettingsShared,
    ProjectSettingsGeneral,
    ProjectSettingsWorkflow,
    ProjectSettingsVHDs,
    ProjectSettingsPublishing,
    ProjectSettingsScripts,
    ProjectSettingsReporting,
    ProjectSettingsXCheck,
    ProjectSettingsConvert,
    ProjectApplicationLanding,
    ProjectApplicationDetailHeader,
    ProjectApplicationImporting,
    ProjectApplicationDetails,
    ProjectApplicationIssues,
    ProjectApplicationActionsDetail,
    ProjectApplicationNotes,
    ProjectApplicationCompanions,
    ProjectApplicationOutput,
    ProjectApplicationBrowse,
    ProjectApplicationBrowseTable,
    ApplicationBrowseSummaryInfoDialog,
    ProjectApplicationHistory,
    ProjectApplicationQa,
    ProjectApplicationDocumentation,
    ProjectApplicationRelatedApps,
    ProjectApplicationPrerequisites,
    ProjectApplicationCore,
    ProjectActions,
    ProjectActionDetails,
    ProjectSettingsSideNav,
    ProjectMenu,  
    ProjectOutputSelector,
    ProjectOutputsDialog,
    ProjectSelector,
    AssessmentGroupSummaryTable,
    AssessmentGroupCharts,
    NormalChecksTable,
    PatchChecksTable,
    CallbackPipe,
    YesNoDialog,
    PromptDialog,
    SimpleDialog,
    InputDialog,
    AddApplicationDialog,
    SetApplicationSourcePathDialog,
    ImageViewDialog,
    AboutDialog,
    HelpDialog,
    AdminAddPatchCategoryDialog,
    TableUtils,
    HeadingBar,
    FootingBar,
    RagColumn,
    QaWorksheet,
    RagDistribution,
    RangeVisualiser,
    DistributionBlock,
    ProcessList,
    Spinner,
    ErrorPanel,
    GridAssistPanel,
    NotesEditor,
    WorkflowStatusCommunication,
    ComplexityColumn,
    ContextSelector,
    ImageUpload,
    SwButtonText,
    BreadcrumbBar,
    PlatformBar,
    ContextToggleBar,
    BackgroundImageDirective,
    InputEdit,
    PromoInfo,
    QaValue,
    HashDirective,
    TaskDiskSelector,
    AppIssuesTable,
    ConversionTaskFailed,
    ConversionTaskTest,
    ConversionTaskPublish,
    ConversionTaskPublishTest,
    ConversionTaskEdit,
    ConversionTaskConvert,
    ConversionTaskConverted,
    ConversionTaskInProgress,
    ConversionClickerPanel,
    ConversionTaskOutcomeSummary,
    ConversionTaskOutput,
    ConversionTaskRetained,
    ApplicationCompanionSelector,
    RelatedInstallers,
    MsiInstallerInfoDialog,
    RepackageObservationsDialog,
    SelectApplicationDialog,
    CreateInstallDependencyDialog,
    TestCompleteDialog,
    SaveMsiEditDialog,
    ConversionTaskCompletionInfo,
    ConversionTaskLogView,
    ProgressBlocks,
    Bullet,
    ImageSizeDirective,
    ApplicationBrowseTableSelector,
    PlatformEditor,
    WorkflowBar,
    XcheckStatus,
    MatRangeSlider,
    FileUploadQueue,
    XCheckMain,
    XCheckSideNav,
    XCheckResultItem,
    XCheckOutputCom,
    XCheckOutputDCom,
    XCheckOutputFirewall,
    XCheckOutputCompatibility,
    XCheckOutputScheduledTasks,
    XCheckOutputEnv,
    XCheckOutputDiskUsage,
    XCheckOutputEventLog,
    XCheckOutputPrinters,
    XCheckOutputServices,
    XCheckOutputShortcuts,
    XCheckOutputDefenderExclusions,
    ApplicationActionTree,
    BigContent,
    NoAuth
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatToolbarModule,
    HttpClientModule,
    MsalModule,
    MatAutocompleteModule,
    MatListModule,
    MatGridListModule,
    MatSidenavModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatInputModule,
    MatTabsModule,
    MatTableModule,
    MatTooltipModule,
    MatExpansionModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCheckboxModule,
    MatSliderModule,
    MatTreeModule,
    MatIconModule,
    MatDialogModule,
    MatMenuModule,
    NgxSliderModule,
    FlexLayoutModule,
    CommonModule,
    MatChipsModule,
    FileUploadModule,
    AngularSlickgridModule.forRoot({}),
    NgxMutationObserverModule,
    OrderModule,
    ChartsModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptHandler, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthTokenInterceptor, multi: true },
    { provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory },
    { provide: MSAL_GUARD_CONFIG, useFactory: MSALGuardConfigFactory },
    { provide: MSAL_INTERCEPTOR_CONFIG, useFactory: MSALInterceptorConfigFactory },
    { provide: ErrorHandler, useClass: AppErrorHandler },
    { provide: LocationStrategy, useClass: HashLocationStrategy},
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    AuthTokenInterceptor,
    DatePipe,
    StylingService,
    UtilService,
    NavigationService,
    DownloadService,
    CurrentProjectService,
    CurrentApplicationService,
    CurrentConversionJobService,
    CurrentAssessmentGroupService,
    CurrentDataService,
    DialogService,
    StateHelperService,
    RagValueResolverService,
    PlatformService,
    MenuService,
    DriveScanService,
    UserCacheService,
    AppUploadService,
    CertUploadService,
    ErrorService,
    EventService,
    AuthService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }

