import { UtilService } from "../svc/utilService";
import { GridFiltering } from "./GridFiltering";
import { GridSorting } from './GridSorting';
import { AngularGridInstance, SlickGrid } from 'angular-slickgrid';

export class GridFeatures {

    constructor(utilService: UtilService) {
        this._gridSorting = new GridSorting(utilService);
        this._gridFiltering = new GridFiltering(utilService);
    }

    private _gridSorting:  GridSorting;
    private _gridFiltering:  GridFiltering;

    public get Sort(): GridSorting {
        return this._gridSorting;
    }

    public get Filter(): GridFiltering {
        return this._gridFiltering;
    }

    public setGrid(angularGrid: AngularGridInstance) {
        var grid = angularGrid.slickGrid;
        var dv = angularGrid.dataView;
        this._gridSorting.setGrid( grid, dv);
        this._gridFiltering.setGrid(grid, dv);
    }
}