import { Component, OnInit, ElementRef, Inject } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { CurrentConversionJobService } from 'src/app/svc/currentConversionJobService';

import * as CRS from '../../api/CRS';

@Component({
    selector: 'repackage-observations-dialog',
    templateUrl: 'repackage-observations-dialog.html',
    styleUrls:['./repackage-observations-dialog.scss']
})
export class RepackageObservationsDialog implements OnInit {
   
    constructor(
        private dialogRef: MatDialogRef<RepackageObservationsDialog>,
        private conversionService: CurrentConversionJobService
    ) {
    }

    public errorMessage: string;
    public data: CRS.RSGetRepackageObservations;

    public ngOnInit() {
        this.conversionService.getRepackageObservations().then(r=>{
            if (r.status.errorCode == 0) {
                this.data = r.data;
            }
            else {
                this.errorMessage = `${r.status.errorCode} : ${r.status.message}`;
            }
        });
    }

    closeDialog() {
        this.dialogRef.close();
    }

}


