import { Injector } from '@angular/core';
import { UtilService } from '../svc/utilService';
import { MatDialog } from '@angular/material/dialog';
import { CurrentApplicationService } from '../svc/currentApplicationService';
import { ApplicationBrowseRowConfirmationDialog } from '../dialogs/application-browse-row-confirmation/applicationBrowseRowConfirmation';

import * as CRS from '../api/CRS';

export class ConfirmAppBrowseRowDeletion {

    constructor(
        private injector: Injector
    ) {
        this.utilService = this.injector.get(UtilService);
        this.currentApplication = this.injector.get(CurrentApplicationService);
        this.dialog = this.injector.get(MatDialog);
    }

    private utilService: UtilService;
    private currentApplication : CurrentApplicationService;
    private dialog: MatDialog; 

    public confirm(tableName: string, row: CRS.RSMsiRow) {
        const dialogRef = this.dialog.open(ApplicationBrowseRowConfirmationDialog, { width:'1200px', height:'600px', data: {tableName:tableName, row:row}});
        dialogRef.afterClosed().subscribe( answer => {
            if(answer) {
                this.currentApplication.browser.touchTable(tableName);
            }
        });
    }

}