import { Injector } from '@angular/core';
import { MenuService } from '../svc/menuService';
import { Constants } from '../api/Constants';
import { AppCommand } from '../model/AppCommand';
import { MatDialog } from '@angular/material/dialog';
import { CurrentApplicationService } from '../svc/currentApplicationService';
import { ApplicationBrowseUndoDialog } from '../dialogs/application-browse-undo/applicationBrowseUndo';

export class UndoPackageModificationCommand {
 
    constructor(
        private injector: Injector
    ) {
        this.menuService = this.injector.get(MenuService);
        this.currentApp = this.injector.get(CurrentApplicationService);
        this.dialog = this.injector.get(MatDialog);
        this.menuService.register(Constants.MENU_UNDO_PACKAGE_UPDATE, this.cmd, Constants.ROUTE_PROJECTREPORTCHECKDETAIL_MAIN )
        this.currentApp.browser.onTableDataReady().subscribe((d)=>{
            this.menuService.refresh(Constants.MENU_UNDO_PACKAGE_UPDATE);
        });
    }

    private menuService: MenuService;
    private currentApp: CurrentApplicationService;
    private dialog: MatDialog; 

    public apply() {
     const dialogRef = this.dialog.open(ApplicationBrowseUndoDialog, { width:'1200px', height:'600px', data: {}});
        dialogRef.afterClosed().subscribe( answer => {
            if(answer) {
                this.currentApp.browser.touchTable(this.currentApp.browser.currentTable);
            }
        });
    }

    public cmd: AppCommand = AppCommand.createWithShow(
        ()=> {
            return this.currentApp.dataContext.isReady &&
                this.currentApp.browser.updates &&
                this.currentApp.browser.updates.length> 0;
        },
        ()=> {
            this.apply();
        } ,
        ()=> { return this.currentApp.dataContext.isReady && 
            this.currentApp.browser.packageDetails &&
            this.currentApp.browser.packageDetails.canUpdate; }
    );


}