import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'spinner',
  templateUrl: './spinner.html'
})
export class Spinner implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  @Input() text: string
}
