export class MsiBrowseColumnWidths {

    public static getWidth(tableName:string, columnName: string): number {
        var compound = `${tableName}.${columnName}`;
        return MsiBrowseColumnWidths.known[compound]??-1;
    }
    
    private static known: { [id: string]: number; } = {
        "File.Sequence": 20,
        "File.Attributes": 20,
        "File.FileSize": 20,
        "File.Language":20,
        "File.Version": 40,
        "Component.Attributes": 20,
        "Component.ComponentId": 90,
        "Control.X":20,
        "Control.Y":20,
        "Control.Width":20,
        "Control.Height":20,
        "Control.Type":50,
        "Control.Attributes":25,
        "CustomAction.Type":20,
        "Dialog.HCentering":20,
        "Dialog.VCentering":20,
        "Dialog.Width":20,
        "Dialog.Height":20,
        "Dialog.Attributes":30,
        "RadioButton.X":20,
        "RadioButton.Y":20,
        "RadioButton.Width":20,
        "RadioButton.Height":20,
        "RadioButton.Order":20,
        "Registry.Root":20
    };
}