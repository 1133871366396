import { Injectable } from '@angular/core';
import { NavigationStart, Router } from "@angular/router";
import { Observable, Subject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { Constants } from '../api/Constants';
import { LocalStorageHelper } from '../shared-helper/localStorageHelper';
import { ConversionJobHelper } from '../shared-helper/conversionJobHelper';
import { AuthService } from './authService';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Navigation } from '../model/Navigation';

import * as IRS from '../api/IRS';

@Injectable({
    providedIn: 'root'
})
export class UtilService {

    public stateChangeCounter:number =0;
    public lastStateChangeDate:Date=null;
    public localStorageHelper: LocalStorageHelper;
    public conversionJobHelper: ConversionJobHelper;
    private monthNames: Array<string> = [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ];
    private inactivityInterval :number = 1000 * 60 * 30;  // 30mins 
    private apiEndpoint:string;

    constructor( 
        private router: Router,
        private datePipe: DatePipe,
        private authService: AuthService,
        private http:HttpClient
    ) { 
        let lthis =this;
        this.router.events.subscribe(ev => {
            if (ev instanceof NavigationStart) {
                lthis.stateChangeCounter++;
                lthis.lastStateChangeDate=new Date();
            }
        });
        this.svrReady =new Subject<any>();
        this.navReady = new Subject<any>();
        this.localStorageHelper = new LocalStorageHelper(this, this.shortHostname);
        this.conversionJobHelper = new ConversionJobHelper();
        this.lastStateChangeDate=new Date();
        setTimeout(() => {
            this.checkInactivity();
        }, this.inactivityInterval);
    }

    private svrReady: Subject<any>;
    public onServerIdReady() : Observable<any> {
        return this.svrReady.asObservable();
    }

    private navReady: Subject<any>;
    public onNavReady(): Observable<any> {
        return this.navReady.asObservable();
    }

    private checkInactivity = () : void => {
        if (this.timeElapsedSinceStateChange() > 60 * 30) { 
            this.router.navigateByUrl("dashboard");
          }
    };

    public getMonthName(month: number): string {
        if (month >= 0 && month <= 11)
            return this.monthNames[month];
        return "";
    }

    public getDateAsString(date: Date) : string {
        return this.datePipe.transform(date, "dd-MMM-yyyy");
    }

    public getDateAsStringWithTime(date:Date): string {
        return this.datePipe.transform(date, "dd-MMM-yyyy hh:mm:ss")
    }

    public getMonthIndex(monthName: string) : number {
        let vmn : string = monthName.toUpperCase();
        let lst = this.monthNames.filter((v) => v.toUpperCase() == vmn);
        return lst.length==0 ? -1 : this.monthNames.indexOf(lst[0]);
    }

    public timeElapsedSinceStateChange() : number {
        var milliseconds = (new Date()).valueOf() - this.lastStateChangeDate.valueOf()
        var seconds = milliseconds / 1000;
        return seconds;
    }

    public getXCheckUpdateFilterState() : number    {
        let v: number = localStorage[Constants.LOCALSET_XCHECK_UPDATE_FILTER_STATE];
        if (!v)
            return 0;
        return v;
    }

    public setXCheckUpdateFilterState(state:number) : void {
        localStorage[Constants.LOCALSET_XCHECK_UPDATE_FILTER_STATE] = state;
    }

    public getToken() : string {
        let token = sessionStorage.getItem('token');
        if (!token) {
            token = localStorage.getItem('token');
        }
        return token;
    }

    public shortHostname(): string {
        return window.location.hostname.replace(/[^a-z]/g, "");
    }

    public setApiEndpoint(value:string): void {
        this.apiEndpoint = value;
        this.checkEssentialsReady();
    }

    private _serverId:string=null;
    public get serverId(): string {
        return this._serverId;
    }

    public set serverId(value:string) {
        this._serverId = value;
        this.checkEssentialsReady();
    }

    private _essentialsReadyInformed=false;
    public checkEssentialsReady() {
        if (!this._essentialsReadyInformed && this.apiEndpoint && this.serverId)
        {
            this._essentialsReadyInformed=true;
            this.svrReady.next();
        }
    }

    public get privileges(): IRS.IRSUserPrivilege {
        return this._privileges;
    }

    private _navigation: Navigation=null;
    public get navigation(): Navigation {
        if (this.privileges && !this._navigation) {
            this._navigation = new Navigation(this._privileges);
            this.navReady.next();
        }
        return this._navigation;
    }
    
    private _privileges: IRS.IRSUserPrivilege= null;
    public setUserFeatures(privileges: IRS.IRSUserPrivilege) {
        this._privileges = privileges;
    }

    public isValidEmail(emailAddress:string) : boolean   
    {  
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(emailAddress).toLowerCase());
    }

    public isValidUrl(value:string) : boolean 
    {
        if (!value)
            return false;
        var expression = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
        var regex = new RegExp(expression);
        var r= value.match(regex);
        
        return r && r.length ==1;
    }

    public isValidPath(value:string) : boolean {
        var reg = new RegExp(/^[a-zA-Z]:\\[\\\S|*\S]?.*$/gi);
        return reg.test(value);
    }

    public isValidPropertyPrefixedPath(value:string) : boolean {
        var reg = new RegExp(/^%[A-Z]+%\\[\\\S|*\S]?.*$/gi);
        return reg.test(value);
    }

    public isEmpty(value:string)
    {
        return value === undefined || value == null || value.trim() == '';
    }

    public isEmptyAny(value:any)
    {
        return value === undefined || value == null;
    }

    public hasToken(): boolean {
        return !this.isEmpty(localStorage.getItem('token')) || !this.isEmpty(sessionStorage.getItem('token'));
    }

    public getAPIUrl(path:string) : string {
        var u = this.apiEndpoint??"https://app-dev-api.azurewebsites.net/api/"; // reply with default if not set yet
        if (path)
            u = u + path;
        return u;
    }

    public buildAPIUrl(urlPart:string): string {
        var url = this.getAPIUrl(urlPart) ;
        let auth :string = this.getToken();
        if (auth)
            url +=    "&Auth=" + auth;
        return url;
    }

    public getHttpHeaders() : HttpHeaders {
        return new HttpHeaders({'Content-Type': 'application/json; charset=utf-8'});
    }

    public getHelpUrl() : string {
        return window.location.protocol + "://" + window.location.host + ":" + window.location.port + "/#/help/";
    }

    public getFileExtension(filename:string) : string {
        return filename.match(/\.([^\.]+)$/)[1];
    }

    public signOut() {
        localStorage.removeItem('token');
        sessionStorage.removeItem('token');
    }

    public dateOf(utcDateStr:string) {
        var offset = new Date().getTimezoneOffset();
        var d= new Date(utcDateStr);
        d.setMinutes(d.getMinutes() - offset);
        return d;
    }

    private toggleItems : { [key:string] : Array<string> } = {}; 

    public toggleItem(category: string, key:string, state: boolean) : void {

        let tgl = this.toggleItems[category];
        if (!tgl) {
            tgl = new Array<string>();
            this.toggleItems[category] = tgl
        }

        let idx:number = tgl.indexOf(key);
        if (state && idx == -1) {
            tgl.push(key);
        }
        else if (!state && idx != -1) {
            tgl.splice(idx, 1);
        }
    }

    public getToggleState(category : string, key: string) : boolean {

        let tgl = this.toggleItems[category];
        if (!tgl)
            return false;
        
        return tgl.indexOf(key) != -1;;
    }

    public sort(items: Array<any>, propertyName:string) : Array<any> {
        return items.sort((n1,n2) => {
            if (n1[propertyName] > n2[propertyName]) 
                return 1;
            if (n1[propertyName] < n2[propertyName]) 
                return -1;
            return 0;
        });
    }

    public getHashCode(string:string): number {
        let hash = 0;       
        if (string.length == 0) return hash;
        for (var i = 0; i < string.length; i++) {
            var char = string.charCodeAt(i);
            hash = ((hash << 5) - hash) + char;
            hash = hash & hash;
        }
    
        return hash;
    }

    public format(format:string, args: Array<any>) :string {

        let str: string = format;
        if (args.length) {
            var t = typeof arguments[0];
            for (var key in args) {
                str = str.replace(new RegExp("\\{" + key + "\\}", "gi"), args[key]);
            }
        }

        return str;
    };

    public areEqual(a: any, b:any) : boolean {

        if (a==null && b!=null)
            return false;
        if (a!=null && b==null)
            return false;
            
        for (var property in a) {
            if (a.hasOwnProperty(property)) {
                if (b[property] != a[property])
                    return false;
            }
        }
        return true;
    }

    // public GetThemeColor(name: string) : any {
    //     let colours: any = spineAuth.configuration.colours;
    //     return colours[name];
    // }

    public getGuid() : string {
        var d = new Date().getTime();
        if (typeof performance !== 'undefined' && typeof performance.now === 'function'){
            d += performance.now(); //use high-precision timer if available
        }
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }

    public isEmptyGuid(g: string) : boolean {
        if (this.isEmpty(g))
            return false;
        return g == "00000000-0000-0000-0000-000000000000";
    }

    public isEmptyOrEmptyGuid(g: string) : boolean {
        if (this.isEmpty(g))
            return true;
        return g == "00000000-0000-0000-0000-000000000000";
    }

    public getEmptyGuid() : string {
        return  "00000000-0000-0000-0000-000000000000";
    }

    public isGuid(g:string) :boolean {
        if (this.isEmpty(g))
            return false;
        return g.toLocaleLowerCase().match("^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$")!=null;
    }

    public getUrlParameter(sParam:string) :string {

        var url = window.location.href;
        var idx = url.indexOf("?");
        if (idx==-1)
            return "";
        var args = url.substring(idx+1);

        console.log("url=" + url);
        console.log("idx=" + idx);
        console.log("args=" + args);
        
        var sURLVariables = args.split('&') 
    
        for (var i = 0; i < sURLVariables.length; i++) {
            var sParameterName = sURLVariables[i].split('=');
            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? "" : decodeURIComponent(sParameterName[1]);
            }
        }

        return "";
    }

    public getEnumNames(value:any) : Array<string> {
        var ret : Array<string> = [];
        for (var enumMember in value) {
            var isValueProperty = parseInt(enumMember, 10) >= 0
            if (isValueProperty) {
               var v = value[enumMember];
               ret.push(v);
            }
         }
         return ret;
    }

    public fromCamel(value:string) : string {        
        let ret = "";
        value.split('').forEach((x,i)=> {
            if (i>0 && x == x.toUpperCase())
                ret+=" ";
            ret+=x;
        });
        return ret;
    }

    public aggregate(value: Array<string>, delimiter: string) : string {
        var ret = "";
        value.forEach(x=>{
            if (ret!="")
                ret+=delimiter;
            ret+=x;
        });
        return ret;
    }

    // The baseId for actions is postpended with applicationId. Looking for a guid at the end of the id with _ preceeding it
    public removeApplicationIdFromAction(actionId: string) : string {
        if (actionId) {
            var idx = actionId.lastIndexOf('_');
            if (idx!= -1) {
                var g = actionId.substring(idx+1);
                if (this.isGuid(g))
                    return actionId.substring(0, idx);
            }
        }
        return actionId;
    }

    public get showVmsPanel():boolean { return this.localStorageHelper.showVmsPanel; }
    public set showVmsPanel(value:boolean) { this.localStorageHelper.showVmsPanel = value; }
    public get showEmptyTablesInAppBrowse(): boolean { return this.localStorageHelper.viewEmptyTables; }
    public set showEmptyTablesInAppBrowse(value:boolean) { this.localStorageHelper.viewEmptyTables = value; }
    public get viewInternalTablesInAppBrowse(): boolean { return this.localStorageHelper.viewInternalTables; }
    public set viewInternalTablesInAppBrowse(value: boolean) { this.localStorageHelper.viewInternalTables = value; }
    public get contextViewer(): string { return this.localStorageHelper.contextViewer; }
    public set contextViewer(value:string) { this.localStorageHelper.contextViewer = value; }
    public get includeEvalResultsInReadme() :boolean { return this.localStorageHelper.includeEvalResultsInReadme; }
    public set includeEvalResultsInReadme(value:boolean) { this.localStorageHelper.includeEvalResultsInReadme = value; }
    public get mergeTransformsDuringPublish() :boolean { return this.localStorageHelper.mergeTransformsDuringPublish; }
    public set mergeTransformsDuringPublish(value:boolean) { this.localStorageHelper.mergeTransformsDuringPublish = value; }
    public get selectedAssessmentGroupCharts(): Array<string> { return this.localStorageHelper.selectedAssessmentGroupCharts; }
    public set selectedAssessmentGroupCharts(value:Array<string>) { this.localStorageHelper.selectedAssessmentGroupCharts = value; }

    downloadViaApi(url: string, filename:string =null, contentType:string=null) {

        let pdfReqBody = {
            // any usefull data you would want to send to service
        }
         this.http.get(url,  { responseType: 'arraybuffer'}).subscribe(data =>{

            const blob = new Blob([data], {
                type: contentType??'application/zip'
              });
              const url = window.URL.createObjectURL(blob);
              window.open(url);
        });
    }
}
