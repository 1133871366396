import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Column, GridOption, FieldType, SlickGrid,  ExtensionName} from 'angular-slickgrid';
import { Subscription } from 'rxjs';
import { Constants } from '../../api/Constants';
import { UtilService } from '../../svc/utilService';
import { CurrentApplicationService } from 'src/app/svc/currentApplicationService';
import { StateHelperService } from 'src/app/svc/stateHelperService';
import { CommandCollection } from '../../commands/command-collection';
import { Grids } from '../../model/Grids';
import { MsiBrowseColumnWidths } from '../../model/MsiBrowseColumnWidths';

import * as CRS from '../../api/CRS';

@Component({
    selector: Constants.ROUTE_PROJECTAPPLICATIONBROWSE,
    templateUrl: './project-application-browse-table.html',
    styleUrls: [ './project-application-browse.scss']
})
export class ProjectApplicationBrowseTable implements OnInit, OnDestroy {

  constructor(
    public currentApplication: CurrentApplicationService,
    private utilService: UtilService,
    private stateHelperService: StateHelperService,
    private grids: Grids
  ) { }

    @ViewChild("msiCellTooltip") msiCellTooltip: ElementRef;

    public isGridReady: boolean=false;
    private appGridFull: SlickGrid=null;
    private appGridFullDataView:any;
    public gridColumnDefs: Column[];
    public gridOptionsFull: GridOption;
    private subscriptions: Array<Subscription> =new Array<Subscription>();

    ngOnInit():void {
        this.setGridOptions();

        this.subscriptions.push(this.currentApplication.browser.onTableDataReady().subscribe(()=>{
            this.gridColumnDefs = this.getColumns();
            this.grids.sizeColumnsToHeaderWidth(this.tableName, this.gridColumnDefs, (t,c)=>MsiBrowseColumnWidths.getWidth(t,c));
            if (this.appGridFull) {
                this.appGridFull.setData(this.currentApplication.browser.tableExt.rows, true);
                this.appGridFull.invalidateAllRows();
                this.appGridFull.render();
            }
            if (this.currentApplication.browser.findItem) {
                this.selectFoundItem();
            }
        }));
        this.subscriptions.push(this.currentApplication.browser.onFindItemChangedWithinTable().subscribe(()=>{
            this.selectFoundItem();
        }));

        this.subscriptions.push(this.stateHelperService.onStateChange().subscribe((data)=>{
            this.currentApplication.browser.touchTable(this.tableName);
        }));

        this.currentApplication.browser.touchTable(this.tableName);

        localStorage.setItem(Constants.LOCALSET_LAST_BROWSE_TABLE, this.tableName);
    }

    ngOnDestroy():void {
        this.subscriptions.forEach((s)=>s.unsubscribe());
        if (this.appGridFull)
            this.appGridFull.destroy();
    }

    public get tableName() :string {
        return this.stateHelperService.details.tableId;
    }
    
    public gotoForeignKey = (r,c)=>{
        console.log("fore");
        // let colDef = c["colDef"];
        // let colDefName = (colDef)? colDef["name"] : null;
        // let col = this.tableExt.getColumn(colDefName);
        // let fk = this.tableExt.getColumnForeignKey(colDefName, r.entity);
        // if (fk) {
        //     let fi: CRS.RSMsiFindResult = new CRS.RSMsiFindResult();
        //     fi.itemId = 0;
        //     fi.primaryKey = [ r.entity[colDefName] ];
        //     fi.table = fk.table;
        //     this.$localStorage["findtransitionrow"] =fi; 
        //     this.$state.go("editPackageTable", {
        //         applicationId: this.stateHelperService.getApplicationId(),
        //         table : fk.table
        //     });
        // }
    };

    public get isReady() : boolean {
        return this.currentApplication.browser.tableExt!=null;
    }
    public get tableData() : Array<any> {
        if (this.utilService.isEmptyAny(this.currentApplication.browser.tableExt))
            return [];
        return this.currentApplication.browser.tableExt.rows;
    }
            
    public appAngularGridReady(angularGrid:any) {
        this.appGridFull = angularGrid.slickGrid;
        this.appGridFullDataView = angularGrid.dataView;
        this.initGridSorting();
        this.handleTooltips();
        const cmInstance = angularGrid.extensionService.getSlickgridAddonInstance(ExtensionName.contextMenu);
        if (cmInstance) {
          cmInstance.onCommand.subscribe((e,a)=>{
            if (a.command == "delete") {
                this.appGridFull.setSelectedRows([a.row]);
                var tableName = this.currentApplication.browser.tableExt.table.name;
                CommandCollection.ConfirmAppBrowseRowDeletion.confirm(tableName, this.grids.getSelectedRow(this.appGridFull));
            }
          })
        };
      

        this.appGridFull.onRendered.subscribe((a,b,c)=> {
         $(".sw-foreignkey").on('click', ()=> {
            //console.log("xxx");
         })
        });
      }
    
    public selectRow(row: CRS.RSMsiRow) {
        // this.$timeout(()=> {
        //     if (this.gridApi.selection.selectRow) {
        //         this.gridApi.selection.selectRow(row);
        //     }
        // });
    }
        
    private mouseX : number = -1;
    private mouseY : number = -1;
    public currentRowIssues : Array<CRS.RSMsiIssue>=null;
    public setCurrentRow(row: any) { 
        // var twi = <CRS.RSMsiGetPackageIssueData>this.$scope.$parent.vm.packageIssues;
        // if (twi && row) {
        //     let tbl = this.stateHelperService.getTableId();
        //     let issForRow = twi.issues.filter(x=>x.tableName == tbl && this.tableExt.getPrimaryKey(row.entity) == x.rowKey);
        //     if (issForRow.length> 0) {
        //         this.currentRowIssues = issForRow;
        //     }
        //     else {
        //         this.currentRowIssues=null;
        //     }
        // }
        // else {
        //     this.currentRowIssues=null;
        // }
    }

    private selectFoundItem() {
        var pk = this.currentApplication.browser.findItem.primaryKey.join('+');
        this.grids.selectRowViaDV(this.appGridFull, this.appGridFullDataView, pk);
    }

    private handleTooltips() {
        this.appGridFull.onMouseEnter.subscribe((e, args) => {
            var cell = args.grid.getCellFromEvent(e)
            var row = cell.row
            var item = args.grid.getDataItem(row);
            this.currentRowIssues=item._issues;
            if (item._issues != null && this.msiCellTooltip) {
                this.msiCellTooltip.nativeElement.style.top = `${e.clientY-50}px`;
                this.msiCellTooltip.nativeElement.style.left = `${e.clientX}px`;
                this.msiCellTooltip.nativeElement.style.display = '';
            }
        });

        this.appGridFull.onMouseLeave.subscribe((e, args) => {
            if (this.msiCellTooltip)
                this.msiCellTooltip.nativeElement.style.display = 'none';
        });
    }

    private getColumns() : Array<any> {
        if (this.currentApplication.browser.packageDetails.status == 0) {
            let requiredCols = this.currentApplication.browser.packageTable.tables[0].columns.filter(x=>x.isRequired).map(x=>x.name);
            let tblName = this.currentApplication.browser.currentTable;
            let tblExt = this.currentApplication.browser.tableExt;
            var cols = [];
            var tbl = this.currentApplication.browser.packageTable.tables[0];
            tbl.columns.forEach((c) => {
                var colType:FieldType = FieldType.string;
                if (c.dataType == "i")
                    colType = FieldType.integer;
                var col = { 
                        id: c.columnId, 
                        name: c.name, 
                        type: colType, 
                        field: c.name, 
                        sortable: true, 
                        custom : {isRequired:c.isRequired, flags:c.flags, tableName: tblName, foreignKeys: c.foreignKeys} };
                this.assignFormatter(col);
                cols.push(col);
            //         name: c.name, 
            //         cellClass: function(g,r,c,ri,ci) { 
            //             let hasIssues:boolean =false;
            //             var twi = <CRS.RSMsiGetPackageIssueData>pscope.vm.packageIssues;
            //             if (twi) {
            //                 let issForRow = twi.issues.filter(x=>x.tableName == tbl && tblExt.getPrimaryKey(r.entity) == x.rowKey);
            //                 hasIssues =  issForRow.length> 0;
            //             }
            //             let isReq= requiredCols.indexOf(c.name) != -1;
            //             let cls = "msi-row";
            //             if (isReq)
            //                 cls += "-required"
            //             if (hasIssues)
            //                     cls+= "-issues";
            //             return cls;
            //         },
            //         enableCellEdit: false,
            //         minwidth:this.tableExt.getColumnWidth(c) * 20 };
            //     if (c.dataType == "i")
            //         col["sortingAlgorithm"] = this.numericSort; 
            //     if (c.foreignKeys && c.foreignKeys.length> 0) 
            //         col["cellTemplate"] = "<div class='ui-grid-cell-contents' class='clear'><md-button class='button-link link-msi' ng-click='grid.appScope.vm.gotoForeignKey(row,col)'>{{grid.appScope.vm.getGridCellData(row, col)}}</md-button></div>";
            //     else
            //         col["cellTemplate"] = "<div ng-mouseout='grid.appScope.vm.setCurrentRow(null)' ng-mouseover='grid.appScope.vm.setCurrentRow(row)'><div class='ui-grid-cell-contents'>{{ COL_FIELD }}</div></div>";
            //     return col;
            });
            return cols;
        }
        return [];
    }

    private initGridSorting() {
        this.appGridFull.onSort.subscribe((e, args) => {
          var cols = args.sortCols.map((c)=> {
            var cx = this.currentApplication.browser.tableExt.getColumnById(c.columnId)
            return {column:cx, asc:c.sortAsc};
          });
          this.currentApplication.browser.tableExt.rows.sort((d1, d2) => {
            for (var i=0, l=cols.length; i < l; i++) {
              var res = this.currentApplication.browser.tableExt.rowCompare(d1,d2, cols[i].column, cols[i].asc);
              if (res!=0)
                return res
            }
            return 0;
          });
           this.appGridFull.setData(this.currentApplication.browser.tableExt.rows, true);
           this.appGridFull.invalidateAllRows();
           this.appGridFull.render();
        });
    };

    private assignFormatter(gridCol) {
        gridCol.formatter = (r,c,v,cd,dc)=> { 
            var cls = "msi-row";
            dc._issues=null;
            if (cd.custom.isRequired)
                cls += "-required";
            if ((cd.custom.flags & Constants.MSIBROWSE_FLAGS_EMBELLISHMENT) > 0)
                cls += "-embellishment";
            let issList = (this.currentApplication.browser.issuesByTable) ? this.currentApplication.browser.issuesByTable[cd.custom.tableName] : null;
            if (issList) {
                var issues= issList.filter(x=>x.rowKey == dc.id);
                if (issues.length>0) {
                    cls += "-issues";
                    dc._issues = issues;
                }
            }
            // if (cd.custom.foreignKeys && cd.custom.foreignKeys.length> 0) {
            //      if (v)
            //          return `<a href='#' on-click='return false' class='sw-foreignkey">${v}</a>`
            //      else
            //          return '<span></span>';
            //  }
            //  else {
                return `<span class="${cls}">${v}</span>`;
            //}
        };
    }

    private setGridOptions() : void {
        this.gridOptionsFull = this.grids.options(Constants.ROUTE_PROJECTAPPLICATIOBROWSETABLE);
        this.gridOptionsFull.showHeaderRow=false;
        this.gridOptionsFull.enableContextMenu=true;
        this.gridOptionsFull.forceFitColumns=true;
        this.gridOptionsFull.rowSelectionOptions={
            selectActiveRow: true,
        };
        this.gridOptionsFull.contextMenu= {
            commandItems: [
                { title: "Delete", iconCssClass: "fa fa-trash-o", command: "delete" }
            ]
        };
        this.gridColumnDefs=[];
    }


}
    

    
    


