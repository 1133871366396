<h2 mat-dialog-title>Repackage Observations</h2>

<div mat-dialog-content class='sw-dialog-padding' style="min-width:800px;height:400px;">
    <div *ngIf="data">
        <div *ngFor="let c of data.categories" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="5px">
            <div fxFlex="none" class="sw-bold">{{c.title}}</div>  
            <div fxFlex="none">
                <div style="padding-left:20px">
                    <table>
                        <tr *ngFor="let i of c.items" val>
                            <td>{{i.contextId}}</td>
                            <td>{{i.mainText}}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<div mat-dialog-actions fxLayout='row'>
    <div fxFlex></div>
    <div fxFlex>
        <button mat-raised-button (click)='closeDialog()'>
            <mat-icon>done</mat-icon>
            <span>Close</span>
        </button>
    </div>
</div>