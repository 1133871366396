import { Injectable } from '@angular/core';
import { ConversionService } from '../api/SVC';
import { delay, repeat } from 'rxjs/operators';
import { of, Subscription } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActionIdParts } from '../model/ActionIdParts';
import { ConvJobPayload } from '../model/ConvJobPayload';
import { ConversionTaskLaunchInfo } from '../model/ConversionTaskLaunchInfo';
import { Constants } from '../api/Constants';
import { UtilService } from './utilService';
import { DataContextProvider } from './DataContextProvider';
import { StateHelperService } from './stateHelperService';

import * as Enums from '../api/Enum';
import * as CRS from '../api/CRS'

@Injectable({
    providedIn: 'root'
})
export class CurrentConversionJobService {

    private appSupplementalData : Array<string> = [ 
        Constants.APP_SUPP_DOC, 
        Constants.APP_SUPP_HISTORY,
        Constants.APP_SUPP_ISSUES,
        Constants.APP_SUPP_NOTES,
        Constants.APP_SUPP_COMPANIONS,
        Constants.APP_SUPP_PREREQUISITES,
        Constants.APP_SUPP_RELATEDAPPS,
        Constants.APP_SUPP_QA
    ];

    constructor( 
        private conversionService: ConversionService,
        private domSanitizer: DomSanitizer,
        private utilService: UtilService,
        private stateHelperService: StateHelperService,
    ) { 
        // The data context provider for jobs is different because the 
        // context param can either be a jobid or an action id. 
        this.dataContext =new DataContextProvider<CRS.ResponseWrapper<CRS.RSGetActionDetails>>(
            "Job",
            this.utilService,
            this.stateHelperService, 
            ()=>{
                var jid = this.stateHelperService.details.jobId;
                if (jid)
                    return jid;
                return this.stateHelperService.details.actionId;
            },
            (id)=>{this.preLoad(id)},
            (id,refresh)=>{return this.mainLoad(id, refresh)},
            ()=>this.mainLoadOk());

        this.dataContext.setTimerInterval(Constants.POLLING_ACTIONVIEW_INTVL);
        this.dataContext.onRefreshed().subscribe((d)=> {
            if (this._recallLog && this.dataItem.actionStatus == Constants.STATUS_INPROGRESS)
                this.refreshLog();
        });
        
        this._applicationId="";
        this._type="";
    }

    public dataContext: DataContextProvider<CRS.ResponseWrapper<CRS.RSGetActionDetails>>;

    public get dataItem(): CRS.RSGetActionDetails {
        return this.dataContext.item() ? this.dataContext.item().data : null;
    }

    public get type() : string {
        return this._type;
    }

    public get applicationId(): string {
        return this._applicationId;
    }

    public get idParts() : ActionIdParts {
        return this._actionIdParts;
    }

    public get log() : Array<CRS.RSTaskLogLine> {
        return (this._log) ? this._log.lines : null;
    }

    private _usePvad: boolean=false;
    public get usePvad(): boolean {
        return this._usePvad;
    }
    public set usePvad(value:boolean) {
        this._usePvad=value;
        this._taskLaunchInfo.updatePvad(value);
    }

    private _pvadDirectory: string=null;
    public get pvadDirectory(): string {
        return this._pvadDirectory;
    }
    public set pvadDirectory(value:string) {
        this._pvadDirectory=value;
        this._taskLaunchInfo.updatePvadDirectory(value);
    }

    public isJob() : boolean {
        return this.utilService.isGuid(this.dataContext.id) && !this.utilService.isEmptyGuid(this.dataContext.id);
    }

    public hasTask(): boolean {
        return this.dataContext.isReady && !this.utilService.isEmptyAny(this.dataContext.item()) && !this.utilService.isEmptyAny(this.dataItem.task);
    }
    
    public hasEval(): boolean {
        return this.dataContext.isReady && !this.utilService.isEmptyAny(this.dataContext.item()) && !this.utilService.isEmptyAny(this.dataItem.eval);
    }

    public alwaysTargetsOriginalPackage() : boolean {
        return this._actionIdParts && this._actionIdParts.conversionType == 1;
    }

    public isVirtualisationConversionType() :boolean {
        return this._actionIdParts &&  this._actionIdParts.conversionType == 2 || this._actionIdParts.conversionType == 20;
    }

    public isInProgress() : boolean {
        if (!this.dataContext.isReady)
            return false;
        return this.dataContext.isReady && this.dataContext.item() && this.dataItem.task && 
                (this.dataItem.task.status == Enums.SpineStatus.InProgress ||
                this.dataItem.task.status == Enums.SpineStatus.NotStarted);
    }

    public isCancelling() : boolean {
        if (!this.dataContext.isReady)
            return false;
        if (!this.dataItem.task)
            return false;
        return this.dataItem.task.cancelling;
    }

    public isUnstartedTestJob() : boolean {
        if (!this.dataContext.isReady)
            return false;
        var t = this.idParts.type;
        if (t===undefined)
            return false;
        return this.dataContext.isReady && (t == Constants.APP_PRIM_TEST || t == Constants.APP_PRIM_RTC || t == Constants.APP_PRIM_MSIMODIFY || t == Constants.APP_PRIM_DISC) && !this.hasEval();
    }

    public isCompletedRuntimeTest() : boolean {
        if (!this.dataContext.isReady)
            return false;
        var t = this.idParts.type;
        if (t===undefined)
            return false;
        return false;
    }

    public uiDetected(): boolean {
        if (!this.dataContext.isReady)
            return false;
        var i = this.dataItem;
        if (!i)
            return false;
        var t = i.task;
        if (!t)
            return false;
        return t.uiDetected;
    }

    public isUnstartedConversionJob(): boolean {
        if (!this.dataContext.isReady)
            return false;
        var t = this.idParts.type;
        if (t===undefined)
            return false;
        if (this.idParts.isRoot) // root is original
            return false;
        var isXCheck = this.idParts.conversionType == 14;
        return this.dataContext.isReady && this.appSupplementalData.indexOf(t) == -1 && 
            (t != Constants.APP_PRIM_TEST && t!= Constants.APP_PRIM_EDIT && t != Constants.APP_PRIM_PUBLISH && t != Constants.APP_PRIM_PUBLISHTEST && t != Constants.APP_PRIM_RTC && t != Constants.APP_PRIM_MSIMODIFY && t != Constants.APP_PRIM_DISC && !isXCheck) && !this.hasTask();
    }
    
    public isEditJob(): boolean {
        if (!this.idParts)
            return false;
        var t = this.idParts.type;
        if (t===undefined)
            return false;
        return this.dataContext.isReady && t == Constants.APP_PRIM_EDIT;
    }

    public isUnstartedPublishJob(): boolean {
        if (!this.idParts)
            return false;
        var t = this.idParts.type;
        if (t===undefined)
            return false;
        return this.dataContext.isReady && t == Constants.APP_PRIM_PUBLISH;
    }

    public isUnstartedPublishedTestJob(): boolean {
        if (!this.idParts)
            return false;
        var t = this.idParts.type;
        if (t===undefined)
            return false;
        return this.dataContext.isReady && t == Constants.APP_PRIM_PUBLISHTEST;
    }

    public isRepackJob(): boolean {
        if (!this.dataContext.isReady)
            return false;
        return this.idParts && this.idParts.conversionType == 1;
    }

    public isXCheckJob() : boolean {
        if (!this.dataContext.isReady)
            return false;
        return this.isXCheckJobType();
    }

    public isXCheckJobType() :boolean {
        if (!this.idParts)
            return false;
        var ct = this.idParts.conversionType;
        if (ct===undefined)
            return false;
        return ct==14;
    }

    public getXCheckJobSubType(): string {
        if (!this.dataContext.isReady)
            return null;
        var ct= this.idParts.conversionType;
        if (ct===undefined)
            return null;
        return this.idParts.type;
    }

    public supportsManualModificationStep() : boolean {
        return this.idParts.type != 'T' && (this.idParts.conversionType == 1 || this.idParts.conversionType == 20 || this.idParts.conversionType == 16); // Repack, Msix
    }

    public canApplyResponseTransform(): boolean {
        if (!this.dataContext.isReady)
            return false;
        if (this.alwaysTargetsOriginalPackage())
            return false;
        if (!this.dataItem)
            return false;
        if (this.dataItem.type != 'T')
            return false;
        if (this.idParts.conversionType == 2 || this.idParts.conversionType == 20) // not virt or msix
            return false;
        return true;
    }

    public cancelCurrentTask() : Promise<boolean> {
        return new Promise<boolean>((resolve,reject) => {
            this.conversionService.cancelConversionTask(this.dataItem.projectId, this.dataItem.jobId).then((ret)=> {
                this.dataItem.task.cancelling=true;
                this.dataItem.task.isInProgress=false;
                resolve(true);
            }, (ex)=> {
                reject(ex);
            });
        });
    }

    public killRemoteProcess(id: number, commandLine:string) : Promise<boolean> {
        return new Promise<boolean>((resolve, reject) =>{
            this.conversionService.killConversionTaskProcess(this.dataItem.jobId, id, commandLine).then((ret)=>{
                resolve(true);
            }, (ex)=>{
                reject(ex);
            })
        });
    }

    private _taskLaunchInfoReady:boolean=false;
    public get taskLaunchInfoReady(): boolean {
        return this._taskLaunchInfoReady;
    }
    private _taskLaunchInfo: ConversionTaskLaunchInfo=new ConversionTaskLaunchInfo(this.utilService,null);
    public get taskLaunchInfo() : ConversionTaskLaunchInfo {
        return this._taskLaunchInfo;
    };
    public updateTaskLaunchInfo() {
        var applicationId = this.stateHelperService.details.applicationId;
        var jobType = this.idParts.conversionType;
        this._taskLaunchInfo.reset(-1,null);
        if (jobType) {
            this._taskLaunchInfoReady=false;
            this.conversionService.getConversionTaskLaunchInfo(applicationId, jobType ).then((r)=>{
                this._taskLaunchInfoReady=(r.status.errorCode == 0);
                this._taskLaunchInfo.reset(jobType,r.data);
            });
        }
    }

    public recallLog(state:boolean) {
        this._recallLog=true;
        this.getLog();
    }

    public resetJob() : Promise<boolean>{
        var jobId = this.dataContext.id;
        this.dataContext.setCurrent(null);
        return new Promise<boolean>((resolve,reject)=>{
            this.conversionService.resetApplicationAction(jobId).then(()=>{
                resolve(true);
            }, (err)=>{
                reject(false);
            });
        });
    }

    public publish(parentJobId:string, includeEvalResultsInReadme:boolean, mergeTransformsDuringPublish:boolean) : Promise<string> {
        return new Promise<string>((resolve,reject)=>{
            this.conversionService.publishApplication(parentJobId, includeEvalResultsInReadme, mergeTransformsDuringPublish).then((response)=>{
                this.dataContext.setCurrent(response.data.jobId);
                resolve(response.data.jobId);
            }, (error)=>{
                reject(null);
            });
        });
    }

    public publishedTest(parentJobId:string) : Promise<string> {
        return new Promise<string>((resolve,reject)=>{
            this.conversionService.publishedTest(parentJobId).then((response)=>{
                this.dataContext.setCurrent(response.data.jobId);
                resolve(response.data.jobId);
            }, (error)=>{
                reject(null);
            });
        });
    }

    public setManualModificationStageState(value:number): Promise<boolean> {
        return new Promise<boolean>((resolve,reject)=> {
            this.conversionService.setManualModificationStageState(this._jobId, value).then(()=> {
                this.dataItem.task.isPreparingManualModStage=false;
                if (value == Constants.CONV_EVTEXT_MANUALMOD_PREP)
                    this.dataItem.task.isWaitingManualModStage=true;
                this.dataItem.task.canRelease=false;
                if (value == Constants.CONV_EVTEXT_MANUALMOD_DONE)
                    this.dataItem.task.canShowRemoteWindow=false;
                resolve(true);
            },(ex)=> {
                reject(ex);
            });
        });
    }

    public getApplicationIdFromJob(jobId:string) : Promise<string> {
        return new Promise<string>((resolve, reject)=> {
            this.conversionService.getApplicationIdFromJob(jobId).then((response)=> {
                resolve(response.data.applicationId);
            }, (ex)=> {
                reject(ex);
            });
        });
    }

    public releaseTestMachine(testOutcome:string, testText:string) : Promise<boolean> {
        return new Promise<boolean>((resolve,reject)=> {
            var tsk = this.dataItem.task;
            tsk.canShowRemoteWindow=false;
            this.conversionService.releaseTestMachine(this.dataContext.id, testOutcome, testText).then(()=> {
                tsk.canRelease=false;
                tsk.canShowRemoteWindow=false;
                this.dataContext.refreshCurrent();
                resolve(true);
            },(ex)=> {
                reject(ex);
            });
        });
    }

    public getTestHeading() {
        switch(this.idParts.conversionType)
        {
            case 0:
                return `Test Imported Application (${this.idParts.conversionSubType})`;
            case 1:
                return "Test Repackaged Application";
            case 2:
                return "Test Virtualised Application";
            case 20:
                return "Test MSIX Packaged Application";
            case 25:
                return "Test Liquidware Application";
            case 10:
                return "Create Response Transform";
            case 16:
                return "MSI Modification";
            case 15:
                return "Application Discovery";
            default:
                return "Unknown";
        }
    }

    public isFailed() :boolean {
        return this.dataContext.isReady && this.dataItem && this.dataItem.actionStatus == Enums.SpineStatus.Failed;
    }

    private preLoad(id:string) : void {
        var appId = this.stateHelperService.details.applicationId;
        var appIdComp = this.stateHelperService.details.applicationIdCompressed;
        var isJobId = this.utilService.isGuid(id);
        this._jobId = (isJobId) ? id : null;
        this._actionIdParts = (id) ? ActionIdParts.parse(id, isJobId, appIdComp): null;
        this._lastArgs = new ConvJobPayload(null,appId,id);
        if (this._currentJobRefreshCycleSubscription != null)
             this._currentJobRefreshCycleSubscription.unsubscribe();
        this._msiInstallersCheckedState=null;
    }

    private mainLoad(id:string, refresh:boolean) : Promise<CRS.ResponseWrapper<CRS.RSGetActionDetails>> {
        this.saveMsiExtractCheckboxStates();
        var appId = this.stateHelperService.details.applicationId;
        if (this._jobId || this.isXCheckJobType()) {
            var type = this._actionIdParts.type;
            if (type!==undefined && this._actionIdParts.conversionType == 14) {
                type="X";
                id = this.utilService.getEmptyGuid();
            }
            if (!appId) // Won't have an appid if we are looking outside app context
                appId = this.utilService.getEmptyGuid();
            return new Promise<CRS.ResponseWrapper<CRS.RSGetActionDetails>>((resolve, reject)=> {
               this.conversionService.getActionDetails(type, appId, id).then((response)=>{
                    this.syncMsiExtractCheckboxes(response?.data);
                    resolve(response);
               }, (ex)=>reject(ex));
            });
        }
        return new Promise<CRS.ResponseWrapper<CRS.RSGetActionDetails>>((resolve,reject)=> {
            return resolve(null);
        })
    }

    private saveMsiExtractCheckboxStates() {
        var data = this.dataContext?.isReady ? this.dataItem: null;
        if (data && data.task && data.task.msiInstallersExtracted) {
            this._msiInstallersCheckedState = [];
            data.task.msiInstallersExtracted.forEach((x)=>{
                if (x.isChecked)
                    this._msiInstallersCheckedState.push(x.filename);
            });
        }
        else {
            this._msiInstallersCheckedState=null;
        }
    }

    private syncMsiExtractCheckboxes(data: CRS.RSGetActionDetails) {
        if (data && data.task && data.task.msiInstallersExtracted && this._msiInstallersCheckedState) {
            data.task.msiInstallersExtracted.forEach((x)=>{
                var fn = this._msiInstallersCheckedState.filter(y=>y == x.filename);
                x.isChecked = (fn.length == 1);
            });
        }
    }

    private mainLoadOk(): void {
        this._applicationId = (this.dataContext.item()) ? this.dataItem.applicationId : null;
        this._remotingJobUrl=this.getRemotingMachineUrl();
        this._recallLog=false;
        if (this.isInProgress())
            this.initialiseRefreshCycle();
        else if (this._currentJobRefreshCycleSubscription)
            this._currentJobRefreshCycleSubscription.unsubscribe();
    }

    private _lastArgs:ConvJobPayload=null;

    private _currentJobRefreshCycleSubscription : Subscription=null;
    private initialiseRefreshCycle()
    {
        const delayedThing = of('timer').pipe(delay(Constants.POLLING_ACTIONVIEW_INTVL));
        this._currentJobRefreshCycleSubscription = delayedThing.pipe(repeat()).subscribe(()=>this.refreshJobCycle());
    }

    private refreshJobCycle() {
        this.dataContext.refreshCurrent();
    }

    public getRemotingMachineUrl() :SafeResourceUrl {
        if (!this.hasTask())
            return null;
        var url = this.getRemotingMachineUrlRaw();
        if (!url)
            return null;
        return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
    }

    public getRemotingMachineUrlRaw() :string {
        if (!this.hasTask())
            return null;
        var ct = this.dataItem.task;
        let userId =  encodeURIComponent(ct.adminUser);
        let pwd = ct.adminUserPwd;
        let svr = ct.machineIpAddress;
        var h = ct.remotingUrl;
        if (!h)
            return null;
        let url: string = "https://" + h + "/Myrtille/?__EVENTTARGET=&__EVENTARGUMENT="
        + "&server=" + svr
        + "&user=" + userId
        + "&passwordHash=" + pwd
        + "&connect=Connect%21";
        
        return url;
    }

    public getClickerExchangeImageUrl(index:number):string {
        if (!this.hasTask || !this.dataItem.task.clickerExecution)
            return null;
        var exchangeId = this.dataItem.task.clickerExecution.exchangeId;
        return this.utilService.getAPIUrl( "Conversion/GetClickerExchangeImage?ServerId=" + this.utilService.serverId + "&jobId="+ this.dataItem.jobId + "&exchangeId=" + exchangeId + "&imageIndex=" + index.toString());
    }

    public getRepackageObservations():Promise<CRS.ResponseWrapper<CRS.RSGetRepackageObservations>> {
        if (!this.dataItem)
            return null;
        return this.conversionService.getRepackageObservations(this.dataItem.jobId)
    }

    public executeClicker(script:string): Promise<CRS.RSExecuteClickerScript>
    {
        var hsh = this.utilService.getHashCode(script);
        return new Promise<CRS.RSExecuteClickerScript>((resolve, reject)=> {
            if (this.isJob()) {
                this.conversionService.executeClickerScript(this._jobId, script, hsh).then((response) => {
                    if (response.data){
                    if (!this.dataItem.task.clickerExecution)
                        this.dataItem.task.clickerExecution = new CRS.RSClickerExecution();
                        this.dataItem.task.clickerExecution.exchangeId = response.data.exchangeId;
                        this.dataItem.task.clickerExecution.status = Constants.CLICKER_EXCHANGE_STATUS_ACKNOWLEDGED;
                        this.dataItem.task.clickerExecution.hashCode = hsh;
                        this.dataItem.task.clickerExecution.response=null;
                    }
                    else {
                        this.dataItem.task.clickerExecution =null;
                    }
                    resolve(response.data)
                },(ex)=> {
                    reject(ex);
                });
            }
            else
            {
                resolve(null);
            }
        });

    }

    public refreshClickerExecutionStatus(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject)=> {
            if (this.isJob() && this.hasTask && this.dataItem.task.clickerExecution) {
                var exchangeId = this.dataItem.task.clickerExecution.exchangeId;
                this.conversionService.refreshClickerExecutionStatus(this._jobId, exchangeId).then((response) => {
                    if (response.data){
                        this.dataItem.task.clickerExecution.progressStatus = response.data.progressStatus;
                        this.dataItem.task.clickerExecution.status = response.data.status;
                        this.dataItem.task.clickerExecution.response=null;
                    }
                    resolve(true)
                },(ex)=> {
                    reject(ex);
                });
            }
            else
            {
                resolve(false);
            }
        });

    }

    private getLog() : Promise<CRS.RSGetConversionTaskLog>
    {
        return new Promise<CRS.RSGetConversionTaskLog>((resolve, reject)=> {
            if (this.isJob()) {
                this.conversionService.getConversionTaskLog(this._jobId).then((response) => {
                    this._log=response.data;
                    resolve(this._log)
                },(ex)=> {
                    reject(ex);
                });
            }
            else
            {
                resolve(null);
            }
        });
    }

    private refreshLog() : void {
        if (this._recallLog) {
            this.getLog().then((data)=> {
                this._log = data;
            }, (ex)=> {
            });
        }
    }

    private _actionIdParts : ActionIdParts;

    private _jobId:string=null;
    private _type:string=null;
    private _applicationId:string=null;
    private _remotingJobUrl:SafeResourceUrl=null;
    private _log : CRS.RSGetConversionTaskLog=null;
    private _recallLog: boolean=false;
    private _msiInstallersCheckedState: Array<string> = null;
}