import { Injectable } from '@angular/core';
import { GridOption, SlickGrid, SlickDataView, Column, CurrentRowSelection, GridStateChange} from 'angular-slickgrid';
import { Constants } from 'src/app/api/Constants';
import { UtilService} from '../svc/utilService';
import { GridSorting } from './GridSorting';
import { GridFiltering } from './GridFiltering';
import { GridFeatures } from './GridFeatures';

@Injectable({
    providedIn: 'root'
})
export class Grids {

    constructor(
        private utilService: UtilService
    ) {
    }

    private _options:  { [id: string] : GridOption; } = {};
    private _gridFeatures: { [id: string] : GridFeatures; } = {};

    public options(id:string): GridOption {
        var ret=this._options[id];
        if (ret)
            return ret;

        return this._options[id] = {
            showHeaderRow: true,
            explicitInitialization: true,
            enableAutoResize: true,
            enableSorting: true,
            enableFiltering:true,
            enableRowSelection: true,
            enableCheckboxSelector:false,
            enableCellNavigation:true,
            enableHeaderMenu:false,
            enableCellMenu:false,
            enableContextMenu:false,
            enableGridMenu:false,
            enableColumnPicker: false,
            rowHeight:Constants.GRID_ROW_HEIGHT,
            multiSelect: false
          };
    }

    public features(id:string) : GridFeatures {
        var ret= this._gridFeatures[id];
        if (!ret)
            ret = this._gridFeatures[id] = new GridFeatures(this.utilService);
        return ret;
    }
    
    public enableEditing(id:string) {
        var opts = this.options(id);
        opts.editable=true,
        opts.autoEdit= true
    }

    public enableCheckboxSelector(id:string){
        var opts = this.options(id);
        opts.enableCheckboxSelector=true;
        opts.checkboxSelector={
            hideSelectAllCheckbox: false,
            selectableOverride: (row: number, dataContext: any, grid: any) => (dataContext.item.status === 0)
        };
    }

    public enableFiltering(id:string){
        var opts = this.options(id);
        opts.enableFiltering=true;
    }

    public enableMenu(id:string){
        var opts = this.options(id);
        opts.enableGridMenu=true;    
    }

    public disableActiveRowSelection(id:string){
        var opts = this.options(id);
        if (!opts.rowSelectionOptions){
            opts.rowSelectionOptions={
                selectActiveRow:false
            };
        }
        else {
            opts.rowSelectionOptions.selectActiveRow=false;
        }
    }

    public setHeaderRowVisibility(id:string, state:boolean){
        var opts = this.options(id);
        opts.showHeaderRow=state;
    }

    public getSelectedRow(grid:SlickGrid): any {
        var selRows = grid.getSelectedRows();
        if (selRows && selRows.length >0) {
          var rowIdx = selRows[0];
          return grid.getDataItem(rowIdx);
        }
        else {
          return null;
        }
    }
    
    public getSelectedRows(grid:SlickGrid): Array<any> {
        return grid.getSelectedRows().map(i=>grid.getDataItem(i));
    }

    public selectRow(grid:SlickGrid, key:string):void {
        var idx = grid.getData().getIdxById(key);
        if (idx>=0) {
            grid.setSelectedRows([idx]);
            grid.scrollRowIntoView(idx);
        }
    }

    public selectRowViaDV(grid:SlickGrid, dataView: SlickDataView,  key:string):void {
        var idx = dataView.getIdxById(key);
        if (idx>=0) {
            grid.setSelectedRows([idx]);
            grid.scrollRowIntoView(idx);
        }
    }

    public gridStateChanged(grid:SlickGrid, gridStateChanges: GridStateChange, handler: (row:any)=>void) {
        if (gridStateChanges.change.type == "rowSelection") {
            var crs = <CurrentRowSelection>gridStateChanges.change.newValues;
            var hasChkbox = grid.getOptions().enableCheckboxSelector;
            if (hasChkbox) {
                handler(this.getSelectedRows(grid));
            }
            else {
                if (crs.dataContextIds[0] != undefined) { // Grouped
                    var row  = this.getSelectedRow(grid);
                    if (row)
                        handler(row);
                }
            }
        }
    }

    public sizeColumnsToHeaderWidth(tableName:string, columns: Array<Column>, sizer: (t,c)=>number) : void {
        columns.forEach((c)=>{
            var w = sizer(tableName, c.name);
            if (w>0)
                c.width = w;
        });
    }

    private getTextWidth(text:string):number{
        var f = '10px Ubuntu',
          o = $('<div>' + this + '</div>')
                .css({'position': 'absolute', 'float': 'left', 'white-space': 'nowrap', 'visibility': 'hidden', 'font': f})
                .appendTo($('body')),
          w = o.width();
    
        o.remove();
      return w;
    }
}
