import { SlickGrid, Column, Filter } from 'angular-slickgrid';
import { UtilService} from '../svc/utilService';

export class GridFiltering {

    constructor(utilService: UtilService) {
        this._utilService = utilService;
    }

    private _grid : SlickGrid;
    private _dataview: any;
    private _gridValueGetter: (r,c)=>any;
    private _utilService: UtilService;

    public setGrid(grid: SlickGrid, dataview: any) {
        this._grid = grid;
        this._dataview = dataview;
    }

    public setGridValueGetter(fnc: (r,c)=>any) {
        this._gridValueGetter = fnc;
        this._dataview.setFilter((r,c)=>this.quickFilter(r,c,(r,c)=>this._gridValueGetter(r,c)));
    }

    public quickFilter(r, cf, getValue:(r,c)=>any) {
        if (!cf)
            return true;

        var fcm = this.getFilterToColumnMap(cf);

        return fcm.every(i=>{
            var v =getValue(r,i.column);
            return this.evaluateFilter(i.filter, v);
        });
    }

    private evaluateFilter(filter:any, value:any): boolean {
        var ret=true;
        if (!value){
            ret=false;
        }
        else{
            var visnum = (typeof value.toLocaleLowerCase !== 'function');
            switch (filter.operator.toLowerCase()) {
                case "contains":
                    if (ret)
                        ret= filter.parsedSearchTerms.some(st=>value && value.toLowerCase().indexOf(st.toLowerCase()) != -1);
                    break;
                case "in":
                    return filter.searchTerms.some(st=> {
                        return (visnum) ? value == st : value.toLocaleLowerCase() == st.toLocaleLowerCase();
                    });
            }      
        }
        return ret;
    }
    
    private getFilterToColumnMap(cf:any): Array<FCM> {
        var filtCols: Array<FCM> =[];
        var filtNames = Object.getOwnPropertyNames(cf.columnFilters);
        if (filtNames.length > 0) {
            filtNames.forEach(n=>{
                var f = cf.columnFilters[n];
                var ci = cf.grid.getColumnIndex(n);
                var c = cf.grid.getColumns()[ci];
                filtCols.push( { filter:f, column:c });
            });
        }
        return filtCols;
    }
}


class FCM {
    filter:Filter;
    column:Column;
}