<div class="full-height-bs" fxLayout="column">

    <div fxFlex="none">
        <h2 mat-dialog-title>APPLICATION BROWSE - UNDO UPDATES</h2>
        <h3 class="sw-upper">{{subHeading}}</h3>
    </div>

    <div fxFlex *ngIf="isWorking()">
        <spinner text="WORKING"></spinner>
    </div>

    <div fxFlex mat-dialog-content *ngIf="isDataReady()">
        <table class="full-width-bs">
            <tr *ngFor="let u of updateList">
                <td style="width:20%">{{u.name}}</td>
                <td>
                    <table class="full-width-bs">
                        <tr *ngFor="let i of u.updates">
                            <td style="width:50px">{{i.id}}</td>
                            <td style="width:100px">{{i.tableName}}</td>
                            <td>
                                <div fxLayout="row" FxLayoutGap="10px">
                                    <div *ngFor="let k of i.keys" fxFlex="none">
                                        <span>{{k}}</span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
        </table>
    </div>

    <div fxFlex="none" class="sw-pad-bottom">
        <div mat-dialog-actions fxLayout='row' fxLayoutGap="10px" >
            <div fxFlex></div>
            <div fxFlex="none">
                <button mat-raised-button color="primary" (click)='apply()' [disabled]="!isValid()">
                    <mat-icon>done</mat-icon>
                    <span>Accept</span>
                </button>
            </div>
            <div fxFlex="none">
                <button mat-raised-button (click)='cancelDialog()'>
                    <mat-icon>cancel</mat-icon>
                    <span>Cancel</span>
                </button>
            </div>
        </div>
    </div>

</div>